/* istanbul ignore file */
import {
	createScopedInstance,
	createScopedInstanceAndLoader,
	getLanguage,
} from '@citrite/translate';
import { languages } from './locales';

const { LocalizationLoader } = createScopedInstanceAndLoader({
	namespace: 'billing-pilet',
	localesLoaderMap: {
		en: () => import('./locales/en.json'),
		es: () => import('./locales/es.json'),
		ja: () => import('./locales/ja.json'),
		de: () => import('./locales/de.json'),
		fr: () => import('./locales/fr.json'),
		'pt-BR': () => import('./locales/pt-BR.json'),
		it: () => import('./locales/it.json'),
		nl: () => import('./locales/nl.json'),
	},
	compatibilityJSON: 'v4',
});

export const { t: tmp } = createScopedInstance({
	resources: languages,
	namespace: 'billing-pilet',
});

export const t: (str: string, opts?: any) => string = tmp;

export { getLanguage, LocalizationLoader };
