interface Configuration {
	origin: string;
	apiPath: string;
}

const configuration: Configuration = {
	origin: window.origin,
	apiPath: '/io/ecommerce/',
};

const entitlementsConfiguration: Configuration = {
	origin: window.origin,
	apiPath: '/io/entitlements/',
};

export function createRequest(path: string, init: RequestInit): Request {
	const url = `${configuration.origin}${configuration.apiPath}${path}`;
	init.headers = { ...init.headers, 'X-BFF-CSRF': 'true' };
	return new Request(url, init);
}

export function createPaywallRequest(path: string, init: RequestInit): Request {
	const url = `${configuration.origin}${entitlementsConfiguration.apiPath}${path}`;
	init.headers = { ...init.headers, 'X-BFF-CSRF': 'true' };
	return new Request(url, init);
}
