import React from 'react';
import { OverlayModalComponentProps } from '@sharefiledev/sharefile-appshell';
import { Spin } from 'antd';
import { billingPiletModals, PaymentGatewayProps } from '../sdk/extensionTypes';

export * from './CreditCardInfoPaymentGateway';

export type PaymentGatewayOverlayProps = PaymentGatewayProps &
	Pick<
		OverlayModalComponentProps<typeof billingPiletModals.creditCardModal>,
		'completeInteraction' | 'cancelInteraction' | 'updateModalProps'
	>;

const LazyPaymentGatewayOverlay = React.lazy(() => import('./PaymentGateway'));
export const AsyncCreditCardModal: React.FC<PaymentGatewayOverlayProps> = props => (
	<React.Suspense fallback={<Spin />}>
		{<LazyPaymentGatewayOverlay {...props} />}
	</React.Suspense>
);
