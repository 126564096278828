import * as React from 'react';
import type { PiletApi, PiletUserContext } from '@sharefiledev/sharefile-appshell';
import { ApplicationContextProvider } from './ApplicationContextProvider';
import { AsyncCreditCardModal } from './components';
import { getDefaultModalProps } from './components/overlayUtils';
import { AsyncConvertToPaid } from './ConvertToPaid';
import {
	AsyncCancelAccount,
	AsyncEditBillingInfo,
	AsyncManagePlan,
	AsyncTrialInformationBanner,
} from './EditBilling';
import {
	showCancelAccount,
	showConvertToPaid,
	showEditBillingInfo,
	showManagePlan,
	showReceipts,
	showTrialBanner,
} from './permissions';
import { AsyncReceiptsPage } from './Receipts';
import {
	AdminBillingRoute,
	ConvertFreeTrialRoute,
	EditBillingInfoRoute,
	ManagePlanRoute,
	ReceiptsRoute,
} from './routes';
import { billingPiletExtensionSlots } from './sdk';
import { billingPiletModals, PaymentGatewayProps } from './sdk/extensionTypes';
import { t } from './translate';

export function setup(piletApi: PiletApi) {
	const context: PiletUserContext = piletApi.sf.piletUserContext.get();
	const account = context.account;
	const accountId = context.account.Id;
	const userId = context.user.Id;
	const isFreeTrial = context.account.IsFreeTrial ?? true;
	const isFINRA = context.account.Preferences?.IsFINRA ?? false;
	const isVDR = (context.account.PlanTrack ?? '') === 'VDR';

	if (showConvertToPaid(context) || showEditBillingInfo(context)) {
		piletApi.registerModal<PaymentGatewayProps>(
			billingPiletModals.creditCardModal,
			({ completeInteraction, cancelInteraction, updateModalProps, options }) => (
				<AsyncCreditCardModal
					completeInteraction={completeInteraction}
					cancelInteraction={cancelInteraction}
					updateModalProps={updateModalProps}
					{...options!}
				/>
			),
			{
				modalProps: {
					title: t('edit_billing_information.credit_card.payment_gateway_title'),
					...getDefaultModalProps(),
				},
			}
		);
	}

	if (accountId && userId && showManagePlan(context)) {
		piletApi.registerPage(ManagePlanRoute, () => (
			<ApplicationContextProvider piletApi={piletApi}>
				<AsyncManagePlan
					accountId={accountId}
					userId={userId}
					isFreeTrial={isFreeTrial}
					account={account}
					datePreferences={context.user.Preferences?.DateFormat}
					timePreferences={context.user.Preferences?.TimeFormat}
					isVDR={isVDR}
					isFINRA={isFINRA}
				/>
			</ApplicationContextProvider>
		));

		if (showTrialBanner(context)) {
			const daysRemaining = 5;
			piletApi.registerExtension(billingPiletExtensionSlots.trialBanner, () => (
				<AsyncTrialInformationBanner trialDaysLeft={daysRemaining} />
			));
		}
		piletApi.sf.registerLeftSecondaryNavChildComponent({
			href: ManagePlanRoute,
			title: () => t('manage_plan.left_nav_button_text') ?? '...',
			weight: 200,
			parent: AdminBillingRoute,
		});
	}

	if (showCancelAccount(context)) {
		piletApi.registerExtension(billingPiletExtensionSlots.cancelAccount, () => {
			return (
				<ApplicationContextProvider piletApi={piletApi}>
					<AsyncCancelAccount account={account}></AsyncCancelAccount>
				</ApplicationContextProvider>
			);
		});
	}
	if (showConvertToPaid(context)) {
		piletApi.registerPage(`${ConvertFreeTrialRoute}/:orderId?/:successFlag?`, () => (
			<ApplicationContextProvider piletApi={piletApi}>
				<AsyncConvertToPaid />
			</ApplicationContextProvider>
		));

		piletApi.sf.registerLeftSecondaryNavChildComponent({
			href: ConvertFreeTrialRoute,
			title: () => t('convert_to_paid.left_nav_button_text') ?? '...',
			weight: 200,
			parent: AdminBillingRoute,
		});
	}
	if (accountId && userId && showEditBillingInfo(context)) {
		piletApi.registerPage(`${EditBillingInfoRoute}/:orderId?/:successFlag?`, () => (
			<ApplicationContextProvider piletApi={piletApi}>
				<AsyncEditBillingInfo />
			</ApplicationContextProvider>
		));

		piletApi.sf.registerLeftSecondaryNavChildComponent({
			href: EditBillingInfoRoute,
			title: () => t('edit_billing_information.left_nav_button_text') ?? '...',
			weight: 200,
			parent: AdminBillingRoute,
		});
	}

	if (showReceipts(context)) {
		piletApi.sf.registerLeftSecondaryNavChildComponent({
			href: ReceiptsRoute,
			title: () => t('receipts.left_nav_button_text') ?? '...',
			weight: 200,
			parent: AdminBillingRoute,
		});
		piletApi.registerPage(`${ReceiptsRoute}/:orderId?/:successFlag?`, () => (
			<ApplicationContextProvider piletApi={piletApi}>
				<AsyncReceiptsPage />
			</ApplicationContextProvider>
		));
	}
}
