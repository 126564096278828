import React from 'react';
import { Spin } from 'antd';
import { CancelAccountProps } from '../CancelAccount/CancelAccount';
import { TrialInfoProps } from '../sdk/extensionTypes';
import { ManagePlanProps } from './ManagePlan';

const LazyEditBillingInfo = React.lazy(() => import('./EditBillingInfo'));
export const AsyncEditBillingInfo: React.FC = () => (
	<React.Suspense fallback={<Spin />}>
		<LazyEditBillingInfo />
	</React.Suspense>
);

const LazyManagePlan = React.lazy(() => import('./ManagePlan'));
export const AsyncManagePlan: React.FC<ManagePlanProps> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyManagePlan {...props} />
	</React.Suspense>
);

const LazyCancelAccount = React.lazy(() => import('../CancelAccount/CancelAccount'));
export const AsyncCancelAccount: React.FC<CancelAccountProps> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyCancelAccount {...props} />
	</React.Suspense>
);

const LazyTrialInformationBanner = React.lazy(
	() => import('../components/TrialInformationBanner')
);
export const AsyncTrialInformationBanner: React.FC<TrialInfoProps> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyTrialInformationBanner {...props} />
	</React.Suspense>
);
