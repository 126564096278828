import {
	Address,
	BillingInfo,
	CreditCardAuthorizationReferrer,
	PaymentAuthorizationEntity,
} from '@citrite/sf-api';
import { CurrencyCode } from '@sharefile/ecomm-models/dist/apis/ecomm/';
import {
	BillingCycle,
	OrderAction,
	SalesforceProductCode,
} from '@sharefile/ecomm-models/dist/orders';
import { PlanKey, ProductKey } from '@sharefile/ecomm-models/dist/plans';
import { Account, User } from '@sharefiledev/sharefile-appshell';
import { v4 as uuid } from 'uuid';
import { PurchaseOrderInformation } from '../types';
import { Consumer, PaymentGatewayFPIRequest, Referrer, SuccessFlag } from './models';
export const ECOMM_API_PATH = '/io/ecommerce';

export const getAddressModel = (billingInfo: BillingInfo) => {
	const address: Address = {
		StreetAddress1: billingInfo.Address1,
		StreetAddress2: billingInfo.Address2,
		City: billingInfo.City,
		StateOrProvince: billingInfo.State,
		PostalCode: billingInfo.Zip,
		Country: billingInfo.Country,
		VatId: billingInfo.VatId,
	};
	return address;
};

export async function getCreditCardAuthorizationReferrer(orderCode: string) {
	try {
		const referrerResponse =
			await PaymentAuthorizationEntity.getCreditCardAuthorizationReferrerAsync(
				orderCode
			).execute();

		return referrerResponse;
	} catch (error) {
		console.error('Error getting credit card authorization referrer', error);
	}
}

export function getCreditCardAuthorizationReferrerModel(
	referrer: Referrer,
	accountId: string,
	referrerState?: string
) {
	const creditCardAuthorizationReferrer: CreditCardAuthorizationReferrer = {
		OrderCode: '',
		Referrer: referrer,
		AccountId: accountId,
		ReferrerState: referrerState,
	};
	return creditCardAuthorizationReferrer;
}

function isAddress(address?: Address | BillingInfo): address is Address {
	return (address as Address)?.StreetAddress1 !== undefined;
}

export const createFPIRequest = (
	referrer: CreditCardAuthorizationReferrer,
	account: Account,
	user: User,
	route: string,
	orderDescription: string,
	consumer?: Consumer, // make this required when WorldPay is deprecated
	address?: Address | BillingInfo
) => {
	if (!address) {
		address = JSON.parse(referrer.ReferrerState!).Address;
	}

	const billingAddress = isAddress(address)
		? {
				address1: address?.StreetAddress1 ?? '',
				address2: address?.StreetAddress2 ?? '',
				city: address?.City ?? '',
				state: address?.StateOrProvince ?? '',
				zip: address?.PostalCode ?? '',
				country: address?.Country ?? '',
		  }
		: {
				address1: address?.Address1 ?? '',
				address2: address?.Address2 ?? '',
				city: address?.City ?? '',
				state: address?.State ?? '',
				zip: address?.Zip ?? '',
				country: address?.Country ?? '',
		  };

	const request: PaymentGatewayFPIRequest = {
		orderCode: referrer.OrderCode!,
		orderDescription,
		shopper: {
			firstName: user.FirstName!,
			lastName: user.LastName!,
			emailAddress: user.Email!,
			companyName: account.CompanyName,
		},
		billingAddress,
		successUrl: getSuccessUrl(referrer.OrderCode!, route),
		failureUrl: getFailureUrl(referrer.OrderCode!, route),
		cancelUrl: getCancelUrl(referrer.OrderCode!, route),
		currency: CurrencyCode.USD,
		...(consumer && { consumer }),
	};
	return request;
};

export const createOrdersPurchaseRequest = (
	orderCode: string,
	account: Account,
	billingInfo: BillingInfo,
	user: User,
	totalUsers: number,
	billingCycle: BillingCycle,
	planKey?: PlanKey,
	code?: SalesforceProductCode,
	accountId?: string
): PurchaseOrderInformation => {
	const request: PurchaseOrderInformation = {
		orderCode,
		orderPlan: {
			shopper: {
				firstName: user.FirstName!,
				lastName: user.LastName!,
				emailAddress: user.Email!,
				companyName: account.CompanyName!,
			},
			billingAddress: {
				address1: billingInfo.Address1!,
				address2: billingInfo.Address2!,
				city: billingInfo.City!,
				state: billingInfo.State!,
				zip: billingInfo.Zip!,
				country: billingInfo.Country!,
			},
			billingCycle,
			products: [
				{
					productKey: ProductKey.ShareFile,
					planKey,
					salesforceProductCode: code,
					quantity: totalUsers,
					instanceId: accountId,
				},
			],
			orderAction: OrderAction.TrialConversion,
		},
	};

	return request;
};

export const getSuccessUrl = (orderCode: string, pathParam?: string) => {
	return getUrl(orderCode, 'success', pathParam);
};

export const getFailureUrl = (orderCode: string, pathParam?: string) => {
	return getUrl(orderCode, 'failure', pathParam);
};

export const getCancelUrl = (orderCode: string, pathParam?: string) => {
	return getUrl(orderCode, 'cancel', pathParam);
};

const getUrl = (orderCode: string, successFlag: SuccessFlag, pathParam?: string) => {
	if (pathParam) {
		return `${
			window.location.origin
		}${pathParam}/${orderCode}/${successFlag}?orderId=${orderCode}&status=${successFlag.toUpperCase()}&`;
	} else {
		return `${window.location.href}/${orderCode}/failure?orderId=${orderCode}&status=FAILURE`;
	}
};

export const generateOrderCode = () => `${uuid()}`;
