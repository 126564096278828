import React from 'react';
import { getFeatureFlagValue } from '@citrite/feature-flags';
import { PaymentAuthorizationEntity } from '@citrite/sf-api';
import { sizes } from '@sharefiledev/antd-config';
import { Alert, Button, Col, Flex, Grid, Row, theme, Typography } from 'antd';
import { BillingHttp } from '../api';
import { createFPIRequest, generateOrderCode } from '../api/common';
import { useApplicationContext } from '../ApplicationContextProvider';
import { FeatureFlag } from '../featureFlags';
import { billingPiletModals, PaymentGatewayProps } from '../sdk/extensionTypes';
import { analyticsIds, events, sendPendoTrackEvent } from '../tracking/pendo';
import { t } from '../translate';
import { getDefaultModalProps } from './overlayUtils';

const { Text } = Typography;

export interface GatewayProps extends PaymentGatewayProps {}

export const CreditCardInfoPaymentGateway = (props: GatewayProps) => {
	const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

	const { piletApi } = useApplicationContext();
	const { token } = theme.useToken();
	const screens = Grid.useBreakpoint();
	const useOFCBillingSystem = getFeatureFlagValue(FeatureFlag.EnableOFCBillingSystem);

	const getWorldpayRedirectUrl = async () => {
		const referrerResponse =
			await PaymentAuthorizationEntity.createCreditCardAuthorizationReferrerAsync({
				...props.creditCardAuthorizationReferrer,
				OrderCode: generateOrderCode(),
			}).execute();

		const fpiRequest = createFPIRequest(
			referrerResponse,
			props.account,
			props.user,
			props.route,
			`Trial conversion for account ${props.account.Id}`
		);

		await BillingHttp.redirectToWorldpayPaymentGateway(fpiRequest);
	};

	return (
		<Flex
			vertical
			justify="flex-start"
			gap={sizes.SM}
			style={{
				border: `1px solid ${token.colorBorder}`,
				borderRadius: token.borderRadius,
				padding: token.padding,
			}}
		>
			{props.billingInfo?.CreditCardToken && (
				<Row data-testid="credit-card-billing-info">
					<Col span={8}>
						<Flex vertical gap={sizes.XS}>
							<Text strong>{t('edit_billing_information.name_on_card')}</Text>
							<Text>
								{props.billingInfo?.CreditCardFirstName}{' '}
								{props.billingInfo?.CreditCardLastName}
							</Text>
						</Flex>
					</Col>
					<Col span={8}>
						<Flex vertical gap={sizes.XS}>
							<Text strong>{t('edit_billing_information.credit_card.card_number')}</Text>
							<Text>********{props.billingInfo?.CreditCardToken}</Text>
						</Flex>
					</Col>
					<Col span={8}>
						<Flex vertical gap={sizes.XS}>
							<Text strong>
								{t('edit_billing_information.credit_card.card_exp_date')}
							</Text>
							<Text>
								{props.billingInfo?.CreditCardExpirationMonth}/
								{props.billingInfo?.CreditCardExpirationYear}
							</Text>
						</Flex>
					</Col>
				</Row>
			)}
			<Row>
				<Col>
					<Button
						type="primary"
						onClick={async () => {
							setIsProcessing(true);
							useOFCBillingSystem
								? piletApi.showModal<PaymentGatewayProps>(
										billingPiletModals.creditCardModal,
										{
											...props,
											onCancelInteraction() {
												sendPendoTrackEvent(events.closeEditCCModal);
												return Promise.resolve();
											},
											modalProps: getDefaultModalProps(screens),
										}
								  )
								: await getWorldpayRedirectUrl();
							setIsProcessing(false);
						}}
						disabled={props.isEditCreditCardInfoDisabled}
						data-testid="credit-card-info-button"
						loading={isProcessing}
						data-analytics-id={analyticsIds.editCCButton}
					>
						{t('convert_to_paid.credit_card.edit')}
					</Button>
				</Col>
			</Row>
			{props.successFlag && props.successFlag !== 'cancel' && (
				<Alert
					message={
						props.successFlag === 'success'
							? t('convert_to_paid.credit_card.confirmed_description')
							: t('convert_to_paid.credit_card.error_message')
					}
					type={props.successFlag === 'success' ? 'success' : 'error'}
					showIcon
					data-testid="credit-card-alert"
				/>
			)}
		</Flex>
	);
};
