import { Account, BillingInfo, CreditCardAuthorizationReferrer } from '@citrite/sf-api';
import type { User } from '@sharefiledev/sharefile-appshell';
import { Consumer, SuccessFlag } from '../api/models';

const prefix = 'urn';
const namespace = 'sfextslot';
const owner = 'billing-pilet';
const modalNamespace = 'sfmodal';

export const billingPiletModals = {
	creditCardModal: `${prefix}:${modalNamespace}:${owner}:creditcard` as const,
};

export const billingPiletExtensionSlots = {
	trialBanner: `${prefix}:${namespace}:billing:trialbanner` as const,
	viewReceipts: `${prefix}:${namespace}:${owner}:view-receipts-page` as const,
	cancelAccount: `${prefix}:${namespace}:${owner}:cancel-account-page` as const,
};

export interface TrialInfoProps {
	trialDaysLeft: number;
}
export interface PaymentGatewayProps {
	creditCardAuthorizationReferrer: CreditCardAuthorizationReferrer;
	account: Account;
	user: User;
	route: string;
	consumer: Consumer;
	isEditCreditCardInfoDisabled?: boolean;
	successFlag?: SuccessFlag;
	billingInfo?: BillingInfo;
}

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomOverlaysOptionsMap {
		[billingPiletModals.creditCardModal]: PaymentGatewayProps;
	}

	interface PiralCustomExtensionSlotsMap {
		[billingPiletExtensionSlots.cancelAccount]: undefined;
		[billingPiletExtensionSlots.trialBanner]: TrialInfoProps;
		[billingPiletExtensionSlots.viewReceipts]: undefined;
	}
}
