const piletPrefix = 'billing_';
const eventPrefix = 'event_';

// usage: data-analytics-id={analyticsIds.createButton}
export const analyticsIds = {
	editCCButton: `${piletPrefix}EditCCButton`,
	switchToCCButton: `${piletPrefix}SwitchToCCButton`,
};

// usage: sendPendoTrackEvent(events.navigateToMyTemplatesTab, { optional: 'metadata' });
export const events = {
	closeEditCCModal: `${piletPrefix}${eventPrefix}CloseEditCCModal`,
	closeSwitchToCCModal: `${piletPrefix}${eventPrefix}CloseSwitchToCCModal`,
	ccUpdateSuccess: `${piletPrefix}${eventPrefix}CCUpdateSuccess`,
	ccUpdateFailure: `${piletPrefix}${eventPrefix}CCUpdateFailure`,
};

interface Window {
	pendo?: any;
}

export function sendPendoTrackEvent(event: string, metadata: any = {}) {
	try {
		const pendo = (window as unknown as Window).pendo;
		if (!pendo?.track) {
			return;
		}
		const metadataWithProductName = {
			...metadata,
			product: 'ShareFile - Billing',
			type: event,
		};
		pendo.track(event, metadataWithProductName);
	} catch {}
}
