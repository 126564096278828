/* tslint:disable */
/* This file was generated by @citrite/translate. Manual changes will be lost. */
import { Language } from '@citrite/translate';
export type locale = 'de' | 'en' | 'es' | 'fr' | 'it' | 'ja' | 'nl' | 'pt-BR';
export const languages: Language[] = [
  {
    "key": "de",
    "content": {
      "cancel_account": {
        "cancel_account": "Konto kündigen",
        "cancel_trial": "Testversion kündigen",
        "contact_information": {
          "call_international": "International: +1 919 745 6111",
          "call_toll_free": "Gebührenfrei anrufen: +1 800 441 3453",
          "call_uk": "UK: +44 800 680 0621",
          "here_for_you": "Wir sind für Sie da.",
          "support": "Support",
          "visit": "Gehen Sie zu"
        },
        "contact_us": "Kontaktieren Sie uns",
        "continue_cancellation": "Kündigen",
        "did_you_know": "Schon gewusst?",
        "did_you_know_desc": "Wenn Sie das ShareFile-Konto kündigen, verzichten Sie u. a. auf folgende großartigen Features:",
        "download_now": "Jetzt herunterladen",
        "error_banner": "Abbrechen fehlgeschlagen. Versuchen Sie es erneut oder wenden Sie sich an unser Support-Team",
        "features": {
          "customer_access": "Unbeschränkter Zugriff für Ihre Kunden",
          "customer_access_desc": "Geben Sie Ihren Kunden kostenlosen Zugriff auf Dateien und legen Sie fest, welche Dateien angezeigt werden.",
          "customer_support": "Kundensupport",
          "customer_support_desc": "per E-Mail oder Telefon. Wir sind für Sie verfügbar!",
          "esignature": "E-Signatur",
          "esignature_desc": "Senden Sie Dateien zur Signatur direkt vom ShareFile-Konto.",
          "sharefile_mobile": "ShareFile Mobile Apps",
          "sharefile_mobile_desc": "Greifen Sie jederzeit von Ihrem Smartphone oder Tablet aus auf Dateien und Ordner zu.",
          "sharefile_plugin": "ShareFile Plug-In für Microsoft Outlook",
          "sharefile_plugin_desc": "Senden Sie große Dateien sicher direkt aus Outlook.",
          "total_security": "Höchste Sicherheit",
          "total_security_desc": "Datenschutz, dem Sie vertrauen können."
        },
        "form": {
          "account_name": "Kontoname",
          "back_to_admin": "Zurück zu 'Kontoverwaltung'",
          "cancel_my_account": "Konto kündigen",
          "cancel_my_trial": "Testversion kündigen",
          "cancellation_reason": {
            "Lacks_required_compliance": "Fehlende erforderliche Konformität",
            "lack_of_integrations": "Fehlende Integrationen",
            "lacks_required_feature": "Fehlende erforderliche Feature(s)",
            "no_longer_needed": "Nicht mehr benötigt",
            "poor_account_support": "Schlechter Accountsupport (Verkauf, Technik)",
            "prefer_competitive_product": "Bevorzuge Wettbewerbsartikel",
            "security_concerns": "Sicherheitsbedenken",
            "technical_issues": "Technische Probleme",
            "too_difficult_to_use": "Zu schwierig zu bedienen",
            "too_expensive": "Zu teuer"
          },
          "comments_label": "Anmerkungen",
          "comments_placeholder": "Bitte teilen Sie uns mit, warum Sie Ihr Konto kündigen. Können wir etwas tun, um ShareFile für Sie zu verbessern?",
          "employees_desc": "{{employeeCount}} von {{employeeMax}} gekauft",
          "employees_title": "Mitarbeiter",
          "forgot_your_password": "Kennwort vergessen?",
          "more_info": "Bitte nehmen Sie sich eine Minute Zeit, um uns zu sagen, warum Sie Ihr Konto kündigen.",
          "once_you_cancel": "Wenn Sie ShareFile kündigen, werden Ihre Dateien nach 30 Tagen gelöscht.",
          "password_label": "Kontokennwort eingeben",
          "password_missing": "Geben Sie Ihr Kontokennwort ein",
          "password_placeholder": "Kennwort",
          "reason_missing": "Wählen Sie einen Grund aus",
          "storage_usage_desc": "{{mbUsed}} MB von {{mbMax}} verwendet bis {{dateFormatted}}",
          "storage_usage_title": "Speichernutzung",
          "title": "Ich kündige das ShareFile-Konto aus folgenden Gründen:",
          "to_authorize": "Wenn Sie mit der Kündigung fortfahren möchten, geben Sie Ihr Kontokennwort ein, um ShareFile zu autorisieren, Ihre Dateien nach 30 Tagen zu löschen."
        },
        "in_progress_banner": "Wir arbeiten daran, Ihr Konto zu aktualisieren. Dies kann einige Zeit dauern – schauen Sie bald noch einmal vorbei!",
        "learn_more": "Weitere Informationen",
        "let_us_help_1": "Wir helfen Ihnen gerne, Ihr ShareFile-Konto optimal zu nutzen.",
        "let_us_help_2": "Kontaktieren Sie unser Customer Care Team,",
        "let_us_help_3": "um mehr über diese und andere ShareFile-Funktionen zu erfahren.",
        "modal_confirm": "OK",
        "modal_message": "Ihr Konto wurde gekündigt. Es bleibt jedoch bis zum Ende des Abrechnungszyklus aktiv. Sie können Ihr Konto wieder aktivieren, indem Sie im Kontoverwaltungsmenü auf 'Konto reaktivieren' klicken.",
        "modal_message_trial": "Ihre Testversion wurde gekündigt. Danke, dass Sie ShareFile ausprobiert haben. Sie können Ihr Konto wieder aktivieren, indem Sie im Kontoverwaltungsmenü auf 'Konto reaktivieren' klicken.",
        "modal_title": "Konto gekündigt",
        "modal_title_trial": "Testversion gekündigt",
        "page_title": "Konto kündigen",
        "page_title_trial": "Testversion kündigen",
        "password_was_incorrect": "Kennwort war falsch",
        "resources_card": {
          "ask_the_community": "Fragen Sie die Community",
          "sharefile_knowledgebase": "ShareFile Knowledge Base",
          "title": "Haben Sie diese Ressourcen ausprobiert?",
          "training_videos": "Schulungsvideos"
        },
        "sorry_to_go": "Schade, dass Sie Ihr Konto kündigen.",
        "sorry_to_go_desc": "ShareFile wurde für Sie entwickelt. Wir arbeiten ständig daran, unseren Service für Sie zu verbessern und Ihre Dateifreigabe- und Speicherbedürfnisse zu erfüllen."
      },
      "convert_to_paid": {
        "advanced_plan": {
          "features": {
            "document_feedback": "Feedback und Genehmigungen für Dokumente",
            "email_encryption": "Verschlüsselung aktivieren",
            "features_include": "Features sind:",
            "outlook_and_gmail_plugin": "Outlook- und Gmail-Plug-Ins",
            "threat_detection_alerts": "Benachrichtigungen zur Bedrohungserkennung",
            "unlimited_client_users": "Unbegrenzte Clientbenutzer"
          },
          "name": "Erweitert"
        },
        "billing_address": {
          "city": "Stadt",
          "country": "Land",
          "state": "Bundesland",
          "street": "Straße",
          "title": "Rechnungsadresse",
          "zip": "Postleitzahl"
        },
        "billing_cycle_selection": {
          "annual_save": "(10 % Einsparungen)",
          "choose_billing_cycle": "Abrechnungszeitraum wählen",
          "choose_billing_cycle_text": "Der Preis basiert auf der Gesamtzahl der Mitarbeiterbenutzer.",
          "online_only": "(Nur online)",
          "pay_annually": "Jährliche Zahlung",
          "pay_monthly": "Monatliche Zahlung"
        },
        "buttons": {
          "cancel": "Abbrechen",
          "convert_now": "Jetzt konvertieren",
          "convert_now_tooltip": "Vergewissern Sie sich, dass Adresse und Kreditkarte vor der Konvertierung überprüft wurden.",
          "upgrade": "Upgrade"
        },
        "convert_modal": {
          "confirm_purchase": "Kauf bestätigen",
          "confirm_purchase_description": "Wenn Sie 'Jetzt konvertieren' wählen, wird Ihnen heute {{billingRate}} berechnet und Ihr Konto wird in ein kostenpflichtiges Konto umgewandelt."
        },
        "credit_card": {
          "confirmed": "Kreditkarte bestätigt",
          "confirmed_description": "Ihre Kreditkartendaten wurden überprüft.",
          "declined": "Kreditkarte abgelehnt",
          "declined_description": "Wir konnten Ihre Karte nicht überprüfen. Vergewissern Sie sich, dass Ihre Eingabe korrekt ist, und versuchen Sie es erneut.",
          "edit": "Bearbeiten",
          "error_message": "Die Verifizierung der Kreditkarte ist fehlgeschlagen. Überprüfen Sie die Kreditkarteninformationen und versuchen Sie es noch einmal.",
          "information": "Kreditkartendaten"
        },
        "current_licenses": "Mitarbeiterlizenzen: {{users}}",
        "current_storage": "Speicher: {{storage}} GB",
        "disable_trial_conversion_message": "Wenden Sie sich an unser Team, um ein Abonnement zu finden, das in Ihrem Land funktioniert: +1 (800)-441-3453",
        "employee_accounts": "Mitarbeiterkonten",
        "employee_accounts_description": "Ihr Abonnement umfasst {{users}} Mitarbeiterkonten. Sie können Ihrem Abo weitere Konten hinzufügen.",
        "employee_accounts_description_vdr": "Ihr Abo umfasst {{users}} Mitarbeiterlizenzen und {{storage}} GB Speicher. Sie können unten mehr hinzufügen.",
        "employee_licenses": "Mitarbeiterlizenzen",
        "employee_licenses_storage": "Mitarbeiterlizenzen und Speicher",
        "error_loading_plans": "Beim Laden der Abonnements ist ein Fehler aufgetreten. Versuchen Sie es später.",
        "industry_advantage_plan": {
          "features": {
            "accounting_workflows": "Vorkonfigurierte Buchhaltungsworkflows",
            "automated_engagement": "Automatisierte Auftragsschreiben",
            "custom_tax_templates": "Gebrauchsfertige benutzerdefinierte Steuervorlagen",
            "custom_tax_user_experience": "Individuell angepasste Steuererklärung",
            "features_include": "Alle Funktionen des Premium-Abos, plus:",
            "integrated_client_onboarding": "Integriertes Kundenonboarding"
          },
          "name": "Industry Advantage",
          "sharefile_for_accounting": "ShareFile für Buchhaltung"
        },
        "left_nav_button_text": "Testversion konvertieren",
        "month": "pro Monat",
        "number_of_users_selection": {
          "title": "Benutzerzahl",
          "user_limit_message": "Das ausgewählte Abonnement erlaubt mindestens {{min}} Benutzer und maximal {{max}} Benutzer."
        },
        "plan": {
          "base_employee_accounts": "{{employee}} Mitarbeiterkonten",
          "max_file_size": "100 GB",
          "max_storage": "1 TB/Lizenz",
          "max_storage_vdr": "1GB/Lizenz",
          "storage": "{{storage}} Speicherplatz"
        },
        "plan_chart": {
          "need_help": "Brauchen Sie Hilfe? Wir sind für Sie da. Kontaktieren Sie den Support unter sales@sharefile.com oder rufen Sie 1 (800)-441-3453 an, um weitere Abonnementoptionen zu erhalten.",
          "upgrade_plan": "Abonnement upgraden",
          "view_detailed_comparison": "Detailvergleich anzeigen"
        },
        "plan_summary": {
          "annually": "Jährlich",
          "billing": "Abrechnung",
          "monthly": "Monatlich",
          "plan": "{{name}}-Abonnement",
          "plus_applicable_taxes": "Zuzüglich anfallender Steuern",
          "storage_max_file_size": "Speicher/max. Dateigröße:",
          "users": "Benutzer",
          "your_total": "Gesamtsumme"
        },
        "premium_plan": {
          "cloud_storage": "Cloudspeicher",
          "features": {
            "all_the_esignatures": "Alle E-Signaturen, die Sie benötigen, inklusive KBA",
            "compliance_support": "Unterstützung für HIPAA-, SEC- und FINRA-Compliance",
            "digital_document_request_lists": "Digitale Dokumentenanforderungslisten",
            "features_include": "Alle Funktionen des Advanced-Abos, plus:",
            "rapid_client_onboarding": "Schnelles Kunden-Onboarding und maßgeschneiderte Portale"
          },
          "most_popular": "Am beliebtesten",
          "name": "Premium"
        },
        "purchase_confirmed": "Kauf bestätigt",
        "something_went_wrong": "Etwas ist schief gelaufen",
        "storage_distribution_description": "Der Speicher wird von allen lizenzierten Mitarbeitern gemeinsam genutzt und ist nicht pro Benutzer begrenzt.",
        "storage_gb": "{{storage}} GB",
        "storage_only": "Speicher",
        "successful_conversion": "Sie haben Ihr Konto erfolgreich umgewandelt.",
        "title": "In kostenpflichtiges Konto konvertieren",
        "try_again_or_contact_support": "Versuchen Sie es noch einmal oder wenden Sie sich an den Support.",
        "upgrade_failed": "Upgrade fehlgeschlagen",
        "vdr_plan": {
          "name": "Virtual Data Room",
          "vdr_account_description": "Erweiterte Sicherheit, gemeinsame Nutzung und Kontrolle vertraulicher Daten.",
          "vdr_max_file_size": "10 GB",
          "vdr_max_storage": "5 GB"
        }
      },
      "edit_billing_information": {
        "address": {
          "billing_address": "Rechnungsadresse",
          "city": "Stadt",
          "country": "Land",
          "state_province": "Bundesland/Kanton",
          "street": "Straße",
          "zip_postal_code": "Postleitzahl"
        },
        "cancel": "Abbrechen",
        "credit_card": {
          "card_details": "Kreditkartendetails",
          "card_exp_date": "Ablaufdatum",
          "card_first_name": "Vorname",
          "card_last_name": "Nachname",
          "card_number": "Kartennummer",
          "card_type": "Kartentyp",
          "card_type_american_express": "American Express",
          "card_type_mastercard": "MasterCard",
          "card_type_not_supported": "Kreditkartentyp wird nicht unterstützt. Bitte verwenden Sie Visa, Mastercard oder American Express",
          "card_type_unknown": "Unbekannt",
          "card_type_visa": "Visa",
          "title": "Kreditkarteninformationen"
        },
        "credit_card_notifications": {
          "add_successful": "Kreditkarteninformationen wurden hinzugefügt.",
          "save_failure": "Fehler beim Speichern der Kreditkarteninformationen",
          "update_failure": "Aktualisierung der Kreditkarteninformationen fehlgeschlagen",
          "update_successful": "Kreditkarteninformationen aktualisiert."
        },
        "disable_trial_conversion_message": "Wenden Sie sich an unser Team, um ein Abonnement zu finden, das in Ihrem Land funktioniert: +1 (800)-441-3453",
        "edit": "Bearbeiten",
        "get_billing_info_failed": "Ein Fehler ist beim Abrufen Ihrer Abrechnungsinformationen aufgetreten. Versuchen Sie es noch einmal.",
        "info_banner": "Hinweis: Aktualisierungen von Abrechnungsinformationen sind u. U. nicht sofort sichtbar. Es kann bis zu 24 Stunden dauern, bis die Änderungen unten angezeigt werden.",
        "left_nav_button_text": "Rechnungsinformationen bearbeiten",
        "name_on_card": "Name auf der Karte",
        "no_billing_info_banner": "Wir können diese Seite derzeit nicht laden. Kontaktieren Sie unseren Kundendienst, um Ihre Rechnungsinformationen zu bearbeiten.",
        "province": "Provinz",
        "save": "Speichern",
        "state": "Bundesland",
        "support": {
          "chat_with_us": "Chatten Sie mit uns",
          "contact_support": "Die Adresse konnte nicht verifiziert werden. Wenden Sie sich an den Support.",
          "contact_support_info": "wenden Sie sich an den Support"
        },
        "title": "Abrechnungsinformationen bearbeiten",
        "update": "Aktualisieren",
        "update_billing_type": {
          "info_message": "Die aktuelle Abrechnungsart ist {<invoice>}. Sie können zu Kreditkartenzahlung wechseln.",
          "invoice": "Rechnung %(number)s",
          "switch_to_credit_card": "Zu Kreditkarte wechseln",
          "title": "Abrechnungsart aktualisieren"
        },
        "update_notifications": {
          "contact_support": "contact support",
          "save_failed": "Speichern nicht möglich",
          "save_success": "Ihre Änderungen wurden gespeichert.",
          "something_went_wrong": "Etwas ist schief gelaufen",
          "try_again": "Die Adresse konnte nicht verifiziert werden. Versuchen Sie es erneut.",
          "try_again_or_contact_support": "Bitte versuchen Sie es erneut oder {<contactSupport>}."
        },
        "vat_id": "Umsatzsteuer-ID",
        "verify_address": "Adresse verifizieren"
      },
      "files": {
        "trial_expiration_info": {
          "day_count": "1 Tag",
          "day_count_plural": "{{count}} Tage",
          "days_left_in_your_trial": "Sie haben noch {<dayCount>} in Ihrer Testversion frei. ",
          "upgrade_to_a_plan": "Upgrade auf ein Abonnement durchführen"
        }
      },
      "lock_error": "Wir konnten diese Anforderung nicht bearbeiten, während eine vorherige Anforderung verarbeitet wird.",
      "manage_plan": {
        "add_storage_btn": "Speicher hinzufügen",
        "add_users_btn": "Benutzer hinzufügen",
        "advanced": {
          "feature_email_encryption": "Verschlüsselung aktivieren",
          "feature_feedback_approvals": "Feedback und Genehmigungen für Dokumente",
          "feature_outlook_gmail_plugin": "Outlook- und Gmail-Plug-Ins",
          "feature_threat_detection": "Benachrichtigungen zur Bedrohungserkennung",
          "features_header": "Alle Standardfunktionen, plus:",
          "title": "Advanced",
          "title_0gb": "Advanced 0 GB"
        },
        "cloud_storage": "Cloudspeicher",
        "connectors": {
          "title_0gb": "Connectors 0 GB"
        },
        "current_plan": "Aktuelles Abonnement",
        "get_usageplan_failed": "Ein Fehler ist beim Abrufen Ihrer Abonnementinformationen aufgetreten. Versuchen Sie es noch einmal.",
        "included": "Inklusive",
        "industry_advantage": {
          "feature_accounting_workflows": "Vorkonfigurierte Buchhaltungsworkflows",
          "feature_automated_engagements": "Automatisierte Auftragsschreiben",
          "feature_custom_tax_templates": "Gebrauchsfertige benutzerdefinierte Steuervorlagen",
          "feature_custom_tax_user_experience": "Individuell angepasste Steuererklärung",
          "feature_integrated_client_onboarding": "Integriertes Kundenonboarding",
          "features_header": "Alle Funktionen des Premium-Abos, plus:",
          "title": "Industry Advantage"
        },
        "left_nav_button_text": "Abonnement verwalten",
        "manage_plan_title": "Abonnement verwalten",
        "message_after_update": "Wir arbeiten daran, Ihr Abonnement zu aktualisieren. Dies kann einige Zeit dauern – schauen Sie bald noch einmal vorbei!",
        "modals": {
          "add_button": "Hinzufügen",
          "additional_users": "Zusätzliche Benutzer",
          "alert_message": "Wenn Sie <boldText> wählen, berechnen wir Ihnen einen anteiligen Betrag für die Differenz der Abonnementkosten.",
          "annual_billing_note": "Ihre erste jährliche Zahlung wird am {{date}} fällig.",
          "annual_billing_text": "Sie können 10 % bei Ihrem ShareFile-Abonnement sparen, indem Sie jährlich zahlen. Hier finden Sie eine Übersicht über die Kosten Ihres neuen Abonnements.",
          "annual_billing_title": "Jährliche Abrechnung",
          "cancel_button": "Abbrechen",
          "credit_card_iframe_error": "There was an error loading your credit card details. Please try again later or contact support.",
          "error_alert": "Ihr Abonnement konnte nicht aktualisiert werden. Versuchen Sie es erneut.",
          "extended_storage": "{{count}} TB erweiterter Speicher",
          "new_billing_amount": "Neuer Rechnungsbetrag",
          "new_monthly_total": "Neuer Monatsbetrag",
          "new_plan_total": "Neuer Abonnementbetrag",
          "new_storage_total": "Neuer Speicherbetrag",
          "new_user_total": "Neue Besucheranzahl",
          "per_month": "{{price}} pro Monat*",
          "per_year": "{{price}} pro Jahr*",
          "plus_applicable_taxes": "* zuzüglich anfallender Steuern",
          "price_per_TB": "{{price}} pro 3 TB*",
          "price_per_user": "{{price}} pro Benutzer*",
          "storage_add": "Speicher hinzufügen",
          "storage_data_breakdown_message": "Sie verwenden derzeit {<usedValue>} TB Ihres Speicherplatzes von {<total>} TB.",
          "storage_modal_title": "Speicher hinzufügen",
          "storage_need_additional": "Benötigen Sie zusätzlichen Speicherplatz?",
          "success_alert": "Aktualisiertes Abonnement.",
          "switch_to_annual_button": "Zur jährlichen Abrechnung wechseln",
          "upgrade_button": "Upgrade",
          "upgrade_plan_modal_title": "Abonnement upgraden",
          "users_add": "Benutzer hinzufügen",
          "users_count": "{{count}} Benutzer",
          "users_data_breakdown_message": "Sie verwenden derzeit {<usedValue>} Ihrer {<total>} Benutzerlizenzen.",
          "users_modal_title": "Benutzer hinzufügen",
          "users_need_additional": "Benötigen Sie zusätzliche Benutzer?",
          "value_gb": "{{value}} GB",
          "value_tb": "{{value}} TB"
        },
        "most_popular": "Am beliebtesten",
        "need_help": "Need more help? Contact support via https://support.sharefile.com/s/",
        "next_billing_date_pending": "an Ihrem nächsten Abrechnungsdatum",
        "one_gb_per_users": "1 GB pro Benutzer",
        "one_tb_per_users": "1 TB pro Benutzer",
        "per_month": "pro Monat",
        "per_year": "pro Jahr",
        "plus_taxes": "* zuzüglich anfallender Steuern",
        "premium": {
          "feature_all_esign": "Alle E-Signaturen, die Sie benötigen, inklusive KBA",
          "feature_document_request": "Digitale Dokumentenanforderungslisten",
          "feature_hippa_finra_support": "Unterstützung für HIPAA-, SEC- und FINRA-Compliance",
          "feature_rapid_onboarding": "Schnelles Kunden-Onboarding und maßgeschneiderte Portale",
          "features_header": "Alle Funktionen des Advanced-Abos, plus:",
          "title": "Premium"
        },
        "save_with_annual_btn": "Sparen Sie 10 % bei jährlicher Abrechnung",
        "sharefile_for_accounting": "ShareFile für Buchhaltung",
        "standard": {
          "feature_all_storage": "Der gesamte Speicherplatz, den Sie benötigen",
          "feature_file_encryption": "Dateiverschlüsselung",
          "feature_sync_edit_share": "Synchronisieren, bearbeiten, teilen",
          "feature_unlimited_client": "Unbegrenzte Clientbenutzer",
          "features_header": "Features sind:",
          "title": "Standard",
          "title_0gb": "Standard 0 GB"
        },
        "storage": "Speicher",
        "upgrade_btn": "Upgrade",
        "upgrade_plan": "Abonnement upgraden",
        "users": "Benutzer",
        "users_count": "1 Benutzer",
        "users_count_plural": "{{count}} Benutzer",
        "vdr": {
          "feature_click_trails": "Klickspuren",
          "feature_folder_qa": "Q&A zu Ordnern",
          "feature_visibility_analytics": "Sichtbarkeit, Analysen und mehr",
          "features_header": "Spezialisierter Schutz, einschließlich:",
          "title": "Virtual Data Room"
        }
      },
      "receipts": {
        "actions": {
          "download": "Download",
          "pay": "Bezahlen",
          "title": "Aktionen"
        },
        "cancel": "Abbrechen",
        "credit_card_notifications": {
          "payment_approved": "Zahlung genehmigt. Danke!",
          "payment_failure": "Die Kreditkartenzahlung ist fehlgeschlagen. Bitte wenden Sie sich an den Support."
        },
        "date": "Datum der Hinzufügung",
        "left_nav_button_text": "Belege und Abrechnungsbenachrichtigungen",
        "open_payment": "Ihr Konto weist eine offene Zahlung auf.",
        "page_infotip": "Zum Anzeigen und/oder Drucken von ShareFile-Abrechnungsbelegen klicken Sie unten auf das entsprechende Datum.",
        "page_title": "Belege und Abrechnungsbenachrichtigungen",
        "pay_now": "Bezahlen",
        "status": "Status",
        "type": "Typ"
      }
    }
  },
  {
    "key": "en",
    "content": {
      "cancel_account": {
        "cancel_account": "Cancel Account",
        "cancel_trial": "Cancel Trial",
        "contact_information": {
          "call_international": "International: +1 919.745.6111",
          "call_toll_free": "Call toll free: 1.800.441.3453",
          "call_uk": "UK: +44 (0800) 680.0621",
          "here_for_you": "We are here for you.",
          "support": "Support",
          "visit": "Visit"
        },
        "contact_us": "Contact us",
        "continue_cancellation": "Continue Cancellation",
        "did_you_know": "Did you know?",
        "did_you_know_desc": "When you cancel your ShareFile account, you'll miss out on a lot of great features, including:",
        "download_now": "Download now",
        "error_banner": "Failed to cancel. Please try again or reach out to our support team",
        "features": {
          "customer_access": "Unlimited access for your customers",
          "customer_access_desc": "Let your customers access files for free and manage which files they can see.",
          "customer_support": "Customer Support",
          "customer_support_desc": "by email or phone; we're here to help!",
          "esignature": "E-Signature",
          "esignature_desc": "Send files for signature directly from your ShareFile account.",
          "sharefile_mobile": "ShareFile Mobile Apps",
          "sharefile_mobile_desc": "Access files and folders anytime from your smartphone or tablet.",
          "sharefile_plugin": "ShareFile Plugin for Microsoft Outlook",
          "sharefile_plugin_desc": "Send large files securely directly from Outlook.",
          "total_security": "Total Security",
          "total_security_desc": "Have confidence in your data protection."
        },
        "form": {
          "account_name": "Account Name",
          "back_to_admin": "Back to account administration",
          "cancel_my_account": "Cancel my Account",
          "cancel_my_trial": "Cancel my Trial",
          "cancellation_reason": {
            "Lacks_required_compliance": "Lacks required compliance(s)",
            "lack_of_integrations": "Lack of integrations",
            "lacks_required_feature": "Lacks required feature(s)",
            "no_longer_needed": "No longer needed",
            "poor_account_support": "Poor account support (Sales, Technical)",
            "prefer_competitive_product": "Prefer competitive product",
            "security_concerns": "Security concerns",
            "technical_issues": "Technical issues",
            "too_difficult_to_use": "Too difficult to use",
            "too_expensive": "Too expensive"
          },
          "comments_label": "Comments",
          "comments_placeholder": "Care to tell us more about why you are cancelling your account? If you'd like, we would also love to know more about what we could do to make ShareFile better for you.",
          "employees_desc": "{{employeeCount}} of {{employeeMax}} purchased",
          "employees_title": "Employees",
          "forgot_your_password": "Forgot your password?",
          "more_info": "Please take a minute to tell us a little more about why you are cancelling your account.",
          "once_you_cancel": "Once you cancel, ShareFile will delete your files after 30 days.",
          "password_label": "Enter account password",
          "password_missing": "Please enter account password",
          "password_placeholder": "Password",
          "reason_missing": "Please select a reason",
          "storage_usage_desc": "{{mbUsed}} MB of {{mbMax}} used as of {{dateFormatted}}",
          "storage_usage_title": "Storage Usage",
          "title": "I'm cancelling my ShareFile account because:",
          "to_authorize": "To authorize ShareFile to delete your files after 30 days, please enter your account password to continue with your account cancellation."
        },
        "in_progress_banner": "We’re working to update your account. This may take some time—check back soon!",
        "learn_more": "Learn More",
        "let_us_help_1": "Let us help you get more from your ShareFile account.",
        "let_us_help_2": "Contact our customer care team",
        "let_us_help_3": "to learn more about these and other ShareFile features.",
        "modal_confirm": "OK",
        "modal_message": "Your account has been canceled, but will remain active through the end of the billing cycle. You may reactivate your account by clicking \"Reactivate Account\" in the administrator menu.",
        "modal_message_trial": "Your trial has been canceled.  Thank you for trying ShareFile! You may reactivate your account by clicking \"Reactivate Account\" in the account administration menu.",
        "modal_title": "Account Canceled",
        "modal_title_trial": "Trial Canceled",
        "page_title": "Cancel Account",
        "page_title_trial": "Cancel Trial",
        "password_was_incorrect": "Password was incorrect",
        "resources_card": {
          "ask_the_community": "Ask the Community",
          "sharefile_knowledgebase": "ShareFile Knowledgebase",
          "title": "Have you tried these resources?",
          "training_videos": "Training Videos"
        },
        "sorry_to_go": "We're sorry to see you go!",
        "sorry_to_go_desc": "ShareFile is designed with you in mind, and we’re always trying to find ways to improve our service and meet your file-sharing and storage needs."
      },
      "convert_to_paid": {
        "advanced_plan": {
          "features": {
            "document_feedback": "Document feedback and approvals",
            "email_encryption": "Email encryption",
            "features_include": "Features include:",
            "outlook_and_gmail_plugin": "Outlook and Gmail plugins",
            "threat_detection_alerts": "Threat detection alerts",
            "unlimited_client_users": "Unlimited client users"
          },
          "name": "Advanced"
        },
        "billing_address": {
          "city": "City",
          "country": "Country",
          "state": "State",
          "street": "Street address",
          "title": "Billing Address",
          "zip": "Zip / Postal code"
        },
        "billing_cycle_selection": {
          "annual_save": "(Save 10%)",
          "choose_billing_cycle": "Choose your billing cycle",
          "choose_billing_cycle_text": "Price is based on total number of employee users.",
          "online_only": "(Online Only)",
          "pay_annually": "Pay Annually",
          "pay_monthly": "Pay Monthly"
        },
        "buttons": {
          "cancel": "Cancel",
          "convert_now": "Convert now",
          "convert_now_tooltip": "Ensure address and credit card have been verified before converting.",
          "upgrade": "Upgrade"
        },
        "convert_modal": {
          "confirm_purchase": "Confirm Purchase",
          "confirm_purchase_description": "Selecting \"Convert now\" will charge you {{billingRate}} today and your account will convert to a paid account."
        },
        "credit_card": {
          "confirmed": "Credit card confirmed",
          "confirmed_description": "Your credit card information has been verified.",
          "declined": "Credit card declined",
          "declined_description": "We couldn't verify your card. Check that what you've entered is correct and try again.",
          "edit": "Edit",
          "error_message": "Credit card verification failed. Check the credit card information and try again.",
          "information": "Credit card information"
        },
        "current_licenses": "Employee Licenses: {{users}}",
        "current_storage": "Storage: {{storage}} GB",
        "disable_trial_conversion_message": "Reach out to our team to find a plan that works in your country: +1 (800)-441-3453",
        "employee_accounts": "Employee Accounts",
        "employee_accounts_description": "Your plan includes {{users}} employee accounts, you can add additional accounts to your plan below.",
        "employee_accounts_description_vdr": "Your plan includes {{users}} employee licenses and {{storage}} GB of storage. You can add more below.",
        "employee_licenses": "Employee Licenses",
        "employee_licenses_storage": "Employee Licenses and Storage",
        "error_loading_plans": "There was an error trying to load plans. Please try again later.",
        "industry_advantage_plan": {
          "features": {
            "accounting_workflows": "Pre-configured accounting workflows",
            "automated_engagement": "Automated engagement letters",
            "custom_tax_templates": "Ready-to-use custom tax templates",
            "custom_tax_user_experience": "Customized tax user experience",
            "features_include": "All Premium features, plus:",
            "integrated_client_onboarding": "Integrated client onboarding"
          },
          "name": "Industry Advantage",
          "sharefile_for_accounting": "ShareFile for Accounting"
        },
        "left_nav_button_text": "Convert Free Trial",
        "month": "per month",
        "number_of_users_selection": {
          "title": "Number of users",
          "user_limit_message": "The selected plan allows a minimum of {{min}} users and a maximum of {{max}} users."
        },
        "plan": {
          "base_employee_accounts": "{{employee}} Employee Accounts",
          "max_file_size": "100GB",
          "max_storage": "1TB/license",
          "max_storage_vdr": "1GB/license",
          "storage": "{{storage}} Storage"
        },
        "plan_chart": {
          "need_help": "Need help? We're here for you. Contact support at sales@sharefile.com or call 1 (800)-441-3453 for more plan options.",
          "upgrade_plan": "Upgrade Plan",
          "view_detailed_comparison": "View detailed comparison"
        },
        "plan_summary": {
          "annually": "Annually",
          "billing": "Billing",
          "monthly": "Monthly",
          "plan": "{{name}} Plan",
          "plus_applicable_taxes": "Plus Applicable Taxes",
          "storage_max_file_size": "Storage/Max File Size:",
          "users": "Users",
          "your_total": "Your Total"
        },
        "premium_plan": {
          "cloud_storage": "Cloud storage",
          "features": {
            "all_the_esignatures": "All the e-signatures you need with included KBA",
            "compliance_support": "Support for HIPAA, SEC, and FINRA compliance",
            "digital_document_request_lists": "Digital document request lists",
            "features_include": "All Advanced features, plus:",
            "rapid_client_onboarding": "Rapid client onboarding and custom-branded portals"
          },
          "most_popular": "Most popular",
          "name": "Premium"
        },
        "purchase_confirmed": "Purchase Confirmed",
        "something_went_wrong": "Something went wrong",
        "storage_distribution_description": "Storage is shared between all licensed employees and is not limited per user.",
        "storage_gb": "{{storage}} GB",
        "storage_only": "Storage",
        "successful_conversion": "You have successfully converted your account.",
        "title": "Convert to paid account",
        "try_again_or_contact_support": "Please try again or contact support for further assistance.",
        "upgrade_failed": "Upgrade Failed",
        "vdr_plan": {
          "name": "Virtual Data Room",
          "vdr_account_description": "Advanced security, sharing and control for sensitive data.",
          "vdr_max_file_size": "10 GB",
          "vdr_max_storage": "5GB"
        }
      },
      "edit_billing_information": {
        "address": {
          "billing_address": "Billing Address",
          "city": "City",
          "country": "Country",
          "state_province": "State / Province",
          "street": "Street Address",
          "zip_postal_code": "Zip / Postal Code"
        },
        "cancel": "Cancel",
        "credit_card": {
          "card_details": "Credit Card Details",
          "card_exp_date": "Expiration Date",
          "card_first_name": "First Name",
          "card_last_name": "Last Name",
          "card_number": "Card Number",
          "card_type": "Card Type",
          "card_type_american_express": "American Express",
          "card_type_mastercard": "MasterCard",
          "card_type_not_supported": "Credit card type not supported. Please use Visa, Mastercard or American Express",
          "card_type_unknown": "Unknown",
          "card_type_visa": "Visa",
          "payment_gateway_description": "Click ”New” to add a new payment card.",
          "payment_gateway_title": "Add/ Edit payment card",
          "title": "Credit Card Information"
        },
        "credit_card_iframe_error": "There was an error loading your credit card details. Please try again later or contact support.",
        "credit_card_notifications": {
          "add_successful": "Credit Card Information Added!",
          "save_failure": "Failed to save Credit Card Information",
          "update_failure": "Credit Card Information update failed",
          "update_successful": "Credit Card Information updated!"
        },
        "disable_trial_conversion_message": "Reach out to our team to find a plan that works in your country: +1 (800)-441-3453",
        "edit": "Edit",
        "get_billing_info_failed": "There was an error retrieving your billing information. Please try again later.",
        "info_banner": "Please Note: Updates to billing information may not be reflected immediately. Please allow up to 24 hours for any changes to appear below.",
        "left_nav_button_text": "Edit Billing Info",
        "name_on_card": "Name on Card",
        "no_billing_info_banner": "We aren't able to load this page right now. Contact our customer service team to edit your billing information.",
        "province": "Province",
        "save": "Save",
        "state": "State",
        "support": {
          "chat_with_us": "Chat with us",
          "contact_support": "Unable to verify address, please contact support.",
          "contact_support_info": "contact support"
        },
        "title": "Edit Billing Information",
        "update": "Update",
        "update_billing_type": {
          "info_message": "The current billing type is {<invoice>}. You may switch to credit card payments.",
          "invoice": "invoice",
          "switch_to_credit_card": "Switch to Credit Card",
          "title": "Update Billing Type"
        },
        "update_notifications": {
          "contact_support": "contact support",
          "invalid_address": "The address you entered is invalid. Please try again.",
          "save_failed": "Unable to save",
          "save_success": "Your changes have been saved.",
          "something_went_wrong": "Something went wrong",
          "try_again": "Unable to verify address, please try again.",
          "try_again_or_contact_support": "Please try again or {<contactSupport>} for further assistance."
        },
        "vat_id": "VAT ID",
        "verify_address": "Verify Address"
      },
      "files": {
        "trial_expiration_info": {
          "day_count": "one day",
          "day_count_plural": "{{count}} days",
          "days_left_in_your_trial": "You have {<dayCount>} left in your trial. ",
          "upgrade_to_a_plan": "Upgrade to a plan"
        }
      },
      "lock_error": "We weren't able to handle this request while a previous request is processing.",
      "manage_plan": {
        "add_storage_btn": "Add storage",
        "add_users_btn": "Add users",
        "advanced": {
          "feature_email_encryption": "Email encryption",
          "feature_feedback_approvals": "Document feedback and approvals",
          "feature_outlook_gmail_plugin": "Outlook and Gmail plug-ins",
          "feature_threat_detection": "Threat detection alerts",
          "features_header": "All Standard features, plus:",
          "title": "Advanced",
          "title_0gb": "Advanced 0GB"
        },
        "cloud_storage": "Cloud storage",
        "connectors": {
          "title_0gb": "Connectors 0GB"
        },
        "current_plan": "Current plan",
        "get_usageplan_failed": "There was an error retrieving your plan information. Please try again later.",
        "included": "Included",
        "industry_advantage": {
          "feature_accounting_workflows": "Pre configured accounting workflows",
          "feature_automated_engagements": "Automated engagement letters",
          "feature_custom_tax_templates": "Ready-to-use custom tax templates",
          "feature_custom_tax_user_experience": "Customized tax user experience",
          "feature_integrated_client_onboarding": "Integrated client onboarding",
          "features_header": "All Premium features, plus:",
          "title": "Industry Advantage"
        },
        "left_nav_button_text": "Manage Plan",
        "manage_plan_title": "Manage plan",
        "message_after_update": "We're working to update your plan. This may take some time—check back soon!",
        "modals": {
          "add_button": "Add",
          "additional_users": "Additional users",
          "alert_message": "If you choose {<boldText>}, we'll charge you a prorated amount for the difference in plan cost.",
          "annual_billing_note": "Your first annual payment will be due on {{date}}.",
          "annual_billing_text": "You can save 10% on your ShareFile plan by paying annually. Here's a summary of what your new plan costs would be.",
          "annual_billing_title": "Annual billing",
          "cancel_button": "Cancel",
          "error_alert": "We weren't able to update your plan. Please try again.",
          "extended_storage": "{{count}} TB extended storage",
          "new_billing_amount": "New billing amount",
          "new_monthly_total": "New monthly total",
          "new_plan_total": "New plan total",
          "new_storage_total": "New storage total",
          "new_user_total": "New user total",
          "per_month": "{{price}} per month*",
          "per_year": "{{price}} per year*",
          "plus_applicable_taxes": "*plus applicable taxes",
          "price_per_TB": "{{price}} per 3 TB",
          "price_per_user": "{{price}} per user",
          "storage_add": "Add storage",
          "storage_data_breakdown_message": "You're currently using {<usedValue>} TB of your {<total>} TB of storage.",
          "storage_modal_title": "Add storage",
          "storage_need_additional": "Need additional storage?",
          "success_alert": "Updated plan.",
          "switch_to_annual_button": "Switch to annual billing",
          "upgrade_button": "Upgrade",
          "upgrade_plan_modal_title": "Upgrade plan",
          "users_add": "Add users",
          "users_count": "{{count}} users",
          "users_data_breakdown_message": "You're currently using {<usedValue>} of your {<total>} user licenses.",
          "users_modal_title": "Add users",
          "users_need_additional": "Need additional users?",
          "value_gb": "{{value}} GB",
          "value_tb": "{{value}} TB"
        },
        "most_popular": "Most popular",
        "need_help": "Need more help? Contact support via https://support.sharefile.com/s/",
        "next_billing_date_pending": "on your next billing date",
        "one_gb_per_users": "1 GB per user",
        "one_tb_per_users": "1 TB per user",
        "per_month": "per month",
        "per_year": "per year",
        "plus_taxes": "*plus applicable taxes",
        "premium": {
          "feature_all_esign": "All the e-signatures you need with included KBA",
          "feature_document_request": "Digital document request lists",
          "feature_hippa_finra_support": "Support for HIPAA, SEC, and FINRA compliance",
          "feature_rapid_onboarding": "Rapid client onboarding and custom-branded portals",
          "features_header": "All Advanced features, plus:",
          "title": "Premium"
        },
        "save_with_annual_btn": "Save 10% with annual billing",
        "sharefile_for_accounting": "ShareFile for Accounting",
        "standard": {
          "feature_all_storage": "All the storage you need",
          "feature_file_encryption": "File encryption",
          "feature_sync_edit_share": "Sync, edit, share",
          "feature_unlimited_client": "Unlimited client users",
          "features_header": "Features include:",
          "title": "Standard",
          "title_0gb": "Standard 0GB"
        },
        "storage": "Storage",
        "upgrade_btn": "Upgrade",
        "upgrade_plan": "Upgrade plan",
        "users": "Users",
        "users_count": "1 user",
        "users_count_plural": "{{count}} users",
        "vdr": {
          "feature_click_trails": "Click trails",
          "feature_folder_qa": "Folder Q&A",
          "feature_visibility_analytics": "Visibility, analytics, and more",
          "features_header": "Specialized protection, including:",
          "title": "Virtual data room"
        }
      },
      "receipts": {
        "actions": {
          "download": "Download",
          "pay": "Pay",
          "title": "Actions"
        },
        "awaiting_payment_tooltip": "Invoices might take up to 24 hours to show as paid on this page.",
        "billing_system_change": "We recently made changes to our billing systems that will affect how you pay your ShareFile invoices. For all invoices posted after {<migrationDate>} there is a direct payment link in the invoice file. Invoices posted before {<migrationDate>} will not contain this link; please contact support to make a payment.",
        "cancel": "Cancel",
        "credit_card_notifications": {
          "payment_approved": "Payment approved. Thank you!",
          "payment_failure": "Credit card payment failed. Please reach out to support."
        },
        "date": "Date added",
        "email": "Email",
        "error_getting_logs": "There was an error getting your billing receipts. Please try again later.",
        "left_nav_button_text": "Receipts and Billing Notifications",
        "open_payment": "Your account has an open payment.",
        "page_infotip": "This page has a list of all your ShareFile billing receipts. Invoices might take up to 24 hours to show as paid on this page.",
        "page_title": "Receipts and billing notifications",
        "pay_now": "pay",
        "phone": "Phone",
        "phone_and_email": "{<phone>}: {{number}} | {<email>}: {{address}}",
        "status": "Status",
        "status_approved": "Approved",
        "status_awaiting_payment": "Awaiting payment",
        "status_cleared": "Cleared",
        "status_failed": "Failed",
        "status_invoice_payment_received": "Invoice payment received",
        "status_negated": "Negated",
        "status_open": "Open",
        "status_paid": "Paid",
        "status_pending": "Pending",
        "status_tax_credited": "Tax credited",
        "status_tax_negated": "Tax negated",
        "status_transaction_credited": "Transaction credited",
        "type": "Type"
      }
    }
  },
  {
    "key": "es",
    "content": {
      "cancel_account": {
        "cancel_account": "Cancelar cuenta",
        "cancel_trial": "Cancelar evaluación",
        "contact_information": {
          "call_international": "Llamadas internacionales: +1 919.745.6111",
          "call_toll_free": "Llamada gratuita: 1.800.441.3453",
          "call_uk": "Reino Unido: +44 (0800) 680.0621",
          "here_for_you": "Estamos aquí para ayudarle.",
          "support": "Asistencia técnica",
          "visit": "Visitar"
        },
        "contact_us": "Contactar",
        "continue_cancellation": "Continuar con la cancelación",
        "did_you_know": "¿Lo sabía?",
        "did_you_know_desc": "Al cancelar la cuenta de ShareFile, ya no podrá usar sus excelentes funciones, como:",
        "download_now": "Descargar",
        "error_banner": "Error al cancelar. Inténtelo de nuevo o contacte con nuestro equipo de asistencia",
        "features": {
          "customer_access": "Acceso ilimitado para sus clientes",
          "customer_access_desc": "Permita a sus clientes acceder a archivos gratis y controle qué archivos pueden ver.",
          "customer_support": "Atención al cliente",
          "customer_support_desc": " por correo electrónico o por teléfono. Estamos aquí para ayudarle.",
          "esignature": "Firma electrónica",
          "esignature_desc": "Enviar archivos para firmar directamente desde su cuenta de ShareFile.",
          "sharefile_mobile": "Aplicaciones móviles de ShareFile",
          "sharefile_mobile_desc": "Acceder a sus archivos y carpetas en cualquier momento desde su smartphone o tableta.",
          "sharefile_plugin": "ShareFile Plug-in para Microsoft Outlook",
          "sharefile_plugin_desc": "Enviar archivos grandes con total seguridad directamente desde Outlook.",
          "total_security": "Seguridad total",
          "total_security_desc": "Puede confiar en la protección de sus datos."
        },
        "form": {
          "account_name": "Nombre de cuenta",
          "back_to_admin": "Volver a Administración de cuentas",
          "cancel_my_account": "Cancelar mi cuenta",
          "cancel_my_trial": "Cancelar la evaluación",
          "cancellation_reason": {
            "Lacks_required_compliance": "Carece de la conformidad necesaria",
            "lack_of_integrations": "Falta de integraciones",
            "lacks_required_feature": "Carece de las funciones necesarias",
            "no_longer_needed": "Ya no se necesita",
            "poor_account_support": "Asistencia de cuentas deficiente (ventas, técnico)",
            "prefer_competitive_product": "Prefiero un producto de la competencia",
            "security_concerns": "Problemas de seguridad",
            "technical_issues": "Problemas técnicos",
            "too_difficult_to_use": "Demasiado difícil de usar",
            "too_expensive": "Demasiado caro"
          },
          "comments_label": "Comentarios",
          "comments_placeholder": "¿Le importaría contarnos un poco por qué quiere cancelar la cuenta? Si quiere, cuéntenos también qué podemos hacer para mejorar ShareFile para usted.",
          "employees_desc": "Compra de: {{employeeCount}} de {{employeeMax}}",
          "employees_title": "Empleados",
          "forgot_your_password": "¿Olvidó la contraseña?",
          "more_info": "Nos gustaría pedirle que dedique un momento a contarnos por qué quiere cancelar su cuenta.",
          "once_you_cancel": "Una vez cancelada la cuenta, ShareFile eliminará sus archivos al cabo de 30 días.",
          "password_label": "Introducir contraseña de la cuenta",
          "password_missing": "Introduzca la contraseña de la cuenta",
          "password_placeholder": "Contraseña",
          "reason_missing": "Seleccione un motivo",
          "storage_usage_desc": "Usado {{mbUsed}} MB de {{mbMax}} en {{dateFormatted}}",
          "storage_usage_title": "Uso del almacenamiento",
          "title": "Cancelo mi cuenta de ShareFile porque:",
          "to_authorize": "Para autorizar a ShareFile a eliminar sus archivos al cabo de 30 días, introduzca la contraseña de su cuenta y continuar así con la cancelación de la misma."
        },
        "in_progress_banner": "Estamos trabajando para actualizar su cuenta. Esto podría tardar algún tiempo. Vuelva pronto.",
        "learn_more": "Más información",
        "let_us_help_1": "Permítanos ayudarle a sacar más partido de su cuenta de ShareFile.",
        "let_us_help_2": "Contacte con nuestro equipo de Atención al cliente",
        "let_us_help_3": "para obtener más información sobre éstas y otras funciones de ShareFile.",
        "modal_confirm": "Aceptar",
        "modal_message": "Su cuenta se ha cancelado, pero permanecerá activa hasta el final del ciclo de facturación. Puede reactivar su cuenta haciendo clic en \"Reactivar cuenta\" en el menú del administrador.",
        "modal_message_trial": "La evaluación se ha cancelado.  ¡Gracias por probar ShareFile! Puede reactivar su cuenta haciendo clic en \"Reactivar cuenta\" en el menú de Administración de cuentas.",
        "modal_title": "Cuenta cancelada",
        "modal_title_trial": "Evaluación cancelada",
        "page_title": "Cancelar cuenta",
        "page_title_trial": "Cancelar evaluación",
        "password_was_incorrect": "La contraseña era incorrecta",
        "resources_card": {
          "ask_the_community": "Preguntar a la comunidad",
          "sharefile_knowledgebase": "Base de conocimientos de ShareFile",
          "title": "¿Ha probado a usar estos recursos?",
          "training_videos": "Vídeos de formación"
        },
        "sorry_to_go": "¡Sentimos que se vaya!",
        "sorry_to_go_desc": "ShareFile está diseñado teniendo en cuenta las necesidades del usuario y estamos continuamente buscando maneras de mejorar nuestro servicio y de satisfacer las necesidades de uso compartido y almacenamiento de archivos."
      },
      "convert_to_paid": {
        "advanced_plan": {
          "features": {
            "document_feedback": "Comentarios y aprobaciones de documentos",
            "email_encryption": "Cifrado de correo electrónico",
            "features_include": "Las funciones incluyen:",
            "outlook_and_gmail_plugin": "Plug-ins de Outlook y Gmail",
            "threat_detection_alerts": "Alertas de detección de amenazas",
            "unlimited_client_users": "Usuarios clientes ilimitados"
          },
          "name": "Advanced"
        },
        "billing_address": {
          "city": "Ciudad",
          "country": "País",
          "state": "Estado",
          "street": "Calle",
          "title": "Dirección de facturación",
          "zip": "Código postal/zip"
        },
        "billing_cycle_selection": {
          "annual_save": "(Ahorre 10%)",
          "choose_billing_cycle": "Elija su ciclo de facturación",
          "choose_billing_cycle_text": "El precio se basa en la cantidad total de usuarios empleados.",
          "online_only": "(Solo con conexión)",
          "pay_annually": "Pago anual",
          "pay_monthly": "Pago mensual"
        },
        "buttons": {
          "cancel": "Cancelar",
          "convert_now": "Convertir ahora",
          "convert_now_tooltip": "Asegúrese de que la dirección y la tarjeta de crédito se hayan verificado antes de realizar la conversión.",
          "upgrade": "Actualización"
        },
        "convert_modal": {
          "confirm_purchase": "Confirmar compra",
          "confirm_purchase_description": "Si selecciona \"Convertir ahora\", se le cobrará {{billingRate}} hoy y su cuenta se convertirá en una cuenta de pago."
        },
        "credit_card": {
          "confirmed": "Tarjeta de crédito confirmada",
          "confirmed_description": "Se ha verificado la información de su tarjeta de crédito.",
          "declined": "Tarjeta de crédito rechazada",
          "declined_description": "No pudimos verificar su tarjeta. Compruebe que ha introducido la información correcta e inténtelo de nuevo.",
          "edit": "Editar",
          "error_message": "Error al verificar la tarjeta de crédito. Compruebe la información de la tarjeta de crédito e inténtelo de nuevo.",
          "information": "Información de la tarjeta de crédito"
        },
        "current_licenses": "Licencias de empleados: {{users}}",
        "current_storage": "Almacenamiento: {{storage}} GB",
        "disable_trial_conversion_message": "Contacte con nuestro equipo para encontrar un plan que funcione en su país: +1 (800)-441-3453",
        "employee_accounts": "Cuentas de empleado",
        "employee_accounts_description": "Su plan incluye {{users}} cuentas de empleado; puede agregar cuentas adicionales a su plan a continuación.",
        "employee_accounts_description_vdr": "Su plan incluye {{users}} licencias de empleado y {{storage}} GB de almacenamiento. Puede agregar más a continuación.",
        "employee_licenses": "Licencias de empleado",
        "employee_licenses_storage": "Licencias de empleados y almacenamiento",
        "error_loading_plans": "Se ha producido un error al intentar cargar los planes. Inténtelo de nuevo más tarde.",
        "industry_advantage_plan": {
          "features": {
            "accounting_workflows": "Flujos de trabajo de contabilidad preconfigurados",
            "automated_engagement": "Cartas de compromiso automatizadas",
            "custom_tax_templates": "Plantillas de impuestos personalizadas listas para usar",
            "custom_tax_user_experience": "Experiencia de usuario fiscal personalizada",
            "features_include": "Todas las funciones de Premium, y además:",
            "integrated_client_onboarding": "Incorporación de clientes integrada"
          },
          "name": "Industry Advantage",
          "sharefile_for_accounting": "ShareFile para contabilidad"
        },
        "left_nav_button_text": "Convertir evaluación gratuita",
        "month": "al mes",
        "number_of_users_selection": {
          "title": "Cantidad de usuarios",
          "user_limit_message": "El plan seleccionado permite un mínimo de {{min}} usuarios y un máximo de {{max}} usuarios."
        },
        "plan": {
          "base_employee_accounts": "{{employee}} Cuentas de empleado",
          "max_file_size": "100 GB",
          "max_storage": "1 TB/licencia",
          "max_storage_vdr": "1GB/licencia",
          "storage": "Almacenamiento de {{storage}}"
        },
        "plan_chart": {
          "need_help": "¿Necesita ayuda? Estamos aquí para ayudarle. Contacte con el servicio de asistencia en sales@sharefile.com o llame al 1 (800)-441-3453 para obtener más opciones de planes.",
          "upgrade_plan": "Actualizar plan",
          "view_detailed_comparison": "Ver comparación detallada"
        },
        "plan_summary": {
          "annually": "Anual",
          "billing": "Facturación",
          "monthly": "Mensualmente",
          "plan": "Plan {{name}}",
          "plus_applicable_taxes": "Más impuestos aplicables",
          "storage_max_file_size": "Almacenamiento/Tamaño máximo de archivos:",
          "users": "Usuarios",
          "your_total": "Su total"
        },
        "premium_plan": {
          "cloud_storage": "Almacenamiento en la nube",
          "features": {
            "all_the_esignatures": "Todas las firmas electrónicas que necesite con KBA incluido",
            "compliance_support": "Conformidad con HIPAA, SEC y FINRA",
            "digital_document_request_lists": "Listas de solicitudes de documentos digitales",
            "features_include": "Todas las funciones de Advanced, y además:",
            "rapid_client_onboarding": "Rápida incorporación de clientes y portales personalizados"
          },
          "most_popular": "Lo más popular",
          "name": "Premium"
        },
        "purchase_confirmed": "Compra confirmada",
        "something_went_wrong": "Se ha producido un error",
        "storage_distribution_description": "El almacenamiento se comparte entre todos los empleados con licencia y no está limitado por usuario.",
        "storage_gb": "{{storage}} GB",
        "storage_only": "Almacenamiento",
        "successful_conversion": "Ha convertido correctamente su cuenta.",
        "title": "Convertir en cuenta de pago",
        "try_again_or_contact_support": "Inténtelo de nuevo o contacte con el servicio de asistencia para obtener más ayuda.",
        "upgrade_failed": "Falló la actualización",
        "vdr_plan": {
          "name": "Data Room virtual",
          "vdr_account_description": "Seguridad avanzada, uso compartido y control de información confidencial.",
          "vdr_max_file_size": "10 GB",
          "vdr_max_storage": "5 GB"
        }
      },
      "edit_billing_information": {
        "address": {
          "billing_address": "Dirección de facturación",
          "city": "Ciudad",
          "country": "País",
          "state_province": "Estado / Provincia",
          "street": "Domicilio",
          "zip_postal_code": "Código postal/zip"
        },
        "cancel": "Cancelar",
        "credit_card": {
          "card_details": "Detalles de la tarjeta de crédito",
          "card_exp_date": "Fecha de caducidad",
          "card_first_name": "Nombre de pila",
          "card_last_name": "Apellido",
          "card_number": "Número de tarjeta",
          "card_type": "Tipo de tarjeta",
          "card_type_american_express": "American Express",
          "card_type_mastercard": "MasterCard",
          "card_type_not_supported": "No se admite este tipo de tarjeta de crédito. Utilice Visa, Mastercard o American Express.",
          "card_type_unknown": "Desconocido",
          "card_type_visa": "Visa",
          "title": "Información de la tarjeta de crédito"
        },
        "credit_card_notifications": {
          "add_successful": "Se ha agregado la información de la tarjeta de crédito",
          "save_failure": "Error al guardar la información de la tarjeta de crédito",
          "update_failure": "Error al actualizar la información de la tarjeta de crédito",
          "update_successful": "Se ha actualizado la información de la tarjeta de crédito"
        },
        "disable_trial_conversion_message": "Contacte con nuestro equipo para encontrar un plan que funcione en su país: +1 (800)-441-3453",
        "edit": "Editar",
        "get_billing_info_failed": "Hubo un error al obtener la información de facturación. Inténtelo de nuevo más tarde.",
        "info_banner": "Nota: Es posible que las actualizaciones de la información de facturación no se reflejen inmediatamente. Los cambios pueden tardar hasta 24 horas en aparecer aquí.",
        "left_nav_button_text": "Editar información de facturación",
        "name_on_card": "Nombre en la tarjeta de crédito",
        "no_billing_info_banner": "No se puede cargar la página en este momento. Contacte con nuestro servicio de atención al cliente para modificar los datos de facturación.",
        "province": "Provincia",
        "save": "Guardar",
        "state": "Estado",
        "support": {
          "chat_with_us": "Hable por chat con nosotros",
          "contact_support": "No se puede verificar la dirección. Póngase en contacto con la Asistencia técnica.",
          "contact_support_info": "contacte con la asistencia técnica"
        },
        "title": "Modificar información de facturación",
        "update": "Actualizar",
        "update_billing_type": {
          "info_message": "El tipo de facturación actual es {<invoice>}. Puede cambiar a pagos con tarjeta de crédito.",
          "invoice": "Factura %(number)s",
          "switch_to_credit_card": "Cambiar a tarjeta de crédito",
          "title": "Actualizar tipo de facturación"
        },
        "update_notifications": {
          "contact_support": "contact support",
          "save_failed": "No se puede guardar",
          "save_success": "Sus cambios se han guardado.",
          "something_went_wrong": "Se ha producido un error",
          "try_again": "No se puede verificar la dirección. Inténtelo de nuevo.",
          "try_again_or_contact_support": "Vuelva a intentarlo o {<contactSupport>} para obtener más ayuda."
        },
        "vat_id": "ID de IVA",
        "verify_address": "Verificar dirección"
      },
      "files": {
        "trial_expiration_info": {
          "day_count": "un día",
          "day_count_plural": "{{count}} días",
          "days_left_in_your_trial": "Restantes de prueba: {<documentsLeft>}. ",
          "upgrade_to_a_plan": "Actualizar a un plan"
        }
      },
      "lock_error": "No pudimos gestionar esta solicitud mientras se procesaba una solicitud anterior.",
      "manage_plan": {
        "add_storage_btn": "Agregar almacenamiento",
        "add_users_btn": "Agregar usuarios",
        "advanced": {
          "feature_email_encryption": "Cifrado de correo electrónico",
          "feature_feedback_approvals": "Comentarios y aprobaciones de documentos",
          "feature_outlook_gmail_plugin": "Plug-ins de Outlook y Gmail",
          "feature_threat_detection": "Alertas de detección de amenazas",
          "features_header": "Todas las funciones de Standard, y además:",
          "title": "Advanced",
          "title_0gb": "Advanced 0 GB"
        },
        "cloud_storage": "Almacenamiento en la nube",
        "connectors": {
          "title_0gb": "Conectores 0 GB"
        },
        "current_plan": "Plan actual",
        "get_usageplan_failed": "Hubo un error al obtener la información sobre su plan. Inténtelo de nuevo más tarde.",
        "included": "Incluido",
        "industry_advantage": {
          "feature_accounting_workflows": "Flujos de trabajo de contabilidad preconfigurados",
          "feature_automated_engagements": "Cartas de compromiso automatizadas",
          "feature_custom_tax_templates": "Plantillas de impuestos personalizadas listas para usar",
          "feature_custom_tax_user_experience": "Experiencia de usuario fiscal personalizada",
          "feature_integrated_client_onboarding": "Incorporación de clientes integrada",
          "features_header": "Todas las funciones de Premium, y además:",
          "title": "Industry Advantage"
        },
        "left_nav_button_text": "Administrar plan",
        "manage_plan_title": "Administrar plan",
        "message_after_update": "Estamos trabajando para actualizar su plan. Esto podría tardar algún tiempo. Vuelva pronto.",
        "modals": {
          "add_button": "Agregar",
          "additional_users": "Usuarios adicionales",
          "alert_message": "Si elige {<boldText>}, le cobraremos una cantidad prorrateada por la diferencia en el coste del plan.",
          "annual_billing_note": "Su primer pago anual vencerá el {{date}}.",
          "annual_billing_text": "Puede ahorrar un 10 % en su plan de ShareFile con el pago anual. Aquí puede ver un resumen de lo que costaría el nuevo plan.",
          "annual_billing_title": "Facturación anual",
          "cancel_button": "Cancelar",
          "credit_card_iframe_error": "There was an error loading your credit card details. Please try again later or contact support.",
          "error_alert": "No pudimos actualizar su plan. Inténtelo de nuevo.",
          "extended_storage": "Almacenamiento ampliado de {{count}} TB",
          "new_billing_amount": "Nuevo importe de facturación",
          "new_monthly_total": "Nuevo total mensual",
          "new_plan_total": "Nuevo total del plan",
          "new_storage_total": "Nuevo total de almacenamiento",
          "new_user_total": "Nuevo total de usuarios",
          "per_month": "{{price}} al mes*",
          "per_year": "{{price}} al año*",
          "plus_applicable_taxes": "* más impuestos aplicables",
          "price_per_TB": "{{price}} por 3 TB",
          "price_per_user": "{{price}} por usuario",
          "storage_add": "Agregar almacenamiento",
          "storage_data_breakdown_message": "Actualmente está usando {<usedValue>} TB de su almacenamiento de {<total>} TB.",
          "storage_modal_title": "Agregar almacenamiento",
          "storage_need_additional": "¿Necesita almacenamiento adicional?",
          "success_alert": "Plan actualizado.",
          "switch_to_annual_button": "Cambiar a facturación anual",
          "upgrade_button": "Actualización",
          "upgrade_plan_modal_title": "Actualizar plan",
          "users_add": "Agregar usuarios",
          "users_count": "Usuarios: {{count}}",
          "users_data_breakdown_message": "Está usando {<usedValue>} de sus {<total>} licencias de usuario.",
          "users_modal_title": "Agregar usuarios",
          "users_need_additional": "¿Necesita usuarios adicionales?",
          "value_gb": "{{value}} GB",
          "value_tb": "{{value}} TB"
        },
        "most_popular": "Lo más popular",
        "need_help": "Need more help? Contact support via https://support.sharefile.com/s/",
        "next_billing_date_pending": "en su próxima fecha de facturación",
        "one_gb_per_users": "1 GB por usuario",
        "one_tb_per_users": "1 TB por usuario",
        "per_month": "al mes",
        "per_year": "al año",
        "plus_taxes": "* más impuestos aplicables",
        "premium": {
          "feature_all_esign": "Todas las firmas electrónicas que necesite con KBA incluido",
          "feature_document_request": "Listas de solicitudes de documentos digitales",
          "feature_hippa_finra_support": "Conformidad con HIPAA, SEC y FINRA",
          "feature_rapid_onboarding": "Rápida incorporación de clientes y portales personalizados",
          "features_header": "Todas las funciones de Advanced, y además:",
          "title": "Premium"
        },
        "save_with_annual_btn": "Ahorre un 10 % con la facturación anual",
        "sharefile_for_accounting": "ShareFile para contabilidad",
        "standard": {
          "feature_all_storage": "Todo el almacenamiento que necesita",
          "feature_file_encryption": "Cifrado de archivos",
          "feature_sync_edit_share": "Sincronizar, modificar, compartir",
          "feature_unlimited_client": "Usuarios clientes ilimitados",
          "features_header": "Las funciones incluyen:",
          "title": "Estándar",
          "title_0gb": "Standard 0 GB"
        },
        "storage": "Almacenamiento",
        "upgrade_btn": "Actualización",
        "upgrade_plan": "Actualizar plan",
        "users": "Usuarios",
        "users_count": "1 usuario",
        "users_count_plural": "{{count}} usuarios",
        "vdr": {
          "feature_click_trails": "Rastro de clics",
          "feature_folder_qa": "Carpeta Preguntas y respuestas",
          "feature_visibility_analytics": "Visibilidad, análisis y más",
          "features_header": "Protección especializada, que incluye:",
          "title": "Sala de datos virtual"
        }
      },
      "receipts": {
        "actions": {
          "download": "Descargar",
          "pay": "Pagar",
          "title": "Acciones"
        },
        "cancel": "Cancelar",
        "credit_card_notifications": {
          "payment_approved": "Pago aprobado. ¡Gracias!",
          "payment_failure": "Error en el pago con tarjeta de crédito. Póngase en contacto con asistencia."
        },
        "date": "Fecha de agregado",
        "left_nav_button_text": "Recibos y notificaciones de facturación",
        "open_payment": "Su cuenta tiene un pago sin finalizar.",
        "page_infotip": "Para ver o imprimir los recibos de facturación de ShareFile, haga clic en la fecha correspondiente.",
        "page_title": "Recibos y notificaciones de facturación",
        "pay_now": "pagar",
        "status": "Estado",
        "type": "Tipo"
      }
    }
  },
  {
    "key": "fr",
    "content": {
      "cancel_account": {
        "cancel_account": "Annuler le compte",
        "cancel_trial": "Annuler l'évaluation",
        "contact_information": {
          "call_international": "International : + 1 919.745.6111",
          "call_toll_free": "Appel gratuit : 1.800.441.3453",
          "call_uk": "Royaume-Uni : +44 (0800) 680.0621",
          "here_for_you": "Nous sommes à votre disposition.",
          "support": "l'assistance",
          "visit": "Visiter"
        },
        "contact_us": "Contactez-nous",
        "continue_cancellation": "Continuer l'annulation",
        "did_you_know": "Le saviez-vous ?",
        "did_you_know_desc": "Si vous annulez votre compte ShareFile, vous passerez à côté d'un grand nombre de fonctionnalités, parmi lesquelles :",
        "download_now": "Télécharger",
        "error_banner": "Échec de l'annulation. Réessayez ou contactez notre équipe d'assistance",
        "features": {
          "customer_access": "Accès illimité pour vos clients",
          "customer_access_desc": "Autorisez vos clients à accéder à des fichiers gratuitement et gérez les fichiers auxquels ils ont accès.",
          "customer_support": "Support client",
          "customer_support_desc": "par e-mail ou par téléphone. Nous sommes là pour vous aider !",
          "esignature": "E-Signature",
          "esignature_desc": "Envoyez des fichiers pour signature directement depuis votre compte ShareFile.",
          "sharefile_mobile": "Applications mobiles ShareFile",
          "sharefile_mobile_desc": "Accédez aux fichiers et dossiers à tout moment à partir de votre smartphone ou tablette.",
          "sharefile_plugin": "ShareFile Plugin pour Microsoft Outlook",
          "sharefile_plugin_desc": "Envoyez des fichiers volumineux de façon sécurisée directement à partir d'Outlook.",
          "total_security": "Sécurité complète",
          "total_security_desc": "Faites confiance à vos solutions de protection des données."
        },
        "form": {
          "account_name": "Nom de compte",
          "back_to_admin": "Retour à l'administration du compte",
          "cancel_my_account": "Annuler mon compte",
          "cancel_my_trial": "Annuler mon essai",
          "cancellation_reason": {
            "Lacks_required_compliance": "Manque de conformité aux exigences",
            "lack_of_integrations": "Manque d'intégrations",
            "lacks_required_feature": "Fonctionnalités requises manquantes",
            "no_longer_needed": "Plus nécessaire",
            "poor_account_support": "Support de compte médiocre (ventes, technique)",
            "prefer_competitive_product": "Préfère un produit concurrent",
            "security_concerns": "Problèmes de sécurité",
            "technical_issues": "Problèmes techniques",
            "too_difficult_to_use": "Trop difficile à utiliser",
            "too_expensive": "Trop cher"
          },
          "comments_label": "Commentaires",
          "comments_placeholder": "Vous souhaitez nous en dire plus sur la raison pour laquelle vous voulez annuler votre compte ? Nous aimerions également savoir ce que nous pouvons faire pour améliorer ShareFile.",
          "employees_desc": "{{employeeCount}} sur {{employeeMax}} achetés",
          "employees_title": "Employés",
          "forgot_your_password": "Mot de passe oublié ?",
          "more_info": "Veuillez nous consacrer une minute pour nous expliquer la raison pour laquelle vous souhaitez annuler votre compte.",
          "once_you_cancel": "Si vous annulez, ShareFile supprimera vos fichiers après 30 jours.",
          "password_label": "Entrer le mot de passe du compte",
          "password_missing": "Entrez le mot de passe du compte",
          "password_placeholder": "Mot de passe",
          "reason_missing": "Sélectionnez une raison",
          "storage_usage_desc": "{{mbUsed}} Mo utilisés sur {{mbMax}} à compter du {{dateFormatted}}",
          "storage_usage_title": "Utilisation du stockage",
          "title": "J'annule mon compte ShareFile car :",
          "to_authorize": "Pour autoriser ShareFile à supprimer vos fichiers après 30 jours, entrez le mot de passe de votre compte pour poursuivre l'annulation de votre compte."
        },
        "in_progress_banner": "Nous travaillons à la mise à jour de votre compte. Cela peut prendre un certain temps, vérifiez ultérieurement !",
        "learn_more": "En savoir plus",
        "let_us_help_1": "Nous pouvons vous aider à profiter au maximum de votre compte ShareFile.",
        "let_us_help_2": "Contactez notre équipe d'assistance à la clientèle",
        "let_us_help_3": "pour en savoir plus sur ces fonctionnalités et d'autres fonctionnalités ShareFile.",
        "modal_confirm": "OK",
        "modal_message": "Votre compte a été annulé, mais il restera actif jusqu'à la fin du cycle de facturation. Vous pouvez le réactiver en cliquant sur « Réactiver le compte » dans le menu d'administration.",
        "modal_message_trial": "Votre évaluation a été annulée. Merci d'avoir essayé ShareFile ! Vous pouvez réactiver votre compte en cliquant sur « Réactiver le compte » dans le menu d'administration du compte.",
        "modal_title": "Compte annulé",
        "modal_title_trial": "Version d'évaluation annulée",
        "page_title": "Annuler le compte",
        "page_title_trial": "Annuler l'évaluation",
        "password_was_incorrect": "Le mot de passe est incorrect",
        "resources_card": {
          "ask_the_community": "Demander à la communauté",
          "sharefile_knowledgebase": "Base de connaissances ShareFile",
          "title": "Avez-vous essayé ces ressources ?",
          "training_videos": "Vidéos de formation"
        },
        "sorry_to_go": "Nous sommes désolés de vous voir partir !",
        "sorry_to_go_desc": "ShareFile est spécifiquement conçu pour répondre à vos besoins en matière de partage et de stockage de fichiers. C'est pourquoi nous nous efforçons sans cesse de trouver des moyens d'améliorer notre service."
      },
      "convert_to_paid": {
        "advanced_plan": {
          "features": {
            "document_feedback": "Commentaires et approbations relatifs aux documents",
            "email_encryption": "Chiffrement des e-mails",
            "features_include": "Les fonctionnalités incluent :",
            "outlook_and_gmail_plugin": "Plugins Outlook et Gmail",
            "threat_detection_alerts": "Alertes de détection des menaces",
            "unlimited_client_users": "Utilisateurs clients illimités"
          },
          "name": "Avancé"
        },
        "billing_address": {
          "city": "Ville",
          "country": "Pays",
          "state": "État",
          "street": "Adresse",
          "title": "Adresse de facturation",
          "zip": "Code postal"
        },
        "billing_cycle_selection": {
          "annual_save": "(Économisez 10 %)",
          "choose_billing_cycle": "Choisissez votre cycle de facturation",
          "choose_billing_cycle_text": "Le prix est basé sur le nombre total d'utilisateurs employés.",
          "online_only": "(En ligne uniquement)",
          "pay_annually": "Payer annuellement",
          "pay_monthly": "Payer mensuellement"
        },
        "buttons": {
          "cancel": "Annuler",
          "convert_now": "Convertir maintenant",
          "convert_now_tooltip": "Assurez-vous que l'adresse et la carte de crédit ont été vérifiées avant la conversion.",
          "upgrade": "Mise à niveau"
        },
        "convert_modal": {
          "confirm_purchase": "Confirmer achat",
          "confirm_purchase_description": "Si vous sélectionnez « Convertir maintenant », vous serez facturé {{billingRate}} aujourd'hui et votre compte sera converti en compte payant."
        },
        "credit_card": {
          "confirmed": "Carte de crédit confirmée",
          "confirmed_description": "Les informations de votre carte de crédit ont été vérifiées.",
          "declined": "Carte de crédit refusée",
          "declined_description": "Nous n'avons pas pu vérifier votre carte. Vérifiez l'exactitude des informations saisies et réessayez.",
          "edit": "Modifier",
          "error_message": "La vérification de la carte de crédit a échoué. Vérifiez les informations de la carte de crédit et réessayez.",
          "information": "Informations de carte de crédit"
        },
        "current_licenses": "Licences employé : {{users}}",
        "current_storage": "Stockage : {{storage}} Go",
        "disable_trial_conversion_message": "Contactez notre équipe pour trouver un plan qui fonctionne dans votre pays : +1 (800)-441-3453",
        "employee_accounts": "Comptes d'employés",
        "employee_accounts_description": "Votre plan comprend {{users}} comptes d'employés. Vous pouvez ajouter des comptes supplémentaires à votre plan ci-dessous.",
        "employee_accounts_description_vdr": "Votre plan comprend {{users}} licences employé et {{storage}} Go de stockage. Vous pouvez en ajouter plus ci-dessous.",
        "employee_licenses": "Licences employé",
        "employee_licenses_storage": "Licences employé et stockage",
        "error_loading_plans": "Une erreur s'est produite lors de la tentative de chargement des abonnements. Réessayez plus tard.",
        "industry_advantage_plan": {
          "features": {
            "accounting_workflows": "Workflows comptables préconfigurés",
            "automated_engagement": "Lettres de mission automatisées",
            "custom_tax_templates": "Modèles fiscaux personnalisés prêts à l'emploi",
            "custom_tax_user_experience": "Expérience utilisateur fiscale personnalisée",
            "features_include": "Toutes les fonctionnalités Premium, plus :",
            "integrated_client_onboarding": "Intégration client intégrée"
          },
          "name": "Avantage de l'industrie",
          "sharefile_for_accounting": "ShareFile pour la comptabilité"
        },
        "left_nav_button_text": "Convertir l'évaluation gratuite",
        "month": "par mois",
        "number_of_users_selection": {
          "title": "Nombre d’utilisateurs",
          "user_limit_message": "Le plan sélectionné autorise un minimum de {{min}} utilisateur et un maximum de {{max}} utilisateurs."
        },
        "plan": {
          "base_employee_accounts": "Comptes d'employés {{employee}}",
          "max_file_size": "100 Go",
          "max_storage": "1 To/licence",
          "max_storage_vdr": "1GB/license",
          "storage": "Stockage de {{storage}}"
        },
        "plan_chart": {
          "need_help": "Besoin d'aide ? Nous sommes là. Envoyez un e-mail au service d'assistance à l'adresse sales@sharefile.com ou appelez le 1 (800)-441-3453 pour plus d'options d'abonnement.",
          "upgrade_plan": "Mettre à niveau l'abonnment",
          "view_detailed_comparison": "Voir la comparaison détaillée"
        },
        "plan_summary": {
          "annually": "Annuelle",
          "billing": "Facturation",
          "monthly": "Mensuel",
          "plan": "Plan {{name}}",
          "plus_applicable_taxes": "Plus les taxes applicables",
          "storage_max_file_size": "Taille max de fichier/stockage :",
          "users": "Utilisateurs",
          "your_total": "Votre total"
        },
        "premium_plan": {
          "cloud_storage": "Stockage dans le cloud",
          "features": {
            "all_the_esignatures": "Toutes les signatures électroniques dont vous avez besoin avec KBA inclus",
            "compliance_support": "Prise en charge de la conformité aux normes HIPAA, SEC et FINRA",
            "digital_document_request_lists": "Listes de demandes de documents numériques",
            "features_include": "Toutes les fonctionnalités Advanced, plus :",
            "rapid_client_onboarding": "Intégration rapide des clients et portails personnalisés"
          },
          "most_popular": "Les plus populaires",
          "name": "Premium"
        },
        "purchase_confirmed": "Achat confirmé",
        "something_went_wrong": "Nous avons rencontré un problème",
        "storage_distribution_description": "Le stockage est partagé entre tous les employés titulaires d'une licence et n'est pas limité par utilisateur.",
        "storage_gb": "{{storage}} Go",
        "storage_only": "Stockage",
        "successful_conversion": "Vous avez converti votre compte avec succès.",
        "title": "Convertir en compte payant",
        "try_again_or_contact_support": "Réessayez ou contactez l'assistance pour obtenir de l'aide.",
        "upgrade_failed": "Échec de la mise à niveau",
        "vdr_plan": {
          "name": "Data room virtuelle",
          "vdr_account_description": "Sécurité avancée, partage et contrôle des données sensibles.",
          "vdr_max_file_size": "10 Go",
          "vdr_max_storage": "5 Go"
        }
      },
      "edit_billing_information": {
        "address": {
          "billing_address": "Adresse de facturation",
          "city": "Ville",
          "country": "Pays",
          "state_province": "Département/région",
          "street": "Rue",
          "zip_postal_code": "Code postal"
        },
        "cancel": "Annuler",
        "credit_card": {
          "card_details": "Détails de la carte de crédit",
          "card_exp_date": "Date d'expiration",
          "card_first_name": "Prénom",
          "card_last_name": "Nom",
          "card_number": "Numéro de carte",
          "card_type": "Type de carte",
          "card_type_american_express": "American Express",
          "card_type_mastercard": "MasterCard",
          "card_type_not_supported": "Type de carte de crédit non pris en charge. Veuillez utiliser une carte Visa, Mastercard ou American Express",
          "card_type_unknown": "Inconnue",
          "card_type_visa": "Visa",
          "title": "Informations de carte de crédit"
        },
        "credit_card_notifications": {
          "add_successful": "Informations sur la carte de crédit ajoutées !",
          "save_failure": "Impossible d'enregistrer les informations sur la carte de crédit",
          "update_failure": "La mise à jour des informations sur la carte de crédit a échoué",
          "update_successful": "Informations sur la carte de crédit mises à jour !"
        },
        "disable_trial_conversion_message": "Contactez notre équipe pour trouver un plan qui fonctionne dans votre pays : +1 (800)-441-3453",
        "edit": "Modifier",
        "get_billing_info_failed": "Une erreur s'est produite lors de l'obtention de vos informations de facturation. Veuillez réessayer plus tard.",
        "info_banner": "Remarque : la mise à jour des informations de facturation peut ne pas être reflétée immédiatement. Veuillez prévoir un délai de 24 heures avant que les modifications ne s'affichent ci-dessous.",
        "left_nav_button_text": "Modifier les informations de facturation",
        "name_on_card": "Nom du détenteur de la carte",
        "no_billing_info_banner": "Nous ne pouvons pas charger cette page pour le moment. Contactez notre service client pour modifier vos informations de facturation.",
        "province": "Province",
        "save": "Enregistrer",
        "state": "État",
        "support": {
          "chat_with_us": "Chattez avec nous",
          "contact_support": "Impossible de vérifier l'adresse, veuillez contacter le support.",
          "contact_support_info": "contactez le support"
        },
        "title": "Modifier les informations de facturation",
        "update": "Mettre à jour",
        "update_billing_type": {
          "info_message": "Le type de facturation actuel est {<invoice>}. Vous pouvez passer aux paiements par carte de crédit.",
          "invoice": "Facture %(number)s",
          "switch_to_credit_card": "Passer à carte de crédit",
          "title": "Mettre à jour type de facturation"
        },
        "update_notifications": {
          "contact_support": "contact support",
          "save_failed": "Impossible d'enregistrer",
          "save_success": "Vos modifications ont été enregistrées.",
          "something_went_wrong": "Nous avons rencontré un problème",
          "try_again": "Impossible de vérifier l'adresse, veuillez réessayer.",
          "try_again_or_contact_support": "Veuillez réessayer ou {<contactSupport>} pour obtenir de l'aide."
        },
        "vat_id": "N° de TVA",
        "verify_address": "Vérifier l’adresse"
      },
      "files": {
        "trial_expiration_info": {
          "day_count": "un jour",
          "day_count_plural": "{{count}} jours",
          "days_left_in_your_trial": "Il ne vous reste plus que {<dayCount>} pour profiter de votre version d'essai. ",
          "upgrade_to_a_plan": "Passer à un plan supérieur"
        }
      },
      "lock_error": "Nous n'avons pas pu traiter cette demande, car une demande précédente est déjà en cours de traitement.",
      "manage_plan": {
        "add_storage_btn": "Ajouter du stockage",
        "add_users_btn": "Ajouter des utilisateurs",
        "advanced": {
          "feature_email_encryption": "Chiffrement des e-mails",
          "feature_feedback_approvals": "Commentaires et approbations relatifs aux documents",
          "feature_outlook_gmail_plugin": "Plug-ins Outlook et Gmail",
          "feature_threat_detection": "Alertes de détection des menaces",
          "features_header": "Toutes les fonctionnalités standard, plus :",
          "title": "Avancé",
          "title_0gb": "Advanced 0 Go"
        },
        "cloud_storage": "Stockage dans le cloud",
        "connectors": {
          "title_0gb": "Connecteurs 0 Go"
        },
        "current_plan": "Plan actuel",
        "get_usageplan_failed": "Une erreur s'est produite lors de l'obtention de vos informations de plan. Veuillez réessayer plus tard.",
        "included": "Inclus",
        "industry_advantage": {
          "feature_accounting_workflows": "Workflows comptables préconfigurés",
          "feature_automated_engagements": "Lettres de mission automatisées",
          "feature_custom_tax_templates": "Modèles fiscaux personnalisés prêts à l'emploi",
          "feature_custom_tax_user_experience": "Expérience utilisateur fiscale personnalisée",
          "feature_integrated_client_onboarding": "Intégration client intégrée",
          "features_header": "Toutes les fonctionnalités Premium, plus :",
          "title": "Avantage de l'industrie"
        },
        "left_nav_button_text": "Gérer le plan",
        "manage_plan_title": "Gérer le plan",
        "message_after_update": "Nous travaillons à la mise à jour de votre plan. Cela peut prendre un certain temps, vérifiez ultérieurement.",
        "modals": {
          "add_button": "Ajouter",
          "additional_users": "Utilisateurs supplémentaires",
          "alert_message": "Si vous choisissez {<boldText>}, nous vous facturerons un montant calculé au prorata de la différence de coût du plan.",
          "annual_billing_note": "Votre premier versement annuel sera dû le {{date}}.",
          "annual_billing_text": "Vous pouvez économiser 10 % sur votre plan ShareFile en payant annuellement. Voici un résumé des coûts de votre nouveau plan.",
          "annual_billing_title": "Facturation annuelle",
          "cancel_button": "Annuler",
          "credit_card_iframe_error": "There was an error loading your credit card details. Please try again later or contact support.",
          "error_alert": "Nous n'avons pas pu mettre à jour votre plan. Veuillez réessayer.",
          "extended_storage": "{{count}} To de stockage étendu",
          "new_billing_amount": "Nouveau montant de facturation",
          "new_monthly_total": "Nouveau total mensuel",
          "new_plan_total": "Total du nouveau plan",
          "new_storage_total": "Nouveau total de stockage",
          "new_user_total": "Total des nouveaux utilisateurs",
          "per_month": "{{price}} par mois*",
          "per_year": "{{price}} par an*",
          "plus_applicable_taxes": "*plus les taxes applicables",
          "price_per_TB": "{{price}} par 3 To",
          "price_per_user": "{{price}} par utilisateur",
          "storage_add": "Ajouter du stockage",
          "storage_data_breakdown_message": "Vous utilisez actuellement {<usedValue>} To de stockage sur un total de {<total>} To.",
          "storage_modal_title": "Ajouter du stockage",
          "storage_need_additional": "Besoin de stockage supplémentaire ?",
          "success_alert": "Plan mis à jour.",
          "switch_to_annual_button": "Passer à la facturation annuelle",
          "upgrade_button": "Mise à niveau",
          "upgrade_plan_modal_title": "Passer à un plan supérieur",
          "users_add": "Ajouter des utilisateurs",
          "users_count": "{{count}} utilisateurs",
          "users_data_breakdown_message": "Vous utilisez actuellement {<usedValue>} licences utilisateur sur {<total>}.",
          "users_modal_title": "Ajouter des utilisateurs",
          "users_need_additional": "Besoin d'utilisateurs supplémentaires ?",
          "value_gb": "{{value}} Go",
          "value_tb": "{{value}} To"
        },
        "most_popular": "Les plus populaires",
        "need_help": "Need more help? Contact support via https://support.sharefile.com/s/",
        "next_billing_date_pending": "à votre prochaine date de facturation",
        "one_gb_per_users": "1 Go par utilisateur",
        "one_tb_per_users": "1 To par utilisateur",
        "per_month": "par mois",
        "per_year": "par an",
        "plus_taxes": "*plus les taxes applicables",
        "premium": {
          "feature_all_esign": "Toutes les signatures électroniques dont vous avez besoin avec KBA inclus",
          "feature_document_request": "Listes de demandes de documents numériques",
          "feature_hippa_finra_support": "Prise en charge de la conformité aux normes HIPAA, SEC et FINRA",
          "feature_rapid_onboarding": "Intégration rapide des clients et portails personnalisés",
          "features_header": "Toutes les fonctionnalités Advanced, plus :",
          "title": "Premium"
        },
        "save_with_annual_btn": "Économisez 10 % avec la facturation annuelle",
        "sharefile_for_accounting": "ShareFile pour la comptabilité",
        "standard": {
          "feature_all_storage": "Tout le stockage dont vous avez besoin",
          "feature_file_encryption": "Chiffrement des fichiers",
          "feature_sync_edit_share": "Synchroniser, modifier, partager",
          "feature_unlimited_client": "Utilisateurs clients illimités",
          "features_header": "Les fonctionnalités incluent :",
          "title": "Standard",
          "title_0gb": "Standard 0 Go"
        },
        "storage": "Stockage",
        "upgrade_btn": "Mise à niveau",
        "upgrade_plan": "Passer à un plan supérieur",
        "users": "Utilisateurs",
        "users_count": "1 utilisateur",
        "users_count_plural": "{{count}} utilisateurs",
        "vdr": {
          "feature_click_trails": "Suivi des clics",
          "feature_folder_qa": "Questions-réponses sur le dossier",
          "feature_visibility_analytics": "Visibilité, analyses, etc.",
          "features_header": "Protection spécialisée, comprenant :",
          "title": "Data room virtuelle"
        }
      },
      "receipts": {
        "actions": {
          "download": "Télécharger",
          "pay": "Payer",
          "title": "Actions"
        },
        "cancel": "Annuler",
        "credit_card_notifications": {
          "payment_approved": "Paiement approuvé. Merci !",
          "payment_failure": "Le paiement par carte de crédit a échoué. Veuillez contacter l'assistance."
        },
        "date": "Date ajoutée",
        "left_nav_button_text": "Relevés & notifications de facturation",
        "open_payment": "Votre compte a un paiement en cours.",
        "page_infotip": "Pour afficher et/ou imprimer des relevés de facturation ShareFile, cliquez sur la date appropriée ci-dessous.",
        "page_title": "Relevés & notifications de facturation",
        "pay_now": "payer",
        "status": "État",
        "type": "Type"
      }
    }
  },
  {
    "key": "it",
    "content": {
      "cancel_account": {
        "cancel_account": "Annulla account",
        "cancel_trial": "Annulla prova",
        "contact_information": {
          "call_international": "Internazionale: +1 919.745.6111",
          "call_toll_free": "Numero verde: 1.800.441.3453",
          "call_uk": "Regno Unito: +44 (0800) 680.0621",
          "here_for_you": "Siamo pronti a fornire la nostra assistenza.",
          "support": "Supporto",
          "visit": "Visitare"
        },
        "contact_us": "Contatti",
        "continue_cancellation": "Continua annullamento",
        "did_you_know": "Informazioni utili",
        "did_you_know_desc": "Quando si annulla l'account ShareFile, non sarà più possibile utilizzare tutta una serie di funzionalità utili, tra cui:",
        "download_now": "Scarica ora",
        "error_banner": "Impossibile annullare. Riprovare o contattare il nostro team di supporto",
        "features": {
          "customer_access": "Accesso illimitato per i clienti",
          "customer_access_desc": "È possibile consentire ai client di accedere ai file gratuitamente e gestire quelli che possono visualizzare.",
          "customer_support": "Supporto clienti",
          "customer_support_desc": "via e-mail o telefonicamente. Saremo lieti di offrire la nostra assistenza.",
          "esignature": "Firma elettronica",
          "esignature_desc": "Inviare file per la firma direttamente dall'account ShareFile.",
          "sharefile_mobile": "App per dispositivi mobili ShareFile",
          "sharefile_mobile_desc": "Accedere a file e cartelle in qualsiasi momento da smartphone o tablet.",
          "sharefile_plugin": "ShareFile Plug-in per Microsoft Outlook",
          "sharefile_plugin_desc": "Inviare file di grandi dimensioni in tutta sicurezza direttamente da Outlook.",
          "total_security": "Sicurezza totale",
          "total_security_desc": "I dati sono sempre protetti."
        },
        "form": {
          "account_name": "Nome account",
          "back_to_admin": "Torna ad amministrazione account",
          "cancel_my_account": "Annulla l'account",
          "cancel_my_trial": "Annulla la mia prova",
          "cancellation_reason": {
            "Lacks_required_compliance": "Manca la conformità richiesta",
            "lack_of_integrations": "Mancanza di integrazioni",
            "lacks_required_feature": "Mancano le funzionalità richieste",
            "no_longer_needed": "Non più necessario",
            "poor_account_support": "Supporto all'account scadente (vendite, tecnico)",
            "prefer_competitive_product": "Preferisco un prodotto della concorrenza",
            "security_concerns": "Problemi di sicurezza",
            "technical_issues": "Problemi tecnici",
            "too_difficult_to_use": "Troppo difficile da usare",
            "too_expensive": "Troppo costoso"
          },
          "comments_label": "Commenti",
          "comments_placeholder": "Desideriamo conoscere i motivi per cui si sta procedendo con l'annullamento dell'account. Chiediamo inoltre di fornirci ulteriori dettagli su come possiamo migliorare ShareFile.",
          "employees_desc": "{{employeeCount}} di {{employeeMax}} acquistati",
          "employees_title": "Dipendenti",
          "forgot_your_password": "Password dimenticata?",
          "more_info": "Spiegare i motivi per cui si intende annullare l'account.",
          "once_you_cancel": "Una volta annullato, ShareFile eliminerà i file dopo 30 giorni.",
          "password_label": "Immettere la password dell'account",
          "password_missing": "Immettere la password dell'account",
          "password_placeholder": "Password",
          "reason_missing": "Selezionare un motivo",
          "storage_usage_desc": "{{mbUsed}} MB di {{mbMax}} utilizzati alla data {{dateFormatted}}",
          "storage_usage_title": "Uso dello spazio di archiviazione",
          "title": "Motivi dell'annullamento dell'account ShareFile:",
          "to_authorize": "Per autorizzare ShareFile a eliminare i file dopo 30 giorni, immettere la password dell'account per continuare con l'annullamento."
        },
        "in_progress_banner": "Stiamo lavorando per aggiornare il tuo account. L'operazione potrebbe richiedere un po' di tempo: ricontrollare fra breve.",
        "learn_more": "Ulteriori informazioni",
        "let_us_help_1": "Possiamo aiutare a sfruttare ancora meglio l'account ShareFile.",
        "let_us_help_2": "Contattare il nostro team del servizio clienti",
        "let_us_help_3": "per ricevere ulteriori informazioni su queste e altre funzionalità di ShareFile.",
        "modal_confirm": "OK",
        "modal_message": "L'account è stato annullato, ma resterà attivo fino alla fine del ciclo di fatturazione. Per riattivarlo, fare clic su \"Riattiva account” nel menu dell'amministratore dell'account",
        "modal_message_trial": "La versione di prova è stata annullata. Grazie per aver provato ShareFile. Per riattivare l'account, fare clic su \"Riattiva account” nel menu di amministrazione dell'account.",
        "modal_title": "Account annullato",
        "modal_title_trial": "Versione di prova annullata",
        "page_title": "Annulla account",
        "page_title_trial": "Annulla prova",
        "password_was_incorrect": "Password errata",
        "resources_card": {
          "ask_the_community": "Chiedi alla community",
          "sharefile_knowledgebase": "Knowledge Base di ShareFile",
          "title": "È possibile provare le seguenti risorse.",
          "training_videos": "Video di formazione"
        },
        "sorry_to_go": "Ci spiace che il servizio stia per essere annullato.",
        "sorry_to_go_desc": "ShareFile è stato progettato appositamente per gli utenti aziendali e siamo sempre alla ricerca di nuovi modi per migliorare il nostro servizio e soddisfare le esigenze di memorizzazione e condivisione dei file dei clienti."
      },
      "convert_to_paid": {
        "advanced_plan": {
          "features": {
            "document_feedback": "Commenti e approvazione dei documenti",
            "email_encryption": "Crittografia delle e-mail",
            "features_include": "Le caratteristiche includono:",
            "outlook_and_gmail_plugin": "Plug-in di Outlook e Gmail",
            "threat_detection_alerts": "Avvisi di rilevamento delle minacce",
            "unlimited_client_users": "Utenti cliente illimitati"
          },
          "name": "Advanced"
        },
        "billing_address": {
          "city": "Città",
          "country": "Paese",
          "state": "Provincia",
          "street": "Via o piazza",
          "title": "Indirizzo di fatturazione",
          "zip": "Codice postale"
        },
        "billing_cycle_selection": {
          "annual_save": "(Risparmio del 10%)",
          "choose_billing_cycle": "Scegli il ciclo di fatturazione",
          "choose_billing_cycle_text": "Il prezzo si basa sul numero totale di utenti dipendenti.",
          "online_only": "(Solo online)",
          "pay_annually": "Pagamento annuale",
          "pay_monthly": "Pagamento mensile"
        },
        "buttons": {
          "cancel": "Annulla",
          "convert_now": "Converti ora",
          "convert_now_tooltip": "Assicurarsi che l'indirizzo e la carta di credito siano stati verificati prima della conversione.",
          "upgrade": "Aggiorna"
        },
        "convert_modal": {
          "confirm_purchase": "Conferma acquisto",
          "confirm_purchase_description": "Se si seleziona \"Converti ora\", verranno addebitati {{billingRate}} e l'account verrà convertito in un account a pagamento."
        },
        "credit_card": {
          "confirmed": "Carta di credito confermata",
          "confirmed_description": "I dati della carta di credito sono stati verificati.",
          "declined": "Carta di credito rifiutata",
          "declined_description": "Non siamo riusciti a verificare la carta. Verificare che i dati inseriti siano corretti e riprovare.",
          "edit": "Modifica",
          "error_message": "Verifica della carta di credito non riuscita. Controllare i dati della carta di credito e riprovare.",
          "information": "Dati carta di credito"
        },
        "current_licenses": "Licenze dipendenti: {{users}}",
        "current_storage": "Spazio di archiviazione: {{storage}} GB",
        "disable_trial_conversion_message": "Per trovare un piano valido nel proprio paese, contattare il nostro team: +1 (800)-441-3453",
        "employee_accounts": "Account dipendenti",
        "employee_accounts_description": "Il piano include {{users}} account dipendenti. È possibile aggiungere altri account al piano sotto.",
        "employee_accounts_description_vdr": "Il tuo piano include {{users}} licenze per i dipendenti e {{storage}} GB di spazio di archiviazione. È possibile aggiungerne altri di seguito.",
        "employee_licenses": "Licenze dipendenti",
        "employee_licenses_storage": "Licenze e archiviazione per i dipendenti",
        "error_loading_plans": "Si è verificato un errore durante il tentativo di caricare i piani. Riprovare più tardi.",
        "industry_advantage_plan": {
          "features": {
            "accounting_workflows": "Flussi di lavoro contabili preconfigurati",
            "automated_engagement": "Lettere di incarico automatizzate",
            "custom_tax_templates": "Modelli fiscali personalizzati pronti all'uso",
            "custom_tax_user_experience": "Esperienza fiscale personalizzata per gli utenti",
            "features_include": "Tutte le funzionalità Premium, più:",
            "integrated_client_onboarding": "Inserimento integrato dei clienti"
          },
          "name": "Vantaggio del settore",
          "sharefile_for_accounting": "ShareFile per la contabilità"
        },
        "left_nav_button_text": "Converti versione di prova gratuita",
        "month": "al mese",
        "number_of_users_selection": {
          "title": "Numero di utenti",
          "user_limit_message": "Il piano selezionato consente un minimo di {{min}} utenti e un massimo di {{max}} utenti."
        },
        "plan": {
          "base_employee_accounts": "{{employee}} account dipendenti",
          "max_file_size": "100 GB",
          "max_storage": "1 TB/licenza",
          "max_storage_vdr": "1GB/licenza",
          "storage": "Memoria {{storage}}"
        },
        "plan_chart": {
          "need_help": "Occorre aiuto? Siamo a disposizione. Contattare l'assistenza all'indirizzo sales@sharefile.com o chiamare il numero 1 (800)-441-3453 per ulteriori opzioni di piano.",
          "upgrade_plan": "Aggiorna il piano",
          "view_detailed_comparison": "Visualizza confronto dettagliato"
        },
        "plan_summary": {
          "annually": "Annuale",
          "billing": "Fatturazione",
          "monthly": "Mensile",
          "plan": "Piano {{name}}",
          "plus_applicable_taxes": "Più imposte applicabili",
          "storage_max_file_size": "Memoria/dimensione file max:",
          "users": "Utenti",
          "your_total": "Totale"
        },
        "premium_plan": {
          "cloud_storage": "Archiviazione cloud",
          "features": {
            "all_the_esignatures": "Tutte le firme elettroniche di cui si ha bisogno con KBA incluso",
            "compliance_support": "Supporto della conformità HIPAA, SEC e FINRA",
            "digital_document_request_lists": "Elenchi di richieste di documenti digitali",
            "features_include": "Tutte le funzionalità di Advanced, più:",
            "rapid_client_onboarding": "Integrazione rapida dei clienti e portali personalizzati"
          },
          "most_popular": "Più popolare",
          "name": "Premium"
        },
        "purchase_confirmed": "Acquisto confermato",
        "something_went_wrong": "Si è verificato un problema",
        "storage_distribution_description": "Lo spazio di archiviazione è condiviso tra tutti i dipendenti con licenza e non è limitato per utente.",
        "storage_gb": "{{storage}} GB",
        "storage_only": "Memoria",
        "successful_conversion": "Account convertito correttamente.",
        "title": "Converti in un account a pagamento",
        "try_again_or_contact_support": "Riprovare o contattare il supporto per ulteriore assistenza.",
        "upgrade_failed": "Aggiornamento non riuscito",
        "vdr_plan": {
          "name": "Virtual Data Room",
          "vdr_account_description": "Sicurezza, condivisione e controllo avanzati per i dati riservati.",
          "vdr_max_file_size": "10 GB",
          "vdr_max_storage": "5 GB"
        }
      },
      "edit_billing_information": {
        "address": {
          "billing_address": "Indirizzo di fatturazione",
          "city": "Città",
          "country": "Paese",
          "state_province": "Stato/provincia",
          "street": "Indirizzo civico",
          "zip_postal_code": "CAP"
        },
        "cancel": "Annulla",
        "credit_card": {
          "card_details": "Dettagli della carta di credito",
          "card_exp_date": "Data di scadenza",
          "card_first_name": "Nome",
          "card_last_name": "Cognome",
          "card_number": "Numero carta",
          "card_type": "Tipo di carta",
          "card_type_american_express": "American Express",
          "card_type_mastercard": "MasterCard",
          "card_type_not_supported": "Tipo di carta di credito non supportato. Utilizzare una carta Visa, Mastercard o American Express",
          "card_type_unknown": "Sconosciuta",
          "card_type_visa": "Visa",
          "title": "Dati carta di credito"
        },
        "credit_card_notifications": {
          "add_successful": "Informazioni sulla carta di credito aggiunte.",
          "save_failure": "Impossibile salvare i dati della carta di credito",
          "update_failure": "Impossibile aggiornare le informazioni sulla carta di credito",
          "update_successful": "Informazioni sulla carta di credito aggiornate."
        },
        "disable_trial_conversion_message": "Per trovare un piano valido nel proprio paese, contattare il nostro team: +1 (800)-441-3453",
        "edit": "Modifica",
        "get_billing_info_failed": "Errore durante il recupero delle informazioni di fatturazione. Riprovare più tardi.",
        "info_banner": "Nota: è possibile che gli aggiornamenti ai dati di fatturazione non vengano visualizzati immediatamente. Attendere fino 24 ore prima di visualizzare le modifiche sotto.",
        "left_nav_button_text": "Modifica informazioni di fatturazione",
        "name_on_card": "Nome sulla carta",
        "no_billing_info_banner": "Al momento non siamo in grado di caricare questa pagina. Contattare il nostro servizio clienti per modificare i dati di fatturazione.",
        "province": "Provincia",
        "save": "Salva",
        "state": "Provincia",
        "support": {
          "chat_with_us": "Chatta con noi",
          "contact_support": "Impossibile verificare l'indirizzo. Contattare il supporto.",
          "contact_support_info": "contattare il supporto"
        },
        "title": "Modifica informazioni di fatturazione",
        "update": "Aggiorna",
        "update_billing_type": {
          "info_message": "Il tipo di fatturazione corrente è {<invoice>}. È possibile passare ai pagamenti con carta di credito.",
          "invoice": "Fattura %(number)s",
          "switch_to_credit_card": "Passare alla carta di credito",
          "title": "Aggiornare il tipo di fatturazione"
        },
        "update_notifications": {
          "contact_support": "contact support",
          "save_failed": "Impossibile salvare",
          "save_success": "Le modifiche sono state salvate.",
          "something_went_wrong": "Si è verificato un problema",
          "try_again": "Impossibile verificare l'indirizzo. Riprovare.",
          "try_again_or_contact_support": "Riprovare o {<contactSupport>} per ulteriore assistenza."
        },
        "vat_id": "Partita IVA",
        "verify_address": "Verifica indirizzo"
      },
      "files": {
        "trial_expiration_info": {
          "day_count": "un giorno",
          "day_count_plural": "{{count}} giorni",
          "days_left_in_your_trial": "Si dispone di {<dayCount>} di prova. ",
          "upgrade_to_a_plan": "Passa a un piano"
        }
      },
      "lock_error": "Non siamo stati in grado di gestire questa richiesta mentre è in corso l'elaborazione di una richiesta precedente.",
      "manage_plan": {
        "add_storage_btn": "Aggiungi spazio di archiviazione",
        "add_users_btn": "Aggiungi utenti",
        "advanced": {
          "feature_email_encryption": "Crittografia delle e-mail",
          "feature_feedback_approvals": "Commenti e approvazione dei documenti",
          "feature_outlook_gmail_plugin": "Plug-in di Outlook e Gmail",
          "feature_threat_detection": "Avvisi di rilevamento delle minacce",
          "features_header": "Tutte le funzionalità standard, più:",
          "title": "Advanced",
          "title_0gb": "Advanced 0GB"
        },
        "cloud_storage": "Archiviazione cloud",
        "connectors": {
          "title_0gb": "Connettori 0GB"
        },
        "current_plan": "Piano corrente",
        "get_usageplan_failed": "Errore durante il recupero delle informazioni del piano. Riprovare più tardi.",
        "included": "Incluso",
        "industry_advantage": {
          "feature_accounting_workflows": "Flussi di lavoro contabili preconfigurati",
          "feature_automated_engagements": "Lettere di incarico automatizzate",
          "feature_custom_tax_templates": "Modelli fiscali personalizzati pronti all'uso",
          "feature_custom_tax_user_experience": "Esperienza fiscale personalizzata per gli utenti",
          "feature_integrated_client_onboarding": "Inserimento integrato dei clienti",
          "features_header": "Tutte le funzionalità Premium, più:",
          "title": "Vantaggio del settore"
        },
        "left_nav_button_text": "Gestisci piano",
        "manage_plan_title": "Gestisci piano",
        "message_after_update": "Stiamo lavorando per aggiornare il tuo piano. L'operazione potrebbe richiedere un po' di tempo: si prega di ricontrollare fra breve.",
        "modals": {
          "add_button": "Aggiungi",
          "additional_users": "Utenti aggiuntivi",
          "alert_message": "Se si sceglie {<boldText>}, sarà addebitato un importo proporzionale per la differenza di costo del piano.",
          "annual_billing_note": "Il primo pagamento annuale sarà dovuto il {{date}}.",
          "annual_billing_text": "È possibile risparmiare il 10% sul proprio piano ShareFile pagando annualmente. Ecco un riepilogo di quanto costerebbe il nuovo piano.",
          "annual_billing_title": "Fatturazione annuale",
          "cancel_button": "Annulla",
          "credit_card_iframe_error": "There was an error loading your credit card details. Please try again later or contact support.",
          "error_alert": "Non siamo stati in grado di aggiornare il piano. Si prega di riprovare.",
          "extended_storage": "{{count}} TB di spazio di archiviazione esteso",
          "new_billing_amount": "Nuovo importo di fatturazione",
          "new_monthly_total": "Nuovo totale mensile",
          "new_plan_total": "Totale nuovo piano",
          "new_storage_total": "Nuovo spazio di archiviazione totale",
          "new_user_total": "Totale nuovi utenti",
          "per_month": "{{price}} al mese*",
          "per_year": "{{price}} all'anno*",
          "plus_applicable_taxes": "*più tasse applicabili",
          "price_per_TB": "{{price}} ogni 3 TB",
          "price_per_user": "{{price}} per utente",
          "storage_add": "Aggiungere spazio di archiviazione",
          "storage_data_breakdown_message": "Si stanno attualmente utilizzando {<usedValue>} TB dei {<total>} TB di spazio di archiviazione.",
          "storage_modal_title": "Aggiungere spazio di archiviazione",
          "storage_need_additional": "Si necessita di ulteriore spazio di archiviazione?",
          "success_alert": "Piano aggiornato.",
          "switch_to_annual_button": "Passare alla fatturazione annuale",
          "upgrade_button": "Aggiorna",
          "upgrade_plan_modal_title": "Aggiorna il piano",
          "users_add": "Aggiungi utenti",
          "users_count": "{{count}} utenti",
          "users_data_breakdown_message": "Si stanno attualmente utilizzando {<usedValue>} delle proprie {<total>} licenze d'uso.",
          "users_modal_title": "Aggiungi utenti",
          "users_need_additional": "Si necessita di altri utenti?",
          "value_gb": "{{value}} GB",
          "value_tb": "{{value}} TB"
        },
        "most_popular": "Più popolare",
        "need_help": "Need more help? Contact support via https://support.sharefile.com/s/",
        "next_billing_date_pending": "alla tua prossima data di fatturazione",
        "one_gb_per_users": "1 GB per utente",
        "one_tb_per_users": "1 TB per utente",
        "per_month": "al mese",
        "per_year": "all'anno",
        "plus_taxes": "*più tasse applicabili",
        "premium": {
          "feature_all_esign": "Tutte le firme elettroniche di cui si ha bisogno con KBA incluso",
          "feature_document_request": "Elenchi di richieste di documenti digitali",
          "feature_hippa_finra_support": "Supporto della conformità HIPAA, SEC e FINRA",
          "feature_rapid_onboarding": "Integrazione rapida dei clienti e portali personalizzati",
          "features_header": "Tutte le funzionalità di Advanced, più:",
          "title": "Premium"
        },
        "save_with_annual_btn": "Risparmia il 10% con la fatturazione annuale",
        "sharefile_for_accounting": "ShareFile per la contabilità",
        "standard": {
          "feature_all_storage": "Tutto lo spazio di archiviazione di cui hai bisogno",
          "feature_file_encryption": "Crittografia file",
          "feature_sync_edit_share": "Sincronizzare, modificare, condividere",
          "feature_unlimited_client": "Utenti cliente illimitati",
          "features_header": "Le caratteristiche includono:",
          "title": "Standard",
          "title_0gb": "Standard 0GB"
        },
        "storage": "Memoria",
        "upgrade_btn": "Aggiorna",
        "upgrade_plan": "Aggiorna il piano",
        "users": "Utenti",
        "users_count": "1 utente",
        "users_count_plural": "{{count}} utenti",
        "vdr": {
          "feature_click_trails": "Tracce di clic",
          "feature_folder_qa": "Domande e risposte sulle cartelle",
          "feature_visibility_analytics": "Visibilità, analisi e altro ancora",
          "features_header": "Protezione specializzata, comprendente:",
          "title": "Data room virtuale"
        }
      },
      "receipts": {
        "actions": {
          "download": "Scarica",
          "pay": "Pagare",
          "title": "Azioni"
        },
        "cancel": "Annulla",
        "credit_card_notifications": {
          "payment_approved": "Pagamento approvato. Grazie!",
          "payment_failure": "Pagamento con carta di credito non riuscito. Si prega di contattare il supporto."
        },
        "date": "Data di aggiunta",
        "left_nav_button_text": "Ricevute e notifiche di fatturazione",
        "open_payment": "Il tuo account ha un pagamento aperto.",
        "page_infotip": "Per visualizzare e/o stampare le ricevute di fatturazione di ShareFile, fare clic sulla data appropriata sotto.",
        "page_title": "Ricevute e notifiche di fatturazione",
        "pay_now": "pagare",
        "status": "Stato",
        "type": "Tipo"
      }
    }
  },
  {
    "key": "ja",
    "content": {
      "cancel_account": {
        "cancel_account": "アカウントのキャンセル",
        "cancel_trial": "トライアルのキャンセル",
        "contact_information": {
          "call_international": "その他の国：+1 919.745.6111",
          "call_toll_free": "フリーダイヤル：1.800.441.3453",
          "call_uk": "英国: +44 (0800) 680.0621",
          "here_for_you": "ここからご連絡いただけます。",
          "support": "サポート",
          "visit": "アクセス先: "
        },
        "contact_us": "お問い合わせ",
        "continue_cancellation": "キャンセルの続行",
        "did_you_know": "ご存知でしたか?",
        "did_you_know_desc": "ShareFileアカウントをキャンセルすると、次のようなさまざまな有用な機能を利用できなくなります。",
        "download_now": "今すぐダウンロード",
        "error_banner": "キャンセルできませんでした。再試行するか、サポート チームにお問い合わせください。",
        "features": {
          "customer_access": "カスタマー用の無制限のアクセス",
          "customer_access_desc": "御社のカスタマーにファイルへの無料アクセスを提供し、カスタマーがどのファイルを表示できるのかを管理します。",
          "customer_support": "カスタマーサポート",
          "customer_support_desc": "メールまたは電話経由。サポートを提供します。",
          "esignature": "電子署名",
          "esignature_desc": "署名のためファイルをShareFileアカウントから直接送信します。",
          "sharefile_mobile": "ShareFileモバイルアプリケーション",
          "sharefile_mobile_desc": "スマートフォンやタブレットを使ってファイルやフォルダーにいつでもアクセスします。",
          "sharefile_plugin": "ShareFile Plugin for Microsoft Outlook",
          "sharefile_plugin_desc": "サイズの大きなファイルをOutlookから直接セキュリティで保護して送信します。",
          "total_security": "全体的なセキュリティ",
          "total_security_desc": "データが保護されることを信頼できます。"
        },
        "form": {
          "account_name": "アカウント名",
          "back_to_admin": "アカウント管理に戻る",
          "cancel_my_account": "マイ アカウントをキャンセルする",
          "cancel_my_trial": "トライアルをキャンセルする",
          "cancellation_reason": {
            "Lacks_required_compliance": "必要なコンプライアンスが欠如している",
            "lack_of_integrations": "統合機能がない",
            "lacks_required_feature": "必要な機能が不足している",
            "no_longer_needed": "必要ではなくなった",
            "poor_account_support": "アカウント サポート (営業、技術) が十分ではない",
            "prefer_competitive_product": "競合の製品の方がよい",
            "security_concerns": "セキュリティに関する懸念がある",
            "technical_issues": "技術的な問題",
            "too_difficult_to_use": "使用方法が難しい",
            "too_expensive": "価格が高すぎる"
          },
          "comments_label": "コメント",
          "comments_placeholder": "アカウントをキャンセルする理由について、もう少しお聞かせください。ShareFileをより良い製品にするためのご意見をご記入いただけると幸いです。",
          "employees_desc": "{{employeeCount}}/{{employeeMax}} 個を購入済み",
          "employees_title": "従業員",
          "forgot_your_password": "パスワードを忘れた場合。",
          "more_info": "アカウントをキャンセルする理由について、もう少しお聞かせください。",
          "once_you_cancel": "キャンセルすると、ShareFileは30日後にファイルを削除いたします。",
          "password_label": "アカウントパスワードを入力",
          "password_missing": "アカウント パスワードを入力してください",
          "password_placeholder": "パスワード",
          "reason_missing": "理由を選択してください",
          "storage_usage_desc": "{{dateFormatted}} 時点で {{mbUsed}}/{{mbMax}} MB が使用されています",
          "storage_usage_title": "ストレージ使用量",
          "title": "次の理由により、ShareFileアカウントをキャンセルしようとしています：",
          "to_authorize": "ShareFileによる30日後のファイル削除を認証するため、アカウントパスワードを入力してアカウントのキャンセルを続行してください。"
        },
        "in_progress_banner": "アカウントの更新中です。これには時間がかかる場合があるため、後から確認してください。",
        "learn_more": "詳細情報",
        "let_us_help_1": "ShareFile アカウントのさまざまな機能についてご案内いたします。",
        "let_us_help_2": "カスタマー ケア チームに連絡して、",
        "let_us_help_3": "ShareFile のさまざまな機能についてご確認ください。",
        "modal_confirm": "OK",
        "modal_message": "アカウントはキャンセルされていますが、請求期間が終了するまではアクティブなままです。管理者メニューで［アカウントの再アクティブ化］をクリックするとアカウントを再アクティブ化することができます。",
        "modal_message_trial": "トライアルがキャンセルされました。ShareFileをお試しいただきありがとうございました。アカウント管理メニューで［アカウントの再アクティブ化］をクリックするとアカウントを再アクティブ化することができます。",
        "modal_title": "アカウントがキャンセルされました",
        "modal_title_trial": "トライアルがキャンセルされました",
        "page_title": "アカウントのキャンセル",
        "page_title_trial": "トライアルのキャンセル",
        "password_was_incorrect": "パスワードが違います",
        "resources_card": {
          "ask_the_community": "コミュニティへの問い合わせ",
          "sharefile_knowledgebase": "ShareFileナレッジベース",
          "title": "これらのリソースをお試しになりましたか?",
          "training_videos": "トレーニングビデオ"
        },
        "sorry_to_go": "ご利用いただきありがとうございました。",
        "sorry_to_go_desc": "ShareFile はお客様向けに設計されており、弊社は常にサービスの向上ならびにお客様のファイル共有やストレージに対するニーズを満たすよう心がけています。"
      },
      "convert_to_paid": {
        "advanced_plan": {
          "features": {
            "document_feedback": "ドキュメントのフィードバックと承認",
            "email_encryption": "メールの暗号化",
            "features_include": "次の機能が含まれる:",
            "outlook_and_gmail_plugin": "Outlook および Gmail プラグイン",
            "threat_detection_alerts": "脅威の検出アラート",
            "unlimited_client_users": "無制限のクライアント ユーザー"
          },
          "name": "高度な検索"
        },
        "billing_address": {
          "city": "市町村",
          "country": "国",
          "state": "都道府県",
          "street": "番地",
          "title": "請求先住所",
          "zip": "郵便番号"
        },
        "billing_cycle_selection": {
          "annual_save": "（10%お得）",
          "choose_billing_cycle": "請求サイクルを選択する",
          "choose_billing_cycle_text": "価格は従業員ユーザーの総数に基づいて決まります。",
          "online_only": "（オンラインのみ）",
          "pay_annually": "年払い",
          "pay_monthly": "月払い"
        },
        "buttons": {
          "cancel": "キャンセル",
          "convert_now": "今すぐ変更する",
          "convert_now_tooltip": "変更する前に、住所とクレジットカードを確認済みであることをチェックしてください。",
          "upgrade": "アップグレード"
        },
        "convert_modal": {
          "confirm_purchase": "購入の確認",
          "confirm_purchase_description": "［今すぐ変更する］を選択すると本日 {{billingRate}} が請求され、アカウントは有料アカウントに変更されます。"
        },
        "credit_card": {
          "confirmed": "クレジットカードを確認しました",
          "confirmed_description": "クレジットカード情報が確認されました。",
          "declined": "クレジットカードは拒否されました",
          "declined_description": "カードを確認できませんでした。入力した内容が正しいことを確認して、再試行してください。",
          "edit": "編集",
          "error_message": "クレジット カードの認証に失敗しました。クレジット カード情報を確認して、もう一度試してください。",
          "information": "クレジットカード情報"
        },
        "current_licenses": "従業員ライセンス: {{users}}",
        "current_storage": "ストレージ: {{storage}} GB",
        "disable_trial_conversion_message": "所属する国の有効なプランを見つけるには、弊社のチームにお問い合わせください: +1 (800)-441-3453",
        "employee_accounts": "従業員アカウント",
        "employee_accounts_description": "現在のプランには{{users}}従業員アカウントが含まれます。以下のプランにさらにアカウントを追加できます。",
        "employee_accounts_description_vdr": "このプランには、 {{users}} 個の従業員ライセンスと {{storage}} GB のストレージが含まれています。以下で追加できます。",
        "employee_licenses": "従業員ライセンス",
        "employee_licenses_storage": "従業員ライセンスとストレージ",
        "error_loading_plans": "プランの読み込み中にエラーが発生しました。後で再試行してください。",
        "industry_advantage_plan": {
          "features": {
            "accounting_workflows": "設定済み会計ワークフロー",
            "automated_engagement": "自動化された委任契約書",
            "custom_tax_templates": "すぐに使えるカスタム税務テンプレート",
            "custom_tax_user_experience": "カスタマイズされた税務ユーザー エクスペリエンス",
            "features_include": "すべての Premium 機能および次の機能:",
            "integrated_client_onboarding": "統合されたクライアントのオンボーディング"
          },
          "name": "業種における優位性",
          "sharefile_for_accounting": "会計用 ShareFile"
        },
        "left_nav_button_text": "無料トライアルに変更",
        "month": "月ごと",
        "number_of_users_selection": {
          "title": "ユーザー数",
          "user_limit_message": "選択したプランで許可されるユーザー数は、最小 {{min}}、最大 {{max}} です。"
        },
        "plan": {
          "base_employee_accounts": "{{employee}}従業員アカウント",
          "max_file_size": "100GB",
          "max_storage": "1TB/ライセンス",
          "max_storage_vdr": "1GB/ライセンス",
          "storage": "{{storage}}ストレージ"
        },
        "plan_chart": {
          "need_help": "サポートが必要な場合はお知らせください。プラン オプションの詳細については、サポートあてに sales@sharefile.com までお問い合わせいただくか、1 (800)-441-3453 までお電話ください。",
          "upgrade_plan": "プランのアップグレード",
          "view_detailed_comparison": "詳細な比較を表示する"
        },
        "plan_summary": {
          "annually": "年単位",
          "billing": "請求",
          "monthly": "月単位",
          "plan": "{{name}}プラン",
          "plus_applicable_taxes": "適用される税込み",
          "storage_max_file_size": "ストレージ/最大ファイルサイズ:",
          "users": "ユーザー",
          "your_total": "合計"
        },
        "premium_plan": {
          "cloud_storage": "クラウド ストレージ",
          "features": {
            "all_the_esignatures": "必要なすべての電子署名 (KBA を含む)",
            "compliance_support": "HIPAA、SEC、および FINRA 準拠のサポート",
            "digital_document_request_lists": "デジタル ドキュメントのリクエスト一覧",
            "features_include": "すべての Advanced 機能および次の機能:",
            "rapid_client_onboarding": "迅速なクライアントのオンボードとカスタム ブランドのポータル"
          },
          "most_popular": "よく使われている",
          "name": "Premium"
        },
        "purchase_confirmed": "購入確認済み",
        "something_went_wrong": "問題が発生しました",
        "storage_distribution_description": "ストレージは、ライセンスが付与されたすべての従業員間で共有され、ユーザーごとに制限されません。",
        "storage_gb": "{{storage}} GB",
        "storage_only": "ストレージ",
        "successful_conversion": "アカウントが正常に変換されました。",
        "title": "有料アカウントに変更する",
        "try_again_or_contact_support": "再試行するか、支援が必要な場合は、サポートにお問い合わせください。",
        "upgrade_failed": "アップグレードに失敗しました",
        "vdr_plan": {
          "name": "Virtual Data Room",
          "vdr_account_description": "機密データに高度なセキュリティ、共有および管理機能を提供。",
          "vdr_max_file_size": "10GB",
          "vdr_max_storage": "5GB"
        }
      },
      "edit_billing_information": {
        "address": {
          "billing_address": "請求先住所",
          "city": "市町村",
          "country": "国",
          "state_province": "州/県",
          "street": "住所 1",
          "zip_postal_code": "郵便番号"
        },
        "cancel": "キャンセル",
        "credit_card": {
          "card_details": "クレジット カードの詳細",
          "card_exp_date": "有効期限日",
          "card_first_name": "名",
          "card_last_name": "姓",
          "card_number": "カード番号",
          "card_type": "カード種類",
          "card_type_american_express": "American Express",
          "card_type_mastercard": "MasterCard",
          "card_type_not_supported": "クレジットカードの種類がサポートされていません。Visa、Mastercard、またはAmerican Expressを使用してください",
          "card_type_unknown": "不明",
          "card_type_visa": "Visa",
          "title": "クレジットカード情報"
        },
        "credit_card_notifications": {
          "add_successful": "クレジットカード情報が追加されました。",
          "save_failure": "クレジットカード情報を保存できませんでした",
          "update_failure": "クレジットカード情報の更新に失敗しました",
          "update_successful": "クレジットカード情報が更新されました。"
        },
        "disable_trial_conversion_message": "所属する国の有効なプランを見つけるには、弊社のチームにお問い合わせください: +1 (800)-441-3453",
        "edit": "編集",
        "get_billing_info_failed": "請求情報を取得中にエラーが発生しました。もう一度試してください。",
        "info_banner": "注: 請求情報の更新はすぐに反映されない場合があります。以下に変更内容が表示されるまで最大 24 時間かかります。",
        "left_nav_button_text": "請求情報を編集",
        "name_on_card": "カードに記載されている名前",
        "no_billing_info_banner": "現在このページを読み込むことができません。請求情報を編集するには、カスタマー サービス チームにお問い合わせください。",
        "province": "州",
        "save": "保存",
        "state": "都道府県",
        "support": {
          "chat_with_us": "担当者とチャットする",
          "contact_support": "アドレスを確認できません。サポートに連絡してください。",
          "contact_support_info": "サポートに問い合わせ"
        },
        "title": "請求情報の編集",
        "update": "更新",
        "update_billing_type": {
          "info_message": "現在の請求の種類は {<invoice>} です。クレジット カード支払いに切り替えることができます。",
          "invoice": "請求書%(number)s",
          "switch_to_credit_card": "クレジット カードに切り替える",
          "title": "請求の種類を更新"
        },
        "update_notifications": {
          "contact_support": "contact support",
          "save_failed": "保存できません",
          "save_success": "変更が保存されています。",
          "something_went_wrong": "問題が発生しました",
          "try_again": "アドレスを確認できません。再試行してください。",
          "try_again_or_contact_support": "もう一度試してください。または、{<contactSupport>}してください。"
        },
        "vat_id": "VAT ID",
        "verify_address": "アドレスの検証"
      },
      "files": {
        "trial_expiration_info": {
          "day_count": "1 日",
          "days_left_in_your_trial": "ご利用中のトライアルの残りは {<dayCount>} 日です。 ",
          "upgrade_to_a_plan": "プランをアップグレードする"
        }
      },
      "lock_error": "以前のリクエストが処理中であるため、このリクエストを処理できませんでした。",
      "manage_plan": {
        "add_storage_btn": "ストレージの追加",
        "add_users_btn": "ユーザーを追加する",
        "advanced": {
          "feature_email_encryption": "メールの暗号化",
          "feature_feedback_approvals": "ドキュメントのフィードバックと承認",
          "feature_outlook_gmail_plugin": "Outlook および Gmail プラグイン",
          "feature_threat_detection": "脅威の検出アラート",
          "features_header": "すべての Standard 機能および次の機能:",
          "title": "Advanced",
          "title_0gb": "Advanced 0GB"
        },
        "cloud_storage": "クラウド ストレージ",
        "connectors": {
          "title_0gb": "コネクタ 0GB"
        },
        "current_plan": "現在のプラン",
        "get_usageplan_failed": "プラン情報を取得中にエラーが発生しました。もう一度試してください。",
        "included": "含まれる",
        "industry_advantage": {
          "feature_accounting_workflows": "設定済み会計ワークフロー",
          "feature_automated_engagements": "自動化された委任契約書",
          "feature_custom_tax_templates": "すぐに使えるカスタム税務テンプレート",
          "feature_custom_tax_user_experience": "カスタマイズされた税務ユーザー エクスペリエンス",
          "feature_integrated_client_onboarding": "統合されたクライアントのオンボーディング",
          "features_header": "すべての Premium 機能および次の機能:",
          "title": "業種における優位性"
        },
        "left_nav_button_text": "プランの管理",
        "manage_plan_title": "プランの管理",
        "message_after_update": "プランの更新中です。これには時間がかかる場合があるため、後から確認してください。",
        "modals": {
          "add_button": "追加",
          "additional_users": "追加のユーザー",
          "alert_message": "{<boldText>} を選択された場合、プランのコストの差額は日割り計算で請求させていただきます。",
          "annual_billing_note": "年払いの初回の期限は {{date}} になります。",
          "annual_billing_text": "年払いで ShareFile プランが 10% お得になります。新しいプランのコストの概要は次のとおりです。",
          "annual_billing_title": "年単位の請求",
          "cancel_button": "キャンセル",
          "credit_card_iframe_error": "There was an error loading your credit card details. Please try again later or contact support.",
          "error_alert": "プランを更新できませんでした。再試行してください。",
          "extended_storage": "{{count}}TB の拡張ストレージ",
          "new_billing_amount": "新しい請求額",
          "new_monthly_total": "月払いの新しい合計額",
          "new_plan_total": "プランの新しい合計額",
          "new_storage_total": "ストレージの新しい合計数",
          "new_user_total": "ユーザーの新しい合計数",
          "per_month": "{{price}}/月*",
          "per_year": "{{price}}/年*",
          "plus_applicable_taxes": "* 適用される税金が加算されます",
          "price_per_TB": "{{price}}/3TB*",
          "price_per_user": "{{price}}/ユーザー*",
          "storage_add": "ストレージの追加",
          "storage_data_breakdown_message": "現在、 {<usedValue>}TB/{<total>}TB のストレージを使用しています。",
          "storage_modal_title": "ストレージの追加",
          "storage_need_additional": "ストレージの追加が必要ですか?",
          "success_alert": "更新されたプラン",
          "switch_to_annual_button": "年単位の請求に切り替える",
          "upgrade_button": "アップグレード",
          "upgrade_plan_modal_title": "プランのアップグレード",
          "users_add": "ユーザーを追加する",
          "users_count": "{{count}} ユーザー",
          "users_data_breakdown_message": "現在、 {<usedValue>} ユーザー/{<total>} ユーザーを使用しています。",
          "users_modal_title": "ユーザーを追加する",
          "users_need_additional": "ユーザーの追加が必要ですか?",
          "value_gb": "{{value}}GB",
          "value_tb": "{{value}}TB"
        },
        "most_popular": "よく使われている",
        "need_help": "Need more help? Contact support via https://support.sharefile.com/s/",
        "next_billing_date_pending": ": 次回の請求日",
        "one_gb_per_users": "ユーザーあたり 1GB",
        "one_tb_per_users": "ユーザーあたり 1TB",
        "per_month": "月ごと",
        "per_year": "年ごと",
        "plus_taxes": "* 適用される税金が加算されます",
        "premium": {
          "feature_all_esign": "必要なすべての電子署名 (KBA を含む)",
          "feature_document_request": "デジタル ドキュメントのリクエスト一覧",
          "feature_hippa_finra_support": "HIPAA、SEC、および FINRA 準拠のサポート",
          "feature_rapid_onboarding": "迅速なクライアントのオンボードとカスタム ブランドのポータル",
          "features_header": "すべての Advanced 機能および次の機能:",
          "title": "Premium"
        },
        "save_with_annual_btn": "年単位の請求で 10% 割引",
        "sharefile_for_accounting": "会計用 ShareFile",
        "standard": {
          "feature_all_storage": "必要なすべてのストレージ",
          "feature_file_encryption": "ファイルの暗号化",
          "feature_sync_edit_share": "同期、編集、共有",
          "feature_unlimited_client": "無制限のクライアント ユーザー",
          "features_header": "次の機能が含まれる:",
          "title": "Standard",
          "title_0gb": "Standard 0GB"
        },
        "storage": "ストレージ",
        "upgrade_btn": "アップグレード",
        "upgrade_plan": "プランのアップグレード",
        "users": "ユーザー",
        "users_count": "1 ユーザー",
        "vdr": {
          "feature_click_trails": "クリック証跡",
          "feature_folder_qa": "質問と回答 - フォルダー",
          "feature_visibility_analytics": "可視性、分析機能など",
          "features_header": "次を含む特殊な保護機能:",
          "title": "Virtual Data Room"
        }
      },
      "receipts": {
        "actions": {
          "download": "ダウンロード",
          "pay": "支払う",
          "title": "操作"
        },
        "cancel": "キャンセル",
        "credit_card_notifications": {
          "payment_approved": "支払いが承認されました。ありがとうございました。",
          "payment_failure": "クレジットカードでの支払いを完了できませんでした。サポートに連絡してください。"
        },
        "date": "追加した日",
        "left_nav_button_text": "受領書および請求書の通知",
        "open_payment": "アカウントに未払いの支払いがあります。",
        "page_infotip": "ShareFile請求受領書を表示または印刷するには、以下で該当する日付をクリックします。",
        "page_title": "受領書および請求書の通知",
        "pay_now": "支払う",
        "status": "状態",
        "type": "種類"
      }
    }
  },
  {
    "key": "nl",
    "content": {
      "cancel_account": {
        "cancel_account": "Account annuleren",
        "cancel_trial": "Proefabonnement annuleren",
        "contact_information": {
          "call_international": "Internationaal: +1 919.745.6111",
          "call_toll_free": "Bel gratis: 1.800.441.3453",
          "call_uk": "VK: + 44 (0800) 680.0621",
          "here_for_you": "Wij zijn er om u te helpen.",
          "support": "Ondersteuning",
          "visit": "Bezoek"
        },
        "contact_us": "Neem contact op",
        "continue_cancellation": "Doorgaan met annuleren",
        "did_you_know": "Wist u dat?",
        "did_you_know_desc": "Wanneer u uw ShareFile-account opzegt, zult u een hoop fantastische functies missen, waaronder:",
        "download_now": "Nu downloaden",
        "error_banner": "Kan niet annuleren. Probeer het opnieuw of neem contact op met ons ondersteuningsteam",
        "features": {
          "customer_access": "Onbeperkte toegang voor uw klanten",
          "customer_access_desc": "Biedt uw klanten gratis toegang tot bestanden en bepaal welke bestanden ze kunnen zien.",
          "customer_support": "Klantenondersteuning",
          "customer_support_desc": "via e-mail of telefoon; wij zijn hier om u te helpen!",
          "esignature": "Digitale handtekening",
          "esignature_desc": "Verzend bestanden voor ondertekening rechtstreeks vanaf uw ShareFile-account.",
          "sharefile_mobile": "ShareFile Mobile Apps",
          "sharefile_mobile_desc": "Altijd toegang hebben tot bestanden en mappen vanaf uw smartphone of tablet.",
          "sharefile_plugin": "ShareFile plug-in voor Microsoft Outlook",
          "sharefile_plugin_desc": "Verzend grote bestanden veilig rechtstreeks uit Outlook.",
          "total_security": "Totale beveiliging",
          "total_security_desc": "Vertrouwen hebben in de beveiliging van uw gegevens."
        },
        "form": {
          "account_name": "Accountnaam",
          "back_to_admin": "Terug naar accountbeheer",
          "cancel_my_account": "Mijn account annuleren",
          "cancel_my_trial": "Mijn proefabonnement annuleren",
          "cancellation_reason": {
            "Lacks_required_compliance": "Mist vereiste naleving(en)",
            "lack_of_integrations": "Mist integraties",
            "lacks_required_feature": "Mist vereiste functie(s)",
            "no_longer_needed": "Niet meer nodig",
            "poor_account_support": "Slechte accountondersteuning (verkoop, technisch)",
            "prefer_competitive_product": "Voorkeur voor een concurrerend product",
            "security_concerns": "Zorgen over de veiligheid",
            "technical_issues": "Technische problemen",
            "too_difficult_to_use": "Te moeilijk te gebruiken",
            "too_expensive": "Te duur"
          },
          "comments_label": "Opmerkingen",
          "comments_placeholder": "Zou u ons kunnen toelichten waarom u uw account annuleert? Als u akkoord gaat, zouden wij ook graag horen wat wij kunnen doen om ShareFile voor u te verbeteren.",
          "employees_desc": "{{employeeCount}} van {{employeeMax}} gekocht",
          "employees_title": "Medewerkers",
          "forgot_your_password": "Wachtwoord vergeten?",
          "more_info": "Hebt u een moment om ons iets meer te vertellen waarom u uw account annuleert?",
          "once_you_cancel": "Als u annuleert, zal ShareFile uw bestanden na 30 dagen verwijderen.",
          "password_label": "Accountwachtwoord invoeren",
          "password_missing": "Voer accountwachtwoord in",
          "password_placeholder": "Wachtwoord",
          "reason_missing": "Selecteer een reden",
          "storage_usage_desc": "{{mbUsed}} MB van {{mbMax}} gebruikt vanaf {{dateFormatted}}",
          "storage_usage_title": "Opslaggebruik",
          "title": "Ik annuleer mijn ShareFile-account omdat:",
          "to_authorize": "Om ShareFile te machtigen uw bestanden na 30 dagen te verwijderen, voert u het wachtwoord voor uw account in om door te gaan met de annulering van uw account."
        },
        "in_progress_banner": "We zijn bezig met het bijwerken van uw account. Dit kan enige tijd duren, kom later nog eens terug!",
        "learn_more": "Meer informatie",
        "let_us_help_1": "Wij helpen u graag om meer uit uw ShareFile-account te halen.",
        "let_us_help_2": "Neem contact op met ons klantenondersteuningsteam",
        "let_us_help_3": "als u meer informatie wilt over deze en andere ShareFile-functies.",
        "modal_confirm": "OK",
        "modal_message": "Uw account is geannuleerd, maar blijft actief tot het einde van de factureringscyclus. U kunt uw account opnieuw activeren door op \"Account opnieuw activeren\" te klikken in het accountbeheermenu.",
        "modal_message_trial": "Uw proefabonnement is geannuleerd. Bedankt voor het proberen van ShareFile! U kunt uw account opnieuw activeren door op \"Account opnieuw activeren\" te klikken in het accountbeheermenu.",
        "modal_title": "Account geannuleerd",
        "modal_title_trial": "Proefabonnement geannuleerd",
        "page_title": "Account annuleren",
        "page_title_trial": "Proefabonnement annuleren",
        "password_was_incorrect": "Wachtwoord was onjuist",
        "resources_card": {
          "ask_the_community": "Vraag de community",
          "sharefile_knowledgebase": "ShareFile Knowledge Base",
          "title": "Hebt u deze bronnen al geprobeerd?",
          "training_videos": "Trainingsvideo's"
        },
        "sorry_to_go": "Het spijt ons dat u weggaat!",
        "sorry_to_go_desc": "ShareFile is ontworpen met de gebruiker in gedachten. Wij zoeken altijd manieren om onze service te verbeteren en om te voldoen aan uw behoeften voor het delen en opslaan van bestanden."
      },
      "convert_to_paid": {
        "advanced_plan": {
          "features": {
            "document_feedback": "Feedback en goedkeuringen van documenten",
            "email_encryption": "E-mailversleuteling",
            "features_include": "Functies zijn onder andere:",
            "outlook_and_gmail_plugin": "Plug-ins voor Outlook en Gmail",
            "threat_detection_alerts": "Waarschuwingen bij detectie van bedreigingen",
            "unlimited_client_users": "Onbeperkte klantgebruikers"
          },
          "name": "Geavanceerd"
        },
        "billing_address": {
          "city": "Plaats",
          "country": "Land",
          "state": "Provincie",
          "street": "Adres",
          "title": "Factureringsadres",
          "zip": "Postcode"
        },
        "billing_cycle_selection": {
          "annual_save": "(Bespaar 10%)",
          "choose_billing_cycle": "Kies uw factureringscyclus",
          "choose_billing_cycle_text": "De prijs is gebaseerd op het totale aantal interne gebruikers.",
          "online_only": "(Alleen online)",
          "pay_annually": "Jaarlijks betalen",
          "pay_monthly": "Maandelijks betalen"
        },
        "buttons": {
          "cancel": "Annuleren",
          "convert_now": "Nu omzetten",
          "convert_now_tooltip": "Zorg ervoor dat adres en creditcard zijn geverifieerd voordat u de omzetting uitvoert.",
          "upgrade": "Upgrade"
        },
        "convert_modal": {
          "confirm_purchase": "Aankoop bevestigen",
          "confirm_purchase_description": "Als u 'Nu omzetten' selecteert, wordt vandaag {{billingRate}} in rekening gebracht en wordt uw account omgezet naar een betaald account."
        },
        "credit_card": {
          "confirmed": "Creditcard bevestigd",
          "confirmed_description": "Uw creditcardgegevens zijn geverifieerd.",
          "declined": "Creditcard geweigerd",
          "declined_description": "We kunnen uw kaart niet verifiëren. Controleer of wat u hebt ingevoerd juist is en probeer het opnieuw.",
          "edit": "Bewerken",
          "error_message": "Verificatie van creditcard is mislukt. Controleer de creditcardinformatie en probeer het opnieuw.",
          "information": "Creditcardgegevens"
        },
        "current_licenses": "Werknemerslicenties: {{users}}",
        "current_storage": "Opslag: {{storage}} GB",
        "disable_trial_conversion_message": "Neem contact op met ons team om een abonnement te vinden dat werkt in uw land: +1 (800)-441-3453",
        "employee_accounts": "Medewerkersaccounts",
        "employee_accounts_description": "Uw abonnement omvat {{users}} medewerkersaccounts. U kunt hieronder extra accounts aan uw abonnement toevoegen.",
        "employee_accounts_description_vdr": "Uw abonnement omvat {{users}} werknemerslicenties en {{storage}} GB opslagruimte. Hieronder kunt u meer toevoegen.",
        "employee_licenses": "Werknemerslicenties",
        "employee_licenses_storage": "Werknemerslicenties en opslag",
        "error_loading_plans": "Er is een fout opgetreden bij het laden van abonnementen. Probeer het later nog eens.",
        "industry_advantage_plan": {
          "features": {
            "accounting_workflows": "Vooraf geconfigureerde boekhoudkundige werkstromen",
            "automated_engagement": "Geautomatiseerde opdrachtbevestigingen",
            "custom_tax_templates": "Kant-en-klare aangepaste belastingsjablonen",
            "custom_tax_user_experience": "Aangepaste gebruikerservaring voor belastingen",
            "features_include": "Alle Premium-functies, plus:",
            "integrated_client_onboarding": "Geïntegreerde client onboarding"
          },
          "name": "Branche voordeel",
          "sharefile_for_accounting": "ShareFile voor boekhouding"
        },
        "left_nav_button_text": "Gratis proefabonnement omzetten",
        "month": "per maand",
        "number_of_users_selection": {
          "title": "Aantal gebruikers",
          "user_limit_message": "Voor het geselecteerde abonnement worden minimaal {{min}} gebruikers en maximaal {{max}} gebruikers toegestaan."
        },
        "plan": {
          "base_employee_accounts": "{{employee}} medewerkersaccounts",
          "max_file_size": "100 GB",
          "max_storage": "1 TB/licentie",
          "max_storage_vdr": "1GB/licentie",
          "storage": "{{storage}} opslag"
        },
        "plan_chart": {
          "need_help": "Hulp nodig? We zijn hier voor u. Neem contact op met Support via sales@sharefile.com of bel 1 (800)-441-3453 voor meer abonnementsopties.",
          "upgrade_plan": "Abonnement upgraden",
          "view_detailed_comparison": "Gedetailleerde vergelijking weergeven"
        },
        "plan_summary": {
          "annually": "Jaarlijks",
          "billing": "Facturering",
          "monthly": "Maandelijks",
          "plan": "{{name}}-abonnement",
          "plus_applicable_taxes": "Plus toepasselijke belastingen",
          "storage_max_file_size": "Opslag/Max. bestandsgrootte:",
          "users": "Gebruikers",
          "your_total": "Uw totaal"
        },
        "premium_plan": {
          "cloud_storage": "Cloudopslag",
          "features": {
            "all_the_esignatures": "Alle e-handtekeningen die u nodig heeft, inclusief KBA",
            "compliance_support": "Ondersteuning voor HIPAA-, SEC- en FINRA-naleving",
            "digital_document_request_lists": "Lijsten met digitale documentaanvragen",
            "features_include": "Alle functies van Advanced, plus:",
            "rapid_client_onboarding": "Snelle onboarding van klanten en portals in eigen huisstijl"
          },
          "most_popular": "Populairst",
          "name": "Premium"
        },
        "purchase_confirmed": "Aankoop bevestigd",
        "something_went_wrong": "Er is iets fout gegaan",
        "storage_distribution_description": "De opslag wordt gedeeld door alle gelicentieerde werknemers en is niet beperkt per gebruiker.",
        "storage_gb": "{{storage}} GB",
        "storage_only": "Opslag",
        "successful_conversion": "U hebt uw account met succes geconverteerd.",
        "title": "Omzetten naar betaald account",
        "try_again_or_contact_support": "Probeer het opnieuw of neem contact op met Support als u hulp nodig hebt.",
        "upgrade_failed": "Upgrade mislukt",
        "vdr_plan": {
          "name": "Virtual Data Room",
          "vdr_account_description": "Geavanceerde beveiliging, delen en controle van gevoelige gegevens.",
          "vdr_max_file_size": "10 GB",
          "vdr_max_storage": "5 GB"
        }
      },
      "edit_billing_information": {
        "address": {
          "billing_address": "Factureringsadres",
          "city": "Plaats",
          "country": "Land",
          "state_province": "Provincie",
          "street": "Adres",
          "zip_postal_code": "Postcode"
        },
        "cancel": "Annuleren",
        "credit_card": {
          "card_details": "Creditcardgegevens",
          "card_exp_date": "Vervaldatum",
          "card_first_name": "Voornaam",
          "card_last_name": "Achternaam",
          "card_number": "Kaartnummer",
          "card_type": "Kaarttype",
          "card_type_american_express": "American Express",
          "card_type_mastercard": "MasterCard",
          "card_type_not_supported": "Type creditcard wordt niet ondersteund. Gebruik Visa, Mastercard of American Express",
          "card_type_unknown": "Onbekend",
          "card_type_visa": "Visa",
          "title": "Creditcardgegevens"
        },
        "credit_card_notifications": {
          "add_successful": "Creditcardgegevens toegevoegd!",
          "save_failure": "Kan creditcardgegevens niet opslaan",
          "update_failure": "Update van creditcardgegevens mislukt",
          "update_successful": "Update van creditcardgegevens geslaagd."
        },
        "disable_trial_conversion_message": "Neem contact op met ons team om een abonnement te vinden dat werkt in uw land: +1 (800)-441-3453",
        "edit": "Bewerken",
        "get_billing_info_failed": "Er is een fout opgetreden bij het ophalen van uw factureringsinformatie. Probeer het later nog eens.",
        "info_banner": "Let op: updates in de factureringsinformatie worden mogelijk niet onmiddellijk weergegeven. Het kan 24 uur duren voordat de wijzigingen hieronder worden weergegeven.",
        "left_nav_button_text": "Factuurgegevens bewerken",
        "name_on_card": "Naam op kaart",
        "no_billing_info_banner": "We kunnen deze pagina momenteel niet laden. Neem contact op met ons klantenserviceteam om uw factureringsgegevens te bewerken.",
        "province": "Provincie",
        "save": "Opslaan",
        "state": "Provincie",
        "support": {
          "chat_with_us": "Chat met ons",
          "contact_support": "Kan het adres niet verifiëren; neem contact op met Support.",
          "contact_support_info": "Neem contact op met Support"
        },
        "title": "Factureringsinformatie bewerken",
        "update": "Bijwerken",
        "update_billing_type": {
          "info_message": "Het huidige factuurtype is {<invoice>}. U kunt overschakelen op creditcardbetalingen.",
          "invoice": "Factuur %(number)s",
          "switch_to_credit_card": "Overschakelen op creditcard",
          "title": "Factureringstype bijwerken"
        },
        "update_notifications": {
          "contact_support": "contact support",
          "save_failed": "Kan niet opslaan",
          "save_success": "Uw wijzigingen zijn opgeslagen.",
          "something_went_wrong": "Er is iets fout gegaan",
          "try_again": "Kan het adres niet verifiëren; probeer het opnieuw.",
          "try_again_or_contact_support": "Probeer het opnieuw of {<contactSupport>} als u meer hulp nodig hebt."
        },
        "vat_id": "BTW-nummer",
        "verify_address": "Adres verifiëren"
      },
      "files": {
        "trial_expiration_info": {
          "day_count": "één dag",
          "day_count_plural": "{{count}} dagen",
          "days_left_in_your_trial": "U hebt nog {<dayCount>} over van uw proefperiode. ",
          "upgrade_to_a_plan": "Upgraden naar een abonnement"
        }
      },
      "lock_error": "We konden deze aanvraag niet verwerken terwijl een eerdere aanvraag wordt verwerkt.",
      "manage_plan": {
        "add_storage_btn": "Opslag toevoegen",
        "add_users_btn": "Gebruikers toevoegen",
        "advanced": {
          "feature_email_encryption": "E-mailversleuteling",
          "feature_feedback_approvals": "Feedback en goedkeuringen van documenten",
          "feature_outlook_gmail_plugin": "Plug-ins voor Outlook en Gmail",
          "feature_threat_detection": "Waarschuwingen bij detectie van bedreigingen",
          "features_header": "Alle standaardfuncties, plus:",
          "title": "Geavanceerd",
          "title_0gb": "Advanced 0 GB"
        },
        "cloud_storage": "Cloudopslag",
        "connectors": {
          "title_0gb": "Connectors 0 GB"
        },
        "current_plan": "Huidige abonnement",
        "get_usageplan_failed": "Er is een fout opgetreden bij het ophalen van uw abonnementsinformatie. Probeer het later nog eens.",
        "included": "Inbegrepen",
        "industry_advantage": {
          "feature_accounting_workflows": "Vooraf geconfigureerde boekhoudkundige werkstromen",
          "feature_automated_engagements": "Geautomatiseerde opdrachtbevestigingen",
          "feature_custom_tax_templates": "Kant-en-klare aangepaste belastingsjablonen",
          "feature_custom_tax_user_experience": "Aangepaste gebruikerservaring voor belastingen",
          "feature_integrated_client_onboarding": "Geïntegreerde client onboarding",
          "features_header": "Alle Premium-functies, plus:",
          "title": "Branche voordeel"
        },
        "left_nav_button_text": "Abonnement beheren",
        "manage_plan_title": "Abonnement beheren",
        "message_after_update": "We zijn bezig met het bijwerken van uw abonnement. Dit kan enige tijd duren, kom later nog eens terug!",
        "modals": {
          "add_button": "Toevoegen",
          "additional_users": "Extra gebruikers",
          "alert_message": "Als u {<boldText>} kiest, brengen we u een bedrag naar rato in rekening voor het verschil in abonnementskosten.",
          "annual_billing_note": "Uw eerste jaarlijkse betaling is verschuldigd op {{date}}.",
          "annual_billing_text": "U kunt 10% besparen op uw ShareFile-abonnement door jaarlijks te betalen. Hier is een overzicht van de kosten van uw nieuwe abonnement.",
          "annual_billing_title": "Jaarlijkse facturering",
          "cancel_button": "Annuleren",
          "credit_card_iframe_error": "There was an error loading your credit card details. Please try again later or contact support.",
          "error_alert": "We konden uw abonnement niet bijwerken. Probeer het opnieuw.",
          "extended_storage": "{{count}} TB extra opslag",
          "new_billing_amount": "Nieuw factuurbedrag",
          "new_monthly_total": "Nieuw maandtotaal",
          "new_plan_total": "Totaalbedrag nieuw abonnement",
          "new_storage_total": "Totaal nieuwe opslagruimte",
          "new_user_total": "Totaal aantal nieuwe gebruikers",
          "per_month": "{{price}} per maand*",
          "per_year": "{{price}} per jaar*",
          "plus_applicable_taxes": "*plus toepasselijke belastingen",
          "price_per_TB": "{{price}} per 3 TB",
          "price_per_user": "{{price}} per gebruiker",
          "storage_add": "Opslag toevoegen",
          "storage_data_breakdown_message": "U gebruikt momenteel {<usedValue>} TB van uw {<total>} TB opslagruimte.",
          "storage_modal_title": "Opslag toevoegen",
          "storage_need_additional": "Extra opslagruimte nodig?",
          "success_alert": "Abonnement bijgewerkt.",
          "switch_to_annual_button": "Overschakelen naar jaarlijkse facturering",
          "upgrade_button": "Upgrade",
          "upgrade_plan_modal_title": "Abonnement upgraden",
          "users_add": "Gebruikers toevoegen",
          "users_count": "{{count}} gebruikers",
          "users_data_breakdown_message": "U gebruikt momenteel {<usedValue>} van uw {<total>} gebruikerslicenties.",
          "users_modal_title": "Gebruikers toevoegen",
          "users_need_additional": "Extra gebruikers nodig?",
          "value_gb": "{{value}} GB",
          "value_tb": "{{value}} TB"
        },
        "most_popular": "Populairst",
        "need_help": "Need more help? Contact support via https://support.sharefile.com/s/",
        "next_billing_date_pending": "op uw volgende factuurdatum",
        "one_gb_per_users": "1 GB per gebruiker",
        "one_tb_per_users": "1 TB per gebruiker",
        "per_month": "per maand",
        "per_year": "per jaar",
        "plus_taxes": "*plus toepasselijke belastingen",
        "premium": {
          "feature_all_esign": "Alle e-handtekeningen die u nodig heeft, inclusief KBA",
          "feature_document_request": "Lijsten met digitale documentaanvragen",
          "feature_hippa_finra_support": "Ondersteuning voor HIPAA-, SEC- en FINRA-naleving",
          "feature_rapid_onboarding": "Snelle onboarding van klanten en portals in eigen huisstijl",
          "features_header": "Alle functies van Advanced, plus:",
          "title": "Premium"
        },
        "save_with_annual_btn": "Bespaar 10% met jaarlijkse facturering",
        "sharefile_for_accounting": "ShareFile voor boekhouding",
        "standard": {
          "feature_all_storage": "Alle opslagruimte die u nodig heeft",
          "feature_file_encryption": "Bestandsversleuteling",
          "feature_sync_edit_share": "Synchroniseren, bewerken, delen",
          "feature_unlimited_client": "Onbeperkte klantgebruikers",
          "features_header": "Functies zijn onder andere:",
          "title": "Standaard",
          "title_0gb": "Standaard 0 GB"
        },
        "storage": "Opslag",
        "upgrade_btn": "Upgrade",
        "upgrade_plan": "Abonnement upgraden",
        "users": "Gebruikers",
        "users_count": "1 gebruiker",
        "users_count_plural": "{{count}} gebruikers",
        "vdr": {
          "feature_click_trails": "Kliktrails",
          "feature_folder_qa": "Vragen/antwoorden over mappen",
          "feature_visibility_analytics": "Zichtbaarheid, analyse en meer",
          "features_header": "Gespecialiseerde beveiliging, waaronder:",
          "title": "Virtual Data Room"
        }
      },
      "receipts": {
        "actions": {
          "download": "downloaden",
          "pay": "Betalen",
          "title": "Acties"
        },
        "cancel": "Annuleren",
        "credit_card_notifications": {
          "payment_approved": "Betaling goedgekeurd. Bedankt!",
          "payment_failure": "Creditcardbetaling is mislukt. Neem contact op met Support."
        },
        "date": "Datum toegevoegd",
        "left_nav_button_text": "Ontvangstbewijzen en factureringsmeldingen",
        "open_payment": "Uw account heeft een openstaande betaling.",
        "page_infotip": "Klik hieronder op de juiste datum om ShareFile-factuurontvangstbewijzen weer te geven en/of af te drukken.",
        "page_title": "Ontvangstbewijzen & factureringsmeldingen",
        "pay_now": "betalen",
        "status": "Status",
        "type": "Type"
      }
    }
  },
  {
    "key": "pt-BR",
    "content": {
      "cancel_account": {
        "cancel_account": "Cancelar conta",
        "cancel_trial": "Cancelar avaliação",
        "contact_information": {
          "call_international": "Internacional: +1 919.745.6111",
          "call_toll_free": "Ligação gratuita: 1.800.441.3453",
          "call_uk": "Reino Unido: +44 (0800) 680.0621",
          "here_for_you": "Estamos à sua disposição.",
          "support": "Suporte",
          "visit": "Visite"
        },
        "contact_us": "Entre em contato conosco",
        "continue_cancellation": "Continuar cancelamento",
        "did_you_know": "Você sabia?",
        "did_you_know_desc": "Cancelando a sua conta do ShareFile, você perde inúmeros excelentes recursos, entre eles:",
        "download_now": "Baixar agora",
        "error_banner": "Falha ao cancelar. Tente novamente ou entre em contato com nossa equipe de suporte",
        "features": {
          "customer_access": "Acesso ilimitado para seus clientes",
          "customer_access_desc": "Permita que seus clientes tenham acesso a arquivos gratuitamente e determine quais arquivos eles podem ver.",
          "customer_support": "Suporte ao cliente",
          "customer_support_desc": " por email ou por telefone: estamos aqui para ajudar!",
          "esignature": "E-Signature",
          "esignature_desc": "Envie arquivos para assinar diretamente da sua conta de ShareFile.",
          "sharefile_mobile": "Aplicativos móveis do ShareFile",
          "sharefile_mobile_desc": "Tenha acesso a arquivos e pastas a qualquer momento pelo seu smartphone ou tablet.",
          "sharefile_plugin": "ShareFile Plugin para Microsoft Outlook",
          "sharefile_plugin_desc": "Envie arquivos grandes de modo seguro diretamente do Outlook.",
          "total_security": "Segurança total",
          "total_security_desc": "Tenha confiança na proteção dos seus dados."
        },
        "form": {
          "account_name": "Nome da conta",
          "back_to_admin": "Voltar para a administração de contas",
          "cancel_my_account": "Cancelar minha conta",
          "cancel_my_trial": "Cancelar minha avaliação",
          "cancellation_reason": {
            "Lacks_required_compliance": "Não tem as conformidades necessárias",
            "lack_of_integrations": "Falta de integrações",
            "lacks_required_feature": "Não tem as funções necessárias",
            "no_longer_needed": "Não é mais necessário",
            "poor_account_support": "Suporte de conta ruim (vendas, técnico)",
            "prefer_competitive_product": "Prefiro um produto da concorrência",
            "security_concerns": "Preocupações com a segurança",
            "technical_issues": "Problemas técnicos",
            "too_difficult_to_use": "Muito difícil de usar",
            "too_expensive": "Caro demais"
          },
          "comments_label": "Comentários",
          "comments_placeholder": "Você poderia nos dizer o porquê de estar cancelando sua conta? Se desejar, também gostaríamos muito de saber mais sobre o que poderíamos fazer para melhorar o ShareFile para você.",
          "employees_desc": "{{employeeCount}} de {{employeeMax}} comprou(aram)",
          "employees_title": "Funcionários",
          "forgot_your_password": "Esqueceu sua senha?",
          "more_info": "Reserve um minuto para nos dizer um pouco mais sobre por que você está cancelando sua conta.",
          "once_you_cancel": "Depois de você cancelar, o ShareFile excluirá seus arquivos em 30 dias.",
          "password_label": "Inserir senha da conta",
          "password_missing": "Insira a senha da conta",
          "password_placeholder": "Senha",
          "reason_missing": "Selecione um motivo",
          "storage_usage_desc": "{{mbUsed}} MB de {{mbMax}} usado(s) desde {{dateFormatted}}",
          "storage_usage_title": "Uso do armazenamento",
          "title": "Estou cancelando minha conta do ShareFile porque:",
          "to_authorize": "Para autorizar o ShareFile a excluir seus arquivos depois de 30 dias, digite a senha da sua conta para continuar com o cancelamento da sua conta."
        },
        "in_progress_banner": "Estamos trabalhando para atualizar sua conta. Isso pode levar algum tempo. Volte em breve!",
        "learn_more": "Saber mais",
        "let_us_help_1": "Podemos ajudá-lo a obter o máximo da sua conta do ShareFile.",
        "let_us_help_2": "Entre em contato com nossa equipe de atendimento ao cliente",
        "let_us_help_3": "para saber mais sobre estes e outros recursos do ShareFile.",
        "modal_confirm": "OK",
        "modal_message": "Sua conta foi cancelada, mas permanecerá ativa até o fim do ciclo de faturação. Você pode reativar sua conta clicando em \"Reativar conta\" no menu de administrador.",
        "modal_message_trial": "Sua avaliação foi cancelada.  Obrigado por experimentar o ShareFile! Você pode reativar sua conta clicando em \"Reativar conta\" no menu de administração de conta.",
        "modal_title": "Conta cancelada",
        "modal_title_trial": "Avaliação cancelada",
        "page_title": "Cancelar conta",
        "page_title_trial": "Cancelar avaliação",
        "password_was_incorrect": "A senha estava incorreta",
        "resources_card": {
          "ask_the_community": "Perguntar à comunidade",
          "sharefile_knowledgebase": "Base de conhecimento do ShareFile",
          "title": "Você experimentou estes recursos?",
          "training_videos": "Vídeos de treinamento"
        },
        "sorry_to_go": "Sentimos muito em ver você ir embora!",
        "sorry_to_go_desc": "O ShareFile foi criado para você, e estamos sempre tentando encontrar meios de melhorar nosso serviço e atender às suas necessidades de compartilhamento de arquivos e armazenamento."
      },
      "convert_to_paid": {
        "advanced_plan": {
          "features": {
            "document_feedback": "Feedback e aprovações de documentos",
            "email_encryption": "Criptografia de email",
            "features_include": "Os recursos incluem:",
            "outlook_and_gmail_plugin": "Plug-ins do Outlook e do Gmail",
            "threat_detection_alerts": "Alertas de detecção de ameaças",
            "unlimited_client_users": "Usuários clientes ilimitados"
          },
          "name": "Avançado"
        },
        "billing_address": {
          "city": "Cidade",
          "country": "País",
          "state": "Estado",
          "street": "Endereço",
          "title": "Endereço de cobrança",
          "zip": "CEP/Código postal"
        },
        "billing_cycle_selection": {
          "annual_save": "(Economize 10%)",
          "choose_billing_cycle": "Escolha seu ciclo de faturação",
          "choose_billing_cycle_text": "O preço é baseado no número total de usuários funcionários.",
          "online_only": "(Somente online)",
          "pay_annually": "Pagar anualmente",
          "pay_monthly": "Pagar mensalmente"
        },
        "buttons": {
          "cancel": "Cancelar",
          "convert_now": "Converter agora",
          "convert_now_tooltip": "Certifique-se de que o endereço e o cartão de crédito foram verificados antes da conversão.",
          "upgrade": "Atualização"
        },
        "convert_modal": {
          "confirm_purchase": "Confirmar a compra",
          "confirm_purchase_description": "Selecionar \"Converter agora\" cobrará hoje {{billingRate}} de você e sua conta será convertida em uma conta paga."
        },
        "credit_card": {
          "confirmed": "Cartão de crédito confirmado",
          "confirmed_description": "As informações do seu cartão de crédito foram verificadas.",
          "declined": "Cartão de crédito recusado",
          "declined_description": "Não foi possível verificar o seu cartão. Verifique se o que você inseriu está correto e tente novamente.",
          "edit": "Editar",
          "error_message": "Falha na verificação do cartão de crédito. Verifique as informações do cartão de crédito e tente novamente.",
          "information": "Informações do cartão de crédito"
        },
        "current_licenses": "Licenças de funcionários: {{users}}",
        "current_storage": "Armazenamento: {{storage}} GB",
        "disable_trial_conversion_message": "Entre em contato com nossa equipe para encontrar um plano que funcione em seu país: +1 (800)-441-3453",
        "employee_accounts": "Contas de funcionários",
        "employee_accounts_description": "Seu plano inclui {{users}} contas de funcionários, você pode adicionar outras contas ao seu plano abaixo.",
        "employee_accounts_description_vdr": "Seu plano inclui {{users}} licenças de funcionário e {{storage}} GB de armazenamento. Abaixo, você pode adicionar mais.",
        "employee_licenses": "Licenças de Funcionário",
        "employee_licenses_storage": "Licenças e armazenamento de funcionários",
        "error_loading_plans": "Ocorreu um erro ao tentar carregar os planos. Tente novamente mais tarde.",
        "industry_advantage_plan": {
          "features": {
            "accounting_workflows": "Fluxos de trabalho contábeis pré-configurados",
            "automated_engagement": "Cartas de compromisso automatizadas",
            "custom_tax_templates": "Modelos fiscais personalizados prontos para usar",
            "custom_tax_user_experience": "Experiência fiscal do usuário personalizada",
            "features_include": "Todos os recursos Premium, mais:",
            "integrated_client_onboarding": "Integração de clientes"
          },
          "name": "Vantagem do setor",
          "sharefile_for_accounting": "ShareFile para contabilidade"
        },
        "left_nav_button_text": "Converter avaliação gratuita",
        "month": "por mês",
        "number_of_users_selection": {
          "title": "Número de usuários",
          "user_limit_message": "O plano selecionado permite um mínimo de {{min}} usuários e um máximo de {{max}} usuários."
        },
        "plan": {
          "base_employee_accounts": "{{employee}} contas de funcionários",
          "max_file_size": "100GB",
          "max_storage": "1 TB/licença",
          "max_storage_vdr": "1GB/licença",
          "storage": "Armazenamento de {{storage}}"
        },
        "plan_chart": {
          "need_help": "Preciso de ajuda? Estamos aqui para ajudá-lo. Entre em contato com o suporte em sales@sharefile.com ou ligue para +1-800-441-3453 para obter mais opções de planos.",
          "upgrade_plan": "Atualizar plano",
          "view_detailed_comparison": "Ver comparação detalhada"
        },
        "plan_summary": {
          "annually": "Anualmente",
          "billing": "Cobrança",
          "monthly": "Mensal",
          "plan": "{{name}} plano",
          "plus_applicable_taxes": "Mais impostos aplicáveis",
          "storage_max_file_size": "Armazenamento/tamanho máximo de arquivo:",
          "users": "Usuários",
          "your_total": "Seu total"
        },
        "premium_plan": {
          "cloud_storage": "Armazenamento na nuvem",
          "features": {
            "all_the_esignatures": "Todas as assinaturas eletrônicas de que você precisa com o KBA incluído",
            "compliance_support": "Suporte para conformidade com HIPAA, SEC e FINRA",
            "digital_document_request_lists": "Listas de solicitação de documentos digitais",
            "features_include": "Todos os recursos do Advanced, mais:",
            "rapid_client_onboarding": "Integração rápida de clientes e portais com marcas personalizadas"
          },
          "most_popular": "Mais procurado",
          "name": "Premium"
        },
        "purchase_confirmed": "Compra confirmada",
        "something_went_wrong": "Algo saiu errado",
        "storage_distribution_description": "O armazenamento é compartilhado entre todos os funcionários licenciados e não é limitado por usuário.",
        "storage_gb": "{{storage}} GB",
        "storage_only": "Armazenamento",
        "successful_conversion": "Você converteu sua conta com sucesso.",
        "title": "Converter em conta paga",
        "try_again_or_contact_support": "Tente novamente ou entre em contato com o suporte para obter mais assistência.",
        "upgrade_failed": "Falha na atualização",
        "vdr_plan": {
          "name": "Data Room virtual",
          "vdr_account_description": "Segurança avançada, compartilhamento e controle de dados confidenciais.",
          "vdr_max_file_size": "10 GB",
          "vdr_max_storage": "5GB"
        }
      },
      "edit_billing_information": {
        "address": {
          "billing_address": "Endereço de cobrança",
          "city": "Cidade",
          "country": "País",
          "state_province": "Estado",
          "street": "Endereço postal",
          "zip_postal_code": "Código Postal"
        },
        "cancel": "Cancelar",
        "credit_card": {
          "card_details": "Dados do cartão de crédito",
          "card_exp_date": "Data de expiração",
          "card_first_name": "Nome",
          "card_last_name": "Sobrenome",
          "card_number": "Número do cartão",
          "card_type": "Tipo de cartão",
          "card_type_american_express": "American Express",
          "card_type_mastercard": "MasterCard",
          "card_type_not_supported": "Tipo de cartão de crédito não aceito. Use Visa, Mastercard ou American Express",
          "card_type_unknown": "Desconhecido",
          "card_type_visa": "Visa",
          "title": "Informações de cartão de crédito"
        },
        "credit_card_notifications": {
          "add_successful": "Informações de cartão de crédito adicionadas!",
          "save_failure": "Falha ao salvar as informações do cartão de crédito",
          "update_failure": "Falha ao atualizar as informações do cartão de crédito",
          "update_successful": "Informações do cartão de crédito atualizadas!"
        },
        "disable_trial_conversion_message": "Entre em contato com nossa equipe para encontrar um plano que funcione em seu país: +1 (800)-441-3453",
        "edit": "Editar",
        "get_billing_info_failed": "Ocorreu um erro ao obter as informações para cobrança. Tente novamente mais tarde.",
        "info_banner": "Observação: as atualizações para informações de cobrança podem não ser refletidas imediatamente. Aguarde até 24 horas para que as alterações apareçam abaixo.",
        "left_nav_button_text": "Editar informações de cobrança",
        "name_on_card": "Nome no cartão",
        "no_billing_info_banner": "Não conseguimos carregar esta página no momento. Entre em contato com nossa equipe de atendimento ao cliente para editar suas informações para cobrança.",
        "province": "Província",
        "save": "Salvar",
        "state": "Estado",
        "support": {
          "chat_with_us": "Converse conosco",
          "contact_support": "Não foi possível verificar o endereço, entre em contato com o suporte.",
          "contact_support_info": "entre em contato com o suporte"
        },
        "title": "Editar informações de cobrança",
        "update": "Atualizar",
        "update_billing_type": {
          "info_message": "O tipo de cobrança atual é {<invoice>}. Você pode mudar para pagamentos com cartão de crédito.",
          "invoice": "Fatura %(number)s",
          "switch_to_credit_card": "Mudar para cartão de crédito",
          "title": "Atualizar tipo de cobrança"
        },
        "update_notifications": {
          "contact_support": "contact support",
          "save_failed": "Impossível salvar",
          "save_success": "Suas alterações foram salvas.",
          "something_went_wrong": "Algo saiu errado",
          "try_again": "Não foi possível verificar o endereço, tente novamente.",
          "try_again_or_contact_support": "Tente novamente ou {<contactSupport>} para obter mais assistência."
        },
        "vat_id": "ID de IVA",
        "verify_address": "Verificar o endereço"
      },
      "files": {
        "trial_expiration_info": {
          "day_count": "um dia",
          "day_count_plural": "{{count}} dias",
          "days_left_in_your_trial": "Você tem {<dayCount>} restantes na sua avaliação. ",
          "upgrade_to_a_plan": "Fazer upgrade para um plano"
        }
      },
      "lock_error": "Não foi possível processar esta solicitação, pois uma solicitação anterior estava sendo processada.",
      "manage_plan": {
        "add_storage_btn": "Adicionar armazenamento",
        "add_users_btn": "Adicionar usuários",
        "advanced": {
          "feature_email_encryption": "Criptografia de email",
          "feature_feedback_approvals": "Feedback e aprovações de documentos",
          "feature_outlook_gmail_plugin": "Plug-ins do Outlook e do Gmail",
          "feature_threat_detection": "Alertas de detecção de ameaças",
          "features_header": "Todos os recursos do Standard, mais:",
          "title": "Avançado",
          "title_0gb": "Advanced 0GB"
        },
        "cloud_storage": "Armazenamento na nuvem",
        "connectors": {
          "title_0gb": "Conectores 0GB"
        },
        "current_plan": "Plano atual",
        "get_usageplan_failed": "Ocorreu um erro ao obter as informações do seu plano. Tente novamente mais tarde.",
        "included": "Incluído",
        "industry_advantage": {
          "feature_accounting_workflows": "Fluxos de trabalho contábeis pré-configurados",
          "feature_automated_engagements": "Cartas de compromisso automatizadas",
          "feature_custom_tax_templates": "Modelos fiscais personalizados prontos para usar",
          "feature_custom_tax_user_experience": "Experiência fiscal do usuário personalizada",
          "feature_integrated_client_onboarding": "Integração de clientes",
          "features_header": "Todos os recursos Premium, mais:",
          "title": "Vantagem do setor"
        },
        "left_nav_button_text": "Gerenciar plano",
        "manage_plan_title": "Gerenciar plano",
        "message_after_update": "Estamos trabalhando para atualizar seu plano. Isso pode levar algum tempo, volte mais tarde.",
        "modals": {
          "add_button": "Adicionar",
          "additional_users": "Usuários adicionais",
          "alert_message": "Se você escolher {<boldText>}, cobraremos uma quantia proporcional pela diferença no custo do plano.",
          "annual_billing_note": "Seu primeiro pagamento anual vencerá em {{date}}.",
          "annual_billing_text": "Você pode economizar 10% em seu plano ShareFile pagando anualmente. Aqui está um resumo de quais seriam os custos do seu novo plano.",
          "annual_billing_title": "Faturamento anual",
          "cancel_button": "Cancelar",
          "credit_card_iframe_error": "There was an error loading your credit card details. Please try again later or contact support.",
          "error_alert": "Não foi possível atualizar seu plano. Tente novamente.",
          "extended_storage": "{{count}} TB de armazenamento estendido",
          "new_billing_amount": "Novo valor de cobrança",
          "new_monthly_total": "Novo total mensal",
          "new_plan_total": "Total do novo plano",
          "new_storage_total": "Novo total de armazenamento",
          "new_user_total": "Total de novos usuários",
          "per_month": "{{price}} por mês*",
          "per_year": "{{price}} por ano*",
          "plus_applicable_taxes": "*mais os impostos incidentes",
          "price_per_TB": "{{price}} por 3 TB",
          "price_per_user": "{{price}} por usuário",
          "storage_add": "Adicionar armazenamento",
          "storage_data_breakdown_message": "Você está usando {<usedValue>} TB do seu total de {<total>} TB de armazenamento.",
          "storage_modal_title": "Adicionar armazenamento",
          "storage_need_additional": "Precisa de mais armazenamento?",
          "success_alert": "Plano atualizado.",
          "switch_to_annual_button": "Mude para o faturamento anual",
          "upgrade_button": "Atualização",
          "upgrade_plan_modal_title": "Atualizar o plano",
          "users_add": "Adicionar usuários",
          "users_count": "{{count}} usuários",
          "users_data_breakdown_message": "Você está usando {<usedValue>} do seu total de {<total>} licenças de usuário.",
          "users_modal_title": "Adicionar usuários",
          "users_need_additional": "Precisa de mais usuários?",
          "value_gb": "{{value}} GB",
          "value_tb": "{{value}} TB"
        },
        "most_popular": "Mais procurado",
        "need_help": "Need more help? Contact support via https://support.sharefile.com/s/",
        "next_billing_date_pending": "na sua próxima data de cobrança",
        "one_gb_per_users": "1 GB por usuário",
        "one_tb_per_users": "1 TB por usuário",
        "per_month": "por mês",
        "per_year": "por ano",
        "plus_taxes": "*mais os impostos incidentes",
        "premium": {
          "feature_all_esign": "Todas as assinaturas eletrônicas de que você precisa com o KBA incluído",
          "feature_document_request": "Listas de solicitação de documentos digitais",
          "feature_hippa_finra_support": "Suporte para conformidade com HIPAA, SEC e FINRA",
          "feature_rapid_onboarding": "Integração rápida de clientes e portais com marcas personalizadas",
          "features_header": "Todos os recursos do Advanced, mais:",
          "title": "Premium"
        },
        "save_with_annual_btn": "Economize 10% com o faturamento anual",
        "sharefile_for_accounting": "ShareFile para contabilidade",
        "standard": {
          "feature_all_storage": "Todo o armazenamento de que você precisa",
          "feature_file_encryption": "Criptografia de arquivos",
          "feature_sync_edit_share": "Sincronize, edite, compartilhe",
          "feature_unlimited_client": "Usuários clientes ilimitados",
          "features_header": "Os recursos incluem:",
          "title": "Padrão",
          "title_0gb": "Standard 0GB"
        },
        "storage": "Armazenamento",
        "upgrade_btn": "Atualização",
        "upgrade_plan": "Atualizar o plano",
        "users": "Usuários",
        "users_count": "1 usuário",
        "users_count_plural": "{{count}} usuários",
        "vdr": {
          "feature_click_trails": "Trilhas de cliques",
          "feature_folder_qa": "Perguntas frequentes de pastas",
          "feature_visibility_analytics": "Visibilidade, análise de dados e muito mais",
          "features_header": "Proteção especializada, incluindo:",
          "title": "Sala de dados virtual"
        }
      },
      "receipts": {
        "actions": {
          "download": "Download",
          "pay": "Pagar",
          "title": "Ações"
        },
        "cancel": "Cancelar",
        "credit_card_notifications": {
          "payment_approved": "Pagamento aprovado. Obrigado!",
          "payment_failure": "O pagamento com cartão de crédito foi malsucedido. Entre em contato com o suporte."
        },
        "date": "Data da adição",
        "left_nav_button_text": "Recibos e notificações de faturamento",
        "open_payment": "Sua conta tem um pagamento em aberto.",
        "page_infotip": "Para ver e/ou imprimir recibos de cobrança do ShareFile, clique na data apropriada abaixo.",
        "page_title": "Recibos e notificações de faturamento",
        "pay_now": "pagar",
        "status": "Status",
        "type": "Tipo"
      }
    }
  }
];