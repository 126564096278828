import { Account, AccountFeatures, UserRole } from '@citrite/sf-api';
import { BillingType } from '@sharefile/ecomm-models/dist/orders';
import { PiletUserContext } from '@sharefiledev/sharefile-appshell';

/**
 * Determines whether the user and their account satisfies the conditions to show the Manage Plan page
 * @param context contains details about the current SF session, including the user and account
 * @returns true if all conditions are met, false otherwise
 */
export function showManagePlan(context: PiletUserContext) {
	if (!context.user) {
		return false;
	}

	if (!context.user.Roles) {
		return false;
	}

	if (!context.user.Id) {
		return false;
	}

	if (!context.user.Roles.includes(UserRole.AdminAccountPolicies)) {
		return false;
	}

	if (!context.user.Roles.includes(UserRole.AdminChangePlan)) {
		return false;
	}

	if (!isSFHybrisAccount(context.account.BillingType)) {
		return false;
	}

	if (!showBillingInfo(context.account, context.user.Id)) {
		return false;
	}

	if (!context.account.CanChangePlan) {
		return false;
	}

	if (context.account.PlanTrack === 'Customer Account Record') {
		return false;
	}

	if (context.account.IsCancelled) {
		return false;
	}

	if (
		getDisableAdminBillingSectionPreference(context.account) === 'ShowReceipts' ||
		getDisableAdminBillingSectionPreference(context.account) === 'DisableAll'
	) {
		return false;
	}

	return true;
}

/**
 * Determines whether the user and their account satisfies the conditions to show the Edit Billing Info page
 * @param context contains details about the current SF session, including the user and account
 * @returns true if all conditions are met, false otherwise
 */
export function showEditBillingInfo(context: PiletUserContext) {
	const { account, user } = context;

	if (!user.Roles?.includes(UserRole.AdminBilling)) {
		return false;
	}

	if (showConvertLink(account)) {
		return false;
	}

	if (!showBillingInfo(account, user.Id ?? '')) {
		return false;
	}

	if (!account.CanChangeBilling) {
		return false;
	}

	if (account.IsCancelled) {
		return false;
	}

	if (
		getDisableAdminBillingSectionPreference(account) === 'ShowReceipts' ||
		getDisableAdminBillingSectionPreference(account) === 'DisableAll'
	) {
		return false;
	}

	return true;
}

/**
 * Determines whether the user and their account satisfies the conditions to show the Convert to Paid page
 * @param context contains details about the current SF session, including the user and account
 * @returns true if all conditions are met, false otherwise
 */
export function showConvertToPaid(context: PiletUserContext) {
	const { account, user } = context;
	if (!account.CanConvertFreeTrial) {
		return false;
	}

	if (!showConvertLink(account)) {
		return false;
	}

	if (!user.Roles?.includes(UserRole.AdminBilling)) {
		return false;
	}

	if (!showBillingInfo(account, user.Id ?? '')) {
		return false;
	}

	return true;
}

/**
 * Determines whether the user and their account satisfies the conditions to show the Receipts page
 * @param context contains details about the current SF session, including the user and account
 * @returns true if all conditions are met, false otherwise
 */
export function showReceipts(context: PiletUserContext) {
	const { account, user } = context;
	if (!isSFHybrisAccount(account.BillingType)) {
		return false;
	}

	if (!user.Roles?.includes(UserRole.AdminViewReceipts)) {
		return false;
	}

	if (account.IsFreeTrial) {
		return false;
	}

	if (!showBillingInfo(account, user.Id ?? '')) {
		return false;
	}

	if (account.AccountFeatures?.includes(AccountFeatures.IsPlanTrackFreemium)) {
		return false;
	}

	if (account.IsCTXSBilledSMBAccount) {
		return false;
	}

	if (getDisableAdminBillingSectionPreference(account) === 'DisableAll') {
		return false;
	}

	return true;
}

/**
 * Determines whether the user and their account satisfies the conditions to show the Cancel Account page
 * @param context contains details about the current SF session, including the user and account
 * @returns true if all conditions are met, false otherwise
 */
export function showCancelAccount(context: PiletUserContext) {
	const { account, user } = context;

	if (!user.Roles?.includes(UserRole.AdminAccountPolicies)) {
		return false;
	}
	if (!user.Roles?.includes(UserRole.AdminChangePlan)) {
		return false;
	}
	if (account.IsCancelled ?? false) {
		return false;
	}
	if (!isSFHybrisAccount(account.BillingType)) {
		return false;
	}
	if (!(account.CanCancel ?? true)) {
		return false;
	}
	if (account.Preferences?.DisableAdminBillingSectionInApp === 'ShowReceipts') {
		return false;
	}
	if (account.Preferences?.DisableAdminBillingSectionInApp === 'DisableAll') {
		return false;
	}

	return true;
}

/**
 * Determines whether the user and their account satisfies the conditions to show the Trial Information Banner
 * @param context contains details about the current SF session, including the user and account
 * @returns true if all conditions are met, false otherwise
 */
export function showTrialBanner(context: PiletUserContext) {
	if (!(context.account.IsFreeTrial ?? false)) {
		return false;
	}
	if (!context.account.TrialInfo || !context.account.TrialInfo.EndDate) {
		return false;
	}
	return true;
}

/**
 * Determines whether the account has a preference 'DisableAdminBillingSectionInApp' and returns the value if it exists
 * Returns '' if the preference does not exist
 * @param account The account of the authenticated user
 * @returns 'ShowReceipts' | 'DisableAll' | ''
 */
function getDisableAdminBillingSectionPreference(account: Account) {
	return account.Preferences?.DisableAdminBillingSectionInApp || '';
}

/**
 * Determines whether the account is a SFHybris account
 * @param billingType the billing type of the account
 * @returns true if the account is a SFHybris account, false otherwise
 */
function isSFHybrisAccount(billingType?: string) {
	return billingType === BillingType.SFHybris;
}

/**
 * Determines whether billing info should be shown
 * @requires isSFHybrisAccount(account.BillingType) is true
 * @param account the account of the authenticated user
 * @param userId the id of the authenticated user
 * @returns true if the account preference ShowBillingInfo is present or the user is in the HideBillingInfoExceptions account preference list, false otherwise
 */
function showBillingInfo(account: Account, userId: string) {
	if (!isSFHybrisAccount(account.BillingType)) {
		return false;
	}

	return (
		account.Preferences?.ShowBillingInfo ||
		(account.Preferences?.HideBillingInfoExceptions || '').indexOf(userId) !== -1
	);
}

/**
 * Determines whether the convert link should be shown
 * @param account the account of the authenticated user
 * @returns true if the account is a free trial and the conversion type is manual, false otherwise
 */
function showConvertLink(account: Account) {
	return account.IsFreeTrial && account.ConversionType === 'manual';
}
