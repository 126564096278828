import { ModalStyles } from '@sharefiledev/sharefile-appshell';
import { Breakpoint } from 'antd';

export const getDefaultModalProps = (screens?: Partial<Record<Breakpoint, boolean>>) => {
	return {
		centered: true,
		width: screens?.sm ? '579px' : '100vw',
		footer: null,
		maskClosable: false,
		styles: {
			body: {
				height: screens?.sm ? '660px' : 'calc(100vh - 56px)', // third screen experience
				display: 'flex',
				flexDirection: 'column',
				gap: '8px',
			} as ModalStyles['body'],
		},
	};
};
